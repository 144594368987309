import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel  } from "../../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import AuthService from "services/authService";
import CustomSlider from "components/CustomSlider";



const ScopeSection: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);





 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refabout, inViewAbout] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThing, inViewEveryThing] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refEveryThingRight, inViewEveryThingRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadBottom, inViewDownloadBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refDownloadLeft, inViewDownloadLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadRight, inViewDownloadRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThing = useSpring({
    opacity: inViewEveryThing ? 1 : 0,
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationEveryThingLeft = useSpring({
    opacity: inViewEveryThingLeft ? 1 : 0,
    transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationEveryThingRight = useSpring({
    opacity: inViewEveryThingRight ? 1 : 0,
    transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThingBottom = useSpring({
    opacity: inViewEveryThingBottom ? 1 : 0,
    transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });


  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const bottomAboutAnimation = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  
  return (
    <>

<div className="self-stretch h-screen md:h-[800px] bg-[#033645] px-10 md:px-4">
          
       
         
            <animated.div ref={refEveryThingRight} style={animationEveryThingRight}>
           <div className="flex flex-col justify-center items-center w-full py-20 gap-4">
                
                <Heading  className="!text-white-A700 !font-hankengrotesk  text-3xl md:text-2xl sm:text-xl">
                Simplifying assessment and compliance at every stage
                </Heading>
                <Text  className=" md:w-full !text-gray-400_10 leading-5  text-[14px] md:text-base sm:text-sm text-white-A700 ">
                  <>
                  Whether you’re working toward your first assessment or running a sustainability and compliance 
                  program at scale, we're here to handle the heavy lifting.
                  </>
                </Text>
                
              </div>
            </animated.div>

            <div className="flex flex-row ">

              <div className="w-4/12 md:hidden"> <Img src="images/img-assessment-1.svg"  className='object-contain my-3 md:my-0 '/></div>
              <div className="w-8/12 md:w-full flex flex-col items-start justify-end pb-4 gap-6 "> 
              <Img src="images/img-assessment-3.svg"  className='object-cover my-3 md:my-0'/>
             <div className="w-full flex flex-row justify-between">
             <div className="flex flex-col w-5/12">
                <Heading  className="text-white-A700 !font-hankengrotesk  text-3xl md:text-2xl sm:text-xl">
              Scope 1,2 and 3 
             </Heading>
              <Text  className=" md:w-full !text-gray-400_10 leading-5  text-[14px] text-base sm:text-sm text-[#AEAEAE] font-kumbhsans">
                  <>
                  Understand your company’s sustainability
                  strategy and build a better lifestyle
                  </>
                </Text>
                </div>
                <div className="flex flex-col w-5/12">
                <Heading  className="text-white-A700 !font-hankengrotesk  text-2xl md:text-xl">
                Lifestyle-based factors
             </Heading>
                <Text  className=" md:w-full !text-gray-400_10 leading-5  text-[14px] text-base sm:text-sm text-[#AEAEAE] font-kumbhsans">
                  <>
                  Seek out Lifestyle-Based factors that employ 
your real activity data as this will provide you
with robust measurements
                  </>
                </Text>
                </div>
             </div>
               

              <Img src="images/img-asssessment-2.svg"  className='object-cover my-3 md:my-0  -m-10 md:-m-4'/>
              </div>
            </div>
        
        </div>


    </>
  );
};

export default ScopeSection;
