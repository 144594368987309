// SendInviteSidebarMenu.tsx
import React, { useEffect, useRef, useState } from "react";
import { Text,Img, Button, Input, Radio, RadioGroup } from 'components';
import { Box, TextField } from "@mui/material";


interface UserDatas {
  firstname: string;
  lastname: string;
  email: string;
  jobTitle: string;
  userRole: string;
  accessModule: string;
  createPassword: boolean;
}

interface SendInviteSidebarMenuProps {
  isAddUserOpen: boolean;
  handleCloseMenu: () => void;
  UserDatas?: UserDatas;
}

const SendInviteSidebarMenu: React.FC<SendInviteSidebarMenuProps> = ({ isAddUserOpen, handleCloseMenu, UserDatas }) => {
 
  return (
    <>
     <div>
      <button 
              onClick={handleCloseMenu}
              className="text-white absolute top-2 right-0 mr-3 focus:outline-none"
            >
                <div className="m-auto items-center  justify-center  top-0 right-0  bg-gray-400_7f h-full w-full  fixed z-40" style={{ opacity: 0.9 }}>
        
                  
        </div>
            </button>
      
          <div className=" m-auto items-center  justify-center  top-0 right-0  bg-white-A700 h-full md:w-[80%] w-[65%]  py-5  md:px-5 px-10 fixed z-50 overflow-scroll">    
          <div className="flex flex-col items-start mb-10 gap-8">
              <div className="flex flex-col items-start  gap-4 md:ml-0">
                <Text size="17xl" as="p">
                  Review and finish
                </Text>
                <Text size="6xl" as="p" className="!font-normal">
                  Review all the information for this user before you finish adding them
                </Text>
              </div>
              <div className="flex items-start gap-2">
                <div className="flex flex-col items-start gap-20 md:gap-10 sm:gap-5">
                  <div className="flex flex-col items-start ml-2 md:ml-0">
                    <Text size="6xl" as="p">
                      Name and Username
                    </Text>
                    <Text size="3xl" as="p" className="mt-[7px] !text-gray-800_03">
                      Display name: {UserDatas.firstname}{UserDatas.lastname}
                    </Text>
                    <Text size="3xl" as="p" className="mt-1.5 !text-gray-800_03">
                      Username: {UserDatas.firstname}
                    </Text>
                    {/* <Text size="3xl" as="p" className="mt-1 !text-gray-800_03">
                      Password: 8hbuk
                    </Text> */}
                    <Text size="6xl" as="p" className="mt-[42px]">
                      Role
                    </Text>
                    <Text size="3xl" as="p" className="mt-2 !text-blue_gray-900_04">
                    {UserDatas.userRole}
                    </Text>
                    <Text size="6xl" as="p" className="mt-10">
                      Access to modules
                    </Text>
                    <Text size="3xl" as="p" className="mt-[7px] !text-gray-800_04">
                    {UserDatas.accessModule}
                    </Text>
                  </div>
                  <Button
                    onClick={handleCloseMenu}

                    color="green_700"
                    size="6xl"
                    className="bg-green-700 sm:px-5 font-hankengrotesk min-w-[128px] rounded-[23px]"
                  >
                    Close
                  </Button>
                </div>
                <Text size="23xl" as="p" className="mt-5 !text-gray-400_03">
                  &#125;
                </Text>
                <Img src="/images/img_trophy_gray_600_02.svg" alt="trophy_one" className="h-[20px] w-[20px] mt-[49px]" />
              </div>
            </div>
                  
          </div>
          </div>
    </>
  );
};

export default SendInviteSidebarMenu;
