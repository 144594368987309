import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Input, Img, Text, Button, Heading } from "../../../../components";
import { Box, LinearProgress, Switch, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DefaultLayout from "layout/DefaultLayout";
import { ReactTyped } from "react-typed";
import AuthService from "services/authService";
import { CircularProgressBar, LinearProgressBar } from "components/ProgressBar";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

const theme = createTheme({
  palette: {
      primary: {
          main: '#4caf50', // Green color
      },
  },
});
const ClimateIntro1= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 500 },
  });
  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>  
      <div className="bg-white-A700 px-10 md:px-5">
       
      <Text  className="text-3xl md:text-2xl sm:text-xl mb-5">
      Latest Sustainability News
       </Text>
      <div className="w-full px-5 h-auto py-10  bg-[#10586D] rounded-2xl ">  
   
      <animated.div ref={ref} style={animation}>
      <div className="flex flex-col  gap-5 mt-5 w-full">
      <Text  className="text-2xl md:text-xl sm:text-base mb-2 text-white-A700">
      Sustainable Rail News:
       </Text>
      <Text  className="text-[14px] md:text-base sm:text-sm mb-2 text-white-A700">
      1.  <strong> UK Unveils Ambitious Plan for Net-Zero Rail by 2050 </strong>: The UK government has announced a new target for the railway industry to achieve net-zero emissions by 2050. This significant goal necessitates substantial investments in electrification, renewable energy sources, and improved energy efficiency across the rail network. Decarbonizing the railways is crucial for the UK's overall net-zero strategy and aligns with its commitment to combatting climate change. (Source: Department for Transport)      </Text>
      <Text  className="text-[14px] md:text-base sm:text-sm mb-2 text-white-A700">
      2. Hydrogen Trains Take Center Stage in UK's Decarbonization Push: The UK government has pledged £90 million ($108 million) to accelerate the development and deployment of hydrogen trains. This initiative aims to explore the feasibility of hydrogen as a clean alternative to diesel on non-electrified routes. A successful rollout of hydrogen technology could significantly contribute to the UK's net-zero rail target and pave the way for a cleaner railway future. (Source: BBC News)       </Text>
       <Text  className="text-[14px] md:text-base sm:text-sm mb-2 text-white-A700">
       <Text  className="text-2xl md:text-xl sm:text-base my-2 text-white-A700">
       SDG News:
       </Text>
       3. UN Report Highlights Lag in Global Progress on SDG 13: Climate Action: The latest report from the United Nations warns of insufficient progress towards achieving the goals outlined in SDG 13, which focuses on climate action. While a slight increase in global renewable energy investment during the first quarter of 2024 is a positive sign, experts emphasize the urgency of significantly accelerating efforts to mitigate climate change.  The report underscores the need for a collective global response to meet the ambitious targets set by SDG 13 and ensure a sustainable future for the planet. (Source: United Nations Framework Convention on Climate Change)
        </Text>
        <Text  className="text-[14px] md:text-base sm:text-sm mb-2 text-white-A700">
        4. Businesses Prioritize Sustainability Integration within Supply Chains: A recent survey conducted by the Supply Chain Sustainability Management Association (SCSMA) indicates a growing emphasis on integrating sustainability practices throughout supply chains. This trend is driven by a multitude of factors, including rising consumer demand for eco-friendly products, increasing regulatory pressure on businesses to operate sustainably, and a growing awareness of the environmental risks associated with unsustainable supply chain practices. Businesses are recognizing the importance of supply chain sustainability not only for environmental reasons but also for enhancing brand reputation and mitigating potential risks. (Source: Supply Chain Sustainability Management Association)        </Text>
      </div>
             </animated.div>
    
     

        
      </div> 
      <div className="w-full flex flex-row items-center justify-center mt-10">
      <Link
       to="/dashboard/home"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-[#5FE1F3] w-24 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
       <button
        className="w-full" >
        Continue
        </button>
      </Link>
      </div>
  
      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {ClimateIntro1};
const ClimateIntro2= () =>  {

const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });
  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/climate-intro1"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
    
       
    
      <Text  className="text-3xl md:text-2xl sm:text-xl"
      >
      In which transportation subsector are you involved?
              </Text>
     <animated.div ref={ref} style={animation}>       
      <div className="flex flex-row md:flex-col  gap-5 mt-5">
      <Link
       to="/dashboard/module/climate-intro3"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Railway
        </button>
                    </Link>
  
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Road
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Aviation
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Marine
        </button>
      </div>
      </animated.div> 

        

      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {ClimateIntro2};
const ClimateIntro3= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   
  <animated.div ref={ref} style={animation}>
      <div className="w-full md:flex-col p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/climate-intro2"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
       
      
      <Text  className="text-3xl md:text-2xl sm:text-xl">
      Select your rail transport category?
              </Text>
      <div className="flex md:flex-col flex-row  gap-5 mt-5">
      <Link
       to="/dashboard/module/climate-intro4"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Light Rail
        </button>
                    </Link>
  
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Heavy Rail
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Metro
        </button>
      </div>
        

        

      </div>
      </animated.div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {ClimateIntro3};
const ClimateIntro4= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="w-full p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/climate-intro3"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
      <Text  className="text-3xl md:text-2xl sm:text-xl">
      Select your rail transport category?
              </Text>
      <animated.div ref={ref} style={animation}>       
      <div className="flex flex-row md:flex-col  gap-5 mt-5">
      <Link
       to="dashboard/module/climate-intro5"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Railway Owner
        </button>
                    </Link>
  
        <button
          className=" transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Railway Operator
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Railway Maintaner
        </button>
      </div>
      <div className="flex md:flex-col flex-row  gap-5 mt-5">
      
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Engineering and Construction
        </button>
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Manufacturing ( Trains, Signaling,
Track, Communication Systems)
        </button>
      </div>
      </animated.div>
        

      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {ClimateIntro4};
const ClimateIntro5= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="w-full p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/climate-intro5"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
       
      
      <Text  className="text-3xl md:text-2xl sm:text-xl">
      Select your rail transport category?
              </Text>
      <animated.div ref={ref} style={animation}>       
      <div className="flex flex-row md:flex-col  gap-5 mt-5">
      <Link
       to="/dashboard/question/climate-change"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Railway Owner
        </button>
                    </Link>
  
        <button
          className=" transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Railway Operator
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Railway Maintaner
        </button>
      </div>
      <div className="flex md:flex-col flex-row  gap-5 mt-5">
      
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Engineering and Construction
        </button>
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Manufacturing ( Trains, Signaling,
Track, Communication Systems)
        </button>
      </div>
      </animated.div>
        

      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {ClimateIntro5};