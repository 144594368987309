import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CustomSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
//   const [activeIndex, setActiveIndex] = useState(0);
//   const slides = [
//     { id: 1, image: 'your-image1.jpg' },
//     { id: 2, image: 'your-image2.jpg' },
//     { id: 3, image: 'your-image3.jpg' },
//     // Add more slides as needed
//   ];
  const slides = [
    {
        id: 1,
        title: "AI-Powered",
        indicator: "Access",
        image:'images/ai-assessment.png',
        content: "Our AI-driven sustainability assessment tool meticulously examines every facet of your company's operations, scrutinizing environmental, social, and economic practices to provide a comprehensive evaluation of your sustainability performance",
      
    },
    {
        id: 2,
        title: "Learning Insight",
        indicator: "Learn",
        image:'images/learning.png',
        content: "Gain profound insights into the intricate web of your company's sustainability landscape. Our tool translates complex data into meaningful patterns, helping you comprehend the nuances of your environmental impact and social responsibility."
    },
    {
        id: 3,
        title: "Report & Analytics",
        indicator: "Report",
        image:'images/report.png',
        content: "Effortlessly generate detailed sustainability reports that go beyond mere numbers. Our tool transforms data into compelling narratives, making it easy for your company to communicate its commitment to sustainability to stakeholders, customers, and partners."
    },
    {
        id: 4,
        title: "Benchmarked Ranking",
        indicator: "Rank",
        image:'images/ranking.png',
        content: "Compare your sustainability standing with industry benchmarks and competitors. Our tool assigns a ranking that reflects your company's commitment to sustainable practices, empowering you to set ambitious goals and stay ahead in the race towards corporate responsibility."
    },
    {
        id: 5,
        title: "Continuous Improvements ",
        indicator: "Improve",
        image:'images/continious.png',
        content: "Armed with actionable insights, embark on a journey of continuous improvement by Identifying areas of opportunity. Implement targeted strategies to enhance your sustainability lifestyle, fostering a positive impact on the environment and society"
    },
]
  const slideWidth = 913;
  const slideHeight = 471;

  // Handle previous slide click
  const handlePrevClick = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  // Handle next slide click
  const handleNextClick = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const slideCount = slides.length;

  // Auto-slide functionality with infinite loop
  useEffect(() => {
    const autoSlide = setInterval(() => {
    setCurrentSlide((prevIndex) => (prevIndex + 1) % slideCount);
    }, 3000); // Adjust the delay (3000ms) as needed

    return () => clearInterval(autoSlide);
  }, [slideCount]);
  const handleIndicatorClick = (index: number) => {
    setCurrentSlide(index); // Set the active slide based on the indicator clicked
  };
  return (
    <div className='w-full   overflow-hidden' >
      <div className="flex justify-center my-10 w-full">
        {slides.map((slide, index) => (
          <div key={slide.id} className="flex flex-col items-center mx-2">
             <button
            className={`font-medium font-hanken text-xl md:text-base sm:text-xs text-center px-2  h-10  rounded-2xl transform transition-transform outline-none `}
            onClick={() => handleIndicatorClick(index)} // ; handleButtonClick("Improve")
            style={currentSlide === index ? {background:'#00BF63',color:'white'} : {background:"#F1F1F1",color:'black'} }

        >
            {slide.indicator}
        </button>
          </div>
        ))}
      </div>
      <div
       className='pl-10 md:pl-4 md:pr-4 md:hidden flex'
        style={{
         
          transform: `translateX(${-currentSlide * slideWidth}px)`,
          transition: 'transform 0.5s ease',
          width: `${slides.length * slideWidth}px`,
        }}
      >
        {slides.map((card) => (
           <section key={card.id} className="slides overflow-hidden py-9 pl-9 rounded-lg border-r bg-stone-50 border-zinc-100 w-[913px] md:w-full shadow-[1px_0px_15px_rgba(0,0,0,0.14)] md:pl-5 mr-14" >
           <div className="flex gap-5 md:flex-col">
           <div className="flex flex-col w-6/12 md:ml-0 md:w-full">
           <div className="flex flex-col items-start self-stretch my-auto md:mt-10">
             <h2 className="text-2xl font-semibold text-black">
               {card.title}
             </h2>
             <p className="self-stretch mt-3 text-lg text-zinc-600">
             {card.content}
             </p>
             <div className="w-40  h-14 relative px-4 py-3 mt-36 md:mt-10">
    <div className="w-40 h-14 left-0 top-0 absolute bg-gradient-to-l from-[#076e5c] to-[#00984f] rounded-[27.50px]" />
    <div className="w-40 h-14 left-[20px] top-[21px] absolute text-white-A700 text-[17px] font-semibold font-kumbhsans leading-3">Book a demo</div>
    </div>
           </div>
           </div>
         <div className="flex flex-col ml-5 w-6/12 md:ml-0 md:w-full">
           <img
             loading="lazy"
             src={card.image}
             alt={card.image}
             className="object-contain grow w-full aspect-[1.11] md:mt-10 md:w-full md:h-60"
           />
         </div>
           </div>
         </section>
        ))}
      </div>
<div className="md:block hidden bg-stone-50">
<div
    className="flex transition-transform duration-500"
    style={{
      transform: `translateX(-${currentSlide * 100}%)`, // Adjusted to percentage for responsive
    }}
  >
    {slides.map((slide, index) => (
      <div key={slide.id} className="w-full  h-[471px] flex-shrink-0 bg-stone-50 overflow-hidden p-4">
           <h2 className="text-2xl font-semibold text-black">
           {slide.title}
         </h2>
         <p className="self-stretch mt-3 text-lg text-zinc-600">
          {slide.content}
         </p>
         <Link to="/signuppage" >
      <div className="w-36  h-12 relative mt-20">
        <div className="w-full h-14 left-0 top-0 absolute bg-gradient-to-l from-[#076e5c] to-[#00984f] rounded-[27.50px]" />
        <div className="w-full h-4 left-[20px] top-[21px] absolute text-white-A700 text-base font-semibold font-kumbhsans leading-3">Book a demo</div>
        </div>
      </Link>
      </div>
    ))}
  </div>


</div>
    
    </div>
  );
};

export default CustomSlider;
