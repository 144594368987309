import React from 'react';

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: () => void;
  file: File | null;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: string | null;
  response: { score: string; report: string; scorePercentage: string } | null;
  uploading: boolean;
  onReset: () => void; 
  onUploadToProfile: () => void; 
  addingToDoc:  boolean;
}

const CustomDialog: React.FC<CustomDialogProps> = ({ open, onClose, onUpload, file, onFileChange, error, response, uploading,  onReset,   onUploadToProfile,addingToDoc }) => {

 
  return (
    <>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <div className="relative w-6/12 md:w-full h-96 md:h-auto ">
        <div className= "absolute bg-white-A700 rounded-lg shadow-lg w-full h-96 md:h-auto overflow-y-scroll">
            <div className="p-4 border-b border-gray-300">
              <h2 className="text-lg font-semibold">Upload File</h2>
            </div>
            <div className="p-4">
              <input
                type="file"
                onChange={onFileChange}
                className="mb-4 border border-gray-300 p-2 rounded-md w-full"
              />
              {file && (
                <p className="text-sm mb-4">Selected document: {file.name}</p>
              )}
              {error && <p className="text-red-500 mb-4">{error}</p>}
              {response && (
                <div>
                <table className="w-full border-collapse">
                  {/* <thead>
                    <tr>
                      <th className="border p-2 text-left">Field</th>
                      <th className="border p-2 text-left">Value</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <tr>
                      <td className="border p-2">Score</td>
                      <td className="border p-2">{response.score}</td>
                    </tr>
                    <tr>
                      <td className="border p-2">Report</td>
                      <td className="border p-2">{response.report}</td>
                    </tr>
                    <tr>
                      <td className="border p-2">Score Percentage</td>
                      <td className="border p-2">{response.scorePercentage}</td>
                    </tr>
                  </tbody>
                </table>
               {response.score ==='poor'? (<div className="flex justify-end p-4 border-t border-gray-300">
              <button
                onClick={onClose}
                className="bg-gray-300 text-black px-4 py-2 rounded-md mr-2"
              >
                Close
              </button>
              <button
                onClick={onUploadToProfile} // Correct usage
                className="bg-green-500 text-white-A700 px-4 py-2 rounded mr-2"
                >
                {uploading ? 'Uploading...' : 'Add to document manager'}
             </button>
              <button
                onClick={onReset}
                disabled={uploading}
                className={`bg-green-A700_01 text-white-A700 px-4 py-2 rounded-md ${uploading ? 'bg-green-A700_01 text-white-A700' : 'hover:bg-green-50_02 hover:text-green-A700_01'}`}
              >

                 {uploading ? 'Uploading...' : 'Upload another Document'}
              </button>
                 </div>):(<div className="flex justify-end p-4 border-t border-gray-300">
              <button
                onClick={onClose}
                className="bg-gray-300 text-black px-4 py-2 rounded-md mr-2"
              >
                Close
              </button>
              <button
               onClick={onUploadToProfile} // Correct usage
                disabled={uploading}
                className={`bg-green-A700_01 text-white-A700 px-4 py-2 rounded-md ${uploading ? 'bg-green-A700_01 text-white-A700' : 'hover:bg-green-50_02 hover:text-green-A700_01'}`}
              >
                {uploading ? 'Uploading...' : 'Add to document management '}
              </button>
          
                 </div>)}
                </div>
              )}
            </div>
            {!response && (
                  <div className="flex justify-end p-4 border-t border-gray-300">
                  <button
                    onClick={onClose}
                    className="bg-gray-300 text-black px-4 py-2 rounded-md mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={onUpload}
                    disabled={uploading}
                    className={`bg-green-A700_01 text-white-A700 px-4 py-2 rounded-md ${uploading ? 'bg-green-A700_01 text-white-A700' : 'hover:bg-green-50_02 hover:text-green-A700_01'}`}
                  >
                    {uploading ? 'Analyzing...' : 'Analyze'}
                  </button>
                </div>
              )}
          
          </div>
          {addingToDoc && (
                   <div   className="bg-white-A700 rounded-lg w-full h-96 md:h-auto absolute flex flex-col items-center justify-center">
        
                   <svg className='bg-opacity-10 animate-pulse my-10' width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M54.807 35.2125C56.8434 36.5068 59.1307 37.3612 61.5207 37.7203V48H66.6734V37.7203C68.6133 37.3945 70.4851 36.7506 72.2126 35.815C73.8401 34.9443 75.2611 33.7374 76.3803 32.2755C78.0688 29.9881 78.9841 27.2276 78.9945 24.3906C79.0756 20.7796 78.2583 17.2046 76.6152 13.9829C73.9453 8.75327 70.1532 4.16961 65.5065 0.555339C65.0724 0.179745 64.5112 -0.0182439 63.936 0.00132362C63.3608 0.0208912 62.8145 0.256558 62.4073 0.660771L61.1267 1.813C60.1871 2.6414 59.2247 3.49239 58.3003 4.41869C55.305 7.36246 52.869 10.8193 51.1092 14.6231C49.2457 18.3907 48.5949 22.6373 49.2451 26.7855C49.5137 28.5031 50.149 30.1438 51.1083 31.5973C52.0677 33.0508 53.3288 34.2834 54.807 35.2125ZM61.5207 18.6446V32.5918C61.1722 32.524 60.8691 32.4487 60.5887 32.3659C59.2014 31.9964 57.9097 31.3365 56.7999 30.4304C55.3799 29.2384 54.4585 27.5627 54.216 25.7311C53.7918 22.7305 54.2717 19.6723 55.5951 16.9426C57.447 12.9809 60.0835 9.43097 63.3469 6.50475C63.5515 6.30894 63.7561 6.12821 63.9834 5.92487C68.0677 9.62254 70.8032 13.26 72.546 17.3492C73.5706 19.6435 74.0254 22.1488 73.8721 24.6542C73.5993 28.382 71.7125 30.8371 68.0904 32.1399C67.6148 32.3126 67.1289 32.456 66.6356 32.5692V18.6747L61.5207 18.6446Z" fill="#5BBA47"/>
                   <path d="M79.2388 36.9713C79.7485 39.2723 80.7364 41.4409 82.1386 43.3368L75 50.4702L78.5656 54L85.682 46.8887C87.255 48.0059 89.0057 48.8497 90.8603 49.3847C92.5879 49.9028 94.4069 50.0438 96.194 49.7982C98.9523 49.3724 101.502 48.0789 103.473 46.106C106.039 43.6577 107.958 40.6133 109.058 37.2445C110.845 31.7715 111.408 25.9739 110.708 20.2602C110.669 19.6999 110.417 19.1755 110.005 18.793C109.593 18.4105 109.051 18.1985 108.489 18.1999L106.676 18.1334C105.478 18.067 104.257 18.0079 102.978 18.0005C98.8644 17.978 94.7813 18.7011 90.9269 20.1346C87.0262 21.4719 83.6358 23.9819 81.2213 27.3198C80.2242 28.6962 79.5317 30.2686 79.1899 31.9326C78.8481 33.5967 78.8648 35.3142 79.2388 36.9713ZM98.9015 33.6631L95.358 30.1333L85.6968 39.7848C85.5119 39.4968 85.3492 39.2384 85.2086 38.9799C84.5117 37.7731 84.0828 36.4308 83.951 35.044C83.7994 33.2377 84.3269 31.4397 85.4305 30.0003C87.2216 27.621 89.6767 25.8228 92.4878 24.8312C96.12 23.4207 100.122 22.83 105.064 22.926L105.9 22.9629C106.151 28.3388 105.515 32.74 103.88 36.7867C102.998 39.0845 101.575 41.1363 99.73 42.7682C96.9485 45.1681 93.9451 45.5595 90.5422 43.9792C90.0913 43.7642 89.6562 43.5174 89.2403 43.2408L98.9015 33.6631Z" fill="#5BBA47"/>
                   <path d="M128.314 62.3681L127.162 61.0853C126.409 60.2047 125.506 59.2103 124.558 58.2614C121.613 55.2644 118.155 52.829 114.351 51.0728C110.58 49.2241 106.336 48.5907 102.196 49.2585C100.481 49.5287 98.8432 50.1639 97.391 51.122C95.9387 52.0801 94.7054 53.339 93.7731 54.8151C92.4849 56.8571 91.6338 59.1479 91.2742 61.5407H81V66.6722H91.3043C91.6291 68.6168 92.2754 70.4925 93.2161 72.2213C94.0855 73.8501 95.292 75.2713 96.7537 76.3887C99.0405 78.078 101.8 78.9923 104.634 79H105.282C108.683 78.9711 112.032 78.1501 115.067 76.6012C120.292 73.9205 124.87 70.1166 128.479 65.4577C128.843 65.0163 129.027 64.453 128.997 63.8802C128.966 63.3074 128.722 62.7674 128.314 62.3681ZM110.31 66.6646V61.5256H96.3999C96.4752 61.1688 96.543 60.8651 96.6257 60.5843C96.9883 59.192 97.6486 57.8965 98.5601 56.7888C99.7461 55.3622 101.419 54.4358 103.249 54.1927C106.247 53.7658 109.303 54.2411 112.033 55.559C116 57.4096 119.554 60.0513 122.48 63.3246L123.06 63.9547C119.364 68.0538 115.729 70.7866 111.649 72.5401C109.356 73.5668 106.853 74.0249 104.348 73.8761C100.585 73.6104 98.1687 71.7202 96.8214 68.0993C96.6478 67.6232 96.5045 67.1364 96.3924 66.6419L110.31 66.6646Z" fill="#5BBA47"/>
                   <path d="M101.681 81.269C100.307 80.2636 98.7346 79.5633 97.0697 79.2145C95.4048 78.8657 93.685 78.8764 92.0245 79.246C89.7231 79.7575 87.5547 80.7498 85.6606 82.1582L78.7504 75.2223L78.5289 75L75 78.5495L82.1391 85.6928C81.025 87.2701 80.1816 89.0234 79.6437 90.8799C79.1332 92.6119 78.9948 94.433 79.2377 96.2226C79.6673 98.9843 80.9599 101.538 82.929 103.514C85.379 106.08 88.4221 107.999 91.7883 109.101C95.6452 110.395 99.6894 111.036 103.756 110.998C105.372 110.998 107.034 110.91 108.754 110.739C109.313 110.698 109.835 110.446 110.216 110.033C110.597 109.621 110.808 109.078 110.806 108.516L110.873 107.249V106.827C110.939 105.678 111.013 104.374 110.998 103.062C111.022 98.9419 110.299 94.8516 108.864 90.9911C107.524 87.0855 105.015 83.6903 101.681 81.269ZM89.2191 85.7151C89.507 85.5224 89.7654 85.3594 90.0238 85.2186C91.2299 84.5194 92.5721 84.0897 93.9588 83.9589C95.7651 83.8027 97.5641 84.3314 99.0012 85.4409C101.38 87.2321 103.178 89.689 104.169 92.5028C105.641 96.5308 106.291 100.815 106.081 105.1C106.081 105.382 106.081 105.656 106.044 105.937C100.662 106.197 96.2696 105.559 92.2239 103.929C89.9266 103.046 87.8754 101.62 86.2439 99.7721C83.8371 96.9933 83.4384 93.9848 85.0183 90.5687C85.2365 90.1186 85.4831 89.683 85.7566 89.2645L95.1696 98.6902L95.391 98.9199L98.9274 95.3631L89.2191 85.7151Z" fill="#5BBA47"/>
                   <path d="M74.1873 93.7724C72.1442 92.4861 69.8534 91.6371 67.4612 91.2797V81H62.3123V91.2872C60.3695 91.6111 58.4956 92.2578 56.7691 93.2C55.1421 94.0733 53.7225 95.2828 52.6061 96.7471C50.9198 99.0353 50.009 101.796 50.0051 104.632C49.9269 108.245 50.7528 111.821 52.4089 115.04C55.0895 120.262 58.8891 124.837 63.5408 128.445C63.9765 128.821 64.5403 129.019 65.1178 128.998C65.6952 128.978 66.2429 128.739 66.6498 128.332L67.6583 127.42L67.9692 127.142C68.8944 126.321 69.8498 125.477 70.7446 124.566C73.7407 121.619 76.1737 118.156 77.9257 114.347C79.7716 110.576 80.4068 106.333 79.7457 102.192C79.4743 100.477 78.8383 98.8392 77.8799 97.3874C76.9215 95.9357 75.6627 94.7033 74.1873 93.7724ZM62.3426 96.4383V110.333H67.4763V96.4157C67.8252 96.4835 68.1285 96.5513 68.4242 96.6341C69.811 97.0075 71.1029 97.667 72.2157 98.5696C73.6419 99.7563 74.5697 101.43 74.8167 103.261C75.2427 106.26 74.7678 109.318 73.4518 112.05C71.5885 116.028 68.9344 119.592 65.6488 122.525L65.0119 123.098C60.917 119.408 58.1871 115.778 56.4279 111.696C55.3986 109.403 54.9383 106.898 55.0857 104.391C55.3511 100.626 57.2393 98.2081 60.8564 96.8601C61.3424 96.6887 61.8388 96.5479 62.3426 96.4383Z" fill="#5BBA47"/>
                   <path d="M49.7733 92.0165C49.2614 89.7201 48.2685 87.5565 46.8591 85.6666L54 78.5433L50.4481 75L43.3369 82.1528C41.7568 81.0457 40.0028 80.2068 38.1462 79.6703C36.4134 79.1584 34.59 79.0228 32.7998 79.2725C30.0383 79.7012 27.4856 80.9911 25.5106 82.9557C22.9445 85.4002 21.0264 88.4368 19.9269 91.7955C18.1423 97.2566 17.5879 103.041 18.303 108.738C18.3425 109.297 18.5941 109.82 19.0071 110.202C19.4201 110.583 19.9636 110.795 20.5276 110.793L22.2182 110.875C23.3528 110.933 24.5244 111 25.7108 111H25.9852C30.1065 111.016 34.1966 110.289 38.0572 108.856C41.9686 107.505 45.3658 104.99 47.786 101.652C48.7875 100.278 49.4827 98.7084 49.8254 97.0469C50.168 95.3855 50.1503 93.6706 49.7733 92.0165ZM30.3009 95.1251L30.0784 95.3535L33.6303 98.8672L43.2701 89.2246C43.4555 89.5045 43.6187 89.7623 43.767 90.0202C44.4612 91.226 44.8908 92.564 45.0275 93.9465C45.1838 95.7488 44.6547 97.5439 43.5445 98.9777C41.7563 101.352 39.2997 103.146 36.4852 104.134C32.4568 105.613 28.1688 106.267 23.8792 106.057L23.0413 106.02C22.7818 100.657 23.4121 96.2669 25.0434 92.2301C25.9217 89.9348 27.3465 87.885 29.196 86.2559C31.9693 83.8618 34.9799 83.4567 38.4057 85.0257C38.8563 85.2431 39.2922 85.4892 39.7108 85.7624L30.3009 95.1251Z" fill="#5BBA47"/>
                   <path d="M26.8174 79.7119C28.5311 79.4405 30.1677 78.8045 31.6181 77.8461C33.0685 76.8878 34.2994 75.6291 35.2287 74.1541C36.5171 72.1178 37.3663 69.8317 37.7212 67.4438H48V62.3107H37.7212C37.3881 60.3614 36.7313 58.4825 35.7784 56.7529C34.9033 55.1272 33.6911 53.7101 32.2241 52.5978C29.9384 50.9136 27.1798 50.0054 24.3474 50.0047C20.7347 49.9297 17.1597 50.7554 13.9406 52.4083C8.72165 55.096 4.15141 58.9032 0.551794 63.5617C0.177209 63.997 -0.0194448 64.5591 0.00151911 65.1347C0.0224831 65.7103 0.259505 66.2564 0.664749 66.6629L1.83194 67.9594C2.66027 68.892 3.51119 69.8474 4.42988 70.7573C7.39109 73.7548 10.8689 76.1853 14.6936 77.9301C17.4889 79.2486 20.5305 79.9541 23.617 80C24.69 79.9963 25.7607 79.8999 26.8174 79.7119ZM18.6696 62.341V67.4438H32.5931C32.5253 67.785 32.45 68.0959 32.3672 68.384C32.0035 69.7729 31.3459 71.0661 30.4394 72.1751C29.2547 73.5996 27.5842 74.5271 25.7556 74.7758C22.7503 75.2038 19.6865 74.7235 16.9527 73.3959C12.9857 71.5549 9.4282 68.927 6.49317 65.6696L5.91334 65.0327C9.59564 60.9383 13.2252 58.2087 17.3142 56.4344C19.6066 55.4111 22.107 54.9486 24.611 55.0848C28.3761 55.3502 30.8009 57.2381 32.1413 60.8473C32.3129 61.3261 32.4562 61.8148 32.5705 62.3107L18.6696 62.341Z" fill="#5BBA47"/>
                   <path d="M27.367 48.5707C28.743 49.6048 30.3164 50.3239 31.9823 50.6798C33.6483 51.0358 35.3685 51.0205 37.0283 50.6351C39.3227 50.1031 41.484 49.0808 43.3734 47.6338L50.4922 55L54 51.3435L46.8664 44.0078C47.9738 42.3856 48.8107 40.5832 49.3426 38.6754C49.8531 36.895 49.9888 35.0223 49.7405 33.1831C49.3106 30.3466 48.0204 27.7246 46.0558 25.695C43.6078 23.0639 40.5706 21.0965 37.2125 19.9665C31.7475 18.1415 25.9618 17.5773 20.263 18.3135C19.7038 18.3541 19.1805 18.6126 18.7988 19.0369C18.4171 19.4612 18.2055 20.0194 18.2069 20.5988C18.2069 21.1777 18.1553 21.7567 18.1258 22.3356C18.0595 23.5164 17.9932 24.8571 18.0006 26.2054C17.9862 30.4422 18.7153 34.6466 20.1524 38.6145C21.5115 42.6201 24.0304 46.0963 27.367 48.5707ZM30.1379 34.0515L39.7918 43.9544C39.5118 44.1525 39.2539 44.3125 38.9959 44.4648C37.7895 45.179 36.4512 45.6228 35.0681 45.7674C33.2654 45.9243 31.4709 45.3811 30.0348 44.2439C27.6623 42.4073 25.8676 39.887 24.8762 36.9996C23.4015 32.8598 22.7449 28.4563 22.9454 24.0496C22.9454 23.7601 22.9454 23.4783 22.9454 23.1888C28.3177 22.907 32.7025 23.5621 36.7409 25.2303C39.035 26.1373 41.0865 27.5971 42.7248 29.4886C45.1273 32.3452 45.5326 35.4379 43.9555 38.9497C43.7382 39.4125 43.492 39.8604 43.2186 40.2904L33.5794 30.3875L30.1379 34.0515Z" fill="#5BBA47"/>
                   </svg>
           
                   <p> Uploading to document manager</p>
           
           
                   {/* <p>{progressPercentage}</p> */}
                  </div>
              )}
        </div>
        
        </div>
      )}
    </>
  );
};

export default CustomDialog;
