
import React from "react";

const PaymentMethod: React.FC = () => {
  return (
    <section className="flex flex-col self-start mt-12 md:mt-10 w-full">
      <h2 className="text-xl font-medium leading-none text-neutral-500">
        Payment method
      </h2>
      <h3 className="self-start mt-5 text-sm font-semibold text-neutral-700">
        Card information
      </h3>
      <div className="flex flex-col mt-1.5 w-full ">
        <div className="flex flex-col items-start w-full text-base rounded-md border border-solid border-stone-300 text-neutral-500">
          <input
          id="cardholderName"
          type="text"
          placeholder="1234 1234 1234 1234"
          className="h-10 flex items-center ml-2"
          aria-label="1234 1234 1234 1234d"
        />
          <div className="flex  h-10  gap-10 w-full  border-t border-solid border-stone-300">
           
          <input
          id="cardholderName"
          type="text"
          placeholder="MM/YY"
          className="h-10  items-center ml-2 w-24"
          aria-label="MM/YY"
        />
            <div className="flex flex-1 pl-2 border-l  border-solid border-stone-300">
          <input
          id="cardholderName"
          type="text"
          placeholder="CCV"
          className="h-10  items-center ml-2"
          aria-label="CCV"
           />
            </div>
          </div>
        </div>
        <label
          htmlFor="cardholderName"
          className="self-start mt-8 text-sm font-semibold text-neutral-700"
        >
          Cardholder name
        </label>
      </div>
      <div className="flex flex-col mt-1.5 md:w-full">
       
         <div    className="border border-gray-400 h-10 p-2 mt-4 w-full rounded-md" >
    <input
       type="email"
       placeholder="Full name on card"
       
     
      />
    </div>
        <label
          htmlFor="country"
          className="self-start mt-8 text-sm font-semibold text-neutral-700"
        >
          Country or region
        </label>
      </div>
      <div className="flex gap-5 justify-between py-2  px-2 h-10 mt-1.5 w-full text-base rounded-md border border-solid border-stone-300 text-neutral-500  md:pr-5 md:mr-2.5">
       
        <select
              id="sector"
              className="w-full bg-transparent appearance-none"
            >
              <option>Select Country</option>
              <option>United State</option>
              <option>United Kingdom</option>
            </select>
      </div>
      <div className="flex gap-3 self-start mt-11 text-base text-black md:mt-10">
        <input
          type="checkbox"
          id="termsAgreement"
          className="flex shrink-0 bg-white rounded-sm border border-solid border-stone-300 h-[21px] shadow-[0px_1px_4px_rgba(0,0,0,0.25)] w-[21px]"
        />
        <label htmlFor="termsAgreement" className="flex-auto">
          <span className="text-neutral-500">I agree to SAudit's </span>{" "}
          <span className="font-semibold">Terms of Service</span>{" "}
          <span className="text-neutral-500">and</span>{" "}
          <span className="font-semibold">Privacy Policy</span>
        </label>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/23d13d9d01f6dc6188223e21afb0900a5d5b427c25e0258d0576fcb662c90431?placeholderIfAbsent=true&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d"
        alt=""
        className="object-contain mt-9 w-full aspect-[500] md:w-full"
      />
      <button className="px-16 py-3.5 mt-3.5 text-base font-semibold whitespace-nowrap bg-sky-900 border-2 border-blue-200 border-solid text-white-A700 text-opacity-70 md:px-5 md:mr-2.5">
        Subscribe
      </button>
      <p className="mt-3.5 text-base text-stone-500 md:mr-2.5">
        By confirming your subscription, you allow saudit to charge <br /> you
        for future payments in accordance with their terms. <br />
      </p>
    </section>
  );
};

export default PaymentMethod;
