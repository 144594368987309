// AddUserSidebarMenu.tsx
import React, { useEffect, useRef, useState } from "react";
import { Text, Img, Button, Input, Radio, RadioGroup } from 'components';
import { Box, TextField } from "@mui/material";
import DefaultLayout from "layout/DefaultLayout";
import { Link } from "react-router-dom";
import CustomDialog from "../component/CustomDialog";
import axios from "axios";
import AuthService from "services/authService";
import { ToastContainer, toast } from "react-toastify";
import TokenService from "services/token";

interface Cert {
  certName: string;
  link: string;
  size: string;
  name: string;
  date: string;
  access: string;
}

interface UserData {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  cert: Cert[];
}
interface Option {
  text: string;
  evd: string;
  tips: string;
  evdText: string;
  keyWord: string;
  point: number;
  _id: string;
}

interface Question {
  _id: string;
  sector: string;
  subSector: string;
  type: string;
  industryFunction: string;
  userFunction: string;
  selectAssessmentCat: string;
  selectComplianceCat: string;
  text: string;
  tips: string;
  point: number;
  options: Option[];
  jurisdiction: string;
  scheme: string;
  body: string;
  tsi: string;
  ntsn: string;
  __v: number;
}
const certifications = [
  "Committee charter, member list, meeting records",

  "Role descriptions, assigned responsibilities documentation",

  "Meeting minutes showing discussions on climate issues",

  "Statements or governance documents showing no mention of climate issues",

  "Job descriptions, performance reviews detailing climate responsibilities",

  "Adjusted job descriptions, records of management meetings discussing climate issues",

  "Internal communications or informal notes indicating management engagement with climate issues",

  "Documentation evidencing recognition without specific assignments",

  "Organizational charts and job descriptions",

  "Strategic planning documents, Company governance frameworks",

  "Strategic documents showing integration in select areas like energy or infrastructure",

  "Strategic documents recognizing risks without governance actions",

  "Future planning agendas, meeting minutes discussing integration",

  "Current strategic plans and governance documents with no climate references",

  "Assessments showing effectiveness of Climate",

  "Comprehensive strategic plans with climate strategies and actions",

  "Strategic sections detailing climate-related actions in specific operational areas",

  "Strategic documents noting climate risks without accompanying actions",

  "Meeting minutes or future strategy documents with climate agenda items",

  "Strategic plans lacking any mention of climate-related issues",

  "Operational changes documentation across the network",

  "Approved plans for actions that have not started yet",

  "Internal reviews or audits confirming lack of implementation",

  "First-round evaluations or assessments of newly implemented actions",

  "Documents outlining future evaluation protocols and schedules",

  "Confirmations indicating the absence of monitoring systems",

  "Documentation of strategic adjustments and improvements based on evaluation insights",

  "Examples where certain insights have led to specific strategic improvements",

  "Plans outlining how current insights will inform future strategic adjustments",

  "Risk registers or assessments that include major climate risks",

  "Initial risk identification documents or lists",

  "Strategic plans or agendas outlining future risk assessment activities",

  "Audits or statements confirming the absence of climate risk identification",

  "Documentation of implemented mitigation strategies",

  "Documentation of key implemented strategies, such as flood defenses or cooling systems for heatwaves",

  "Approved plans or strategies awaiting implementation",

  "Reviews or audits showing a lack of mitigation action"
];

const AddDocsListing: React.FC = () =>{
  const [selectedCertification, setSelectedCertification] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [addingToDoc, setAddingToDoc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<{ score: string; report: string; scorePercentage: string } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<Cert[]>([]);
  const [userData, setUserData] = useState<UserData | null>(null);
  const userDatas = AuthService.getUserData();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthService.fetchCerts();
        setUserData(data);
      } catch (error) {
        // setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const questionsData: Question[] = await AuthService.fetchQuestions();
        setQuestions(questionsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setFile(null);
    setResponse(null);
    setError(null);
  };

  const handleReset = () => {
    setFile(null);
    setResponse(null);
    setError(null);
  };
  const handleUploadAndSend = async () => {
    if (!file || !selectedCertification) {
      setError("Please select both a file and a certification.");
      return;
    }

    setUploading(true);
    setError(null);
    setResponse(null);

    try {
      const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dizlp3hvp/upload`;
      const uploadPreset = "rztljgso";

      // Create FormData for Cloudinary upload
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", uploadPreset);

      // Upload file to Cloudinary
      const uploadResponse = await axios.post(cloudinaryUrl, formData);
      const fileUrl = uploadResponse.data.secure_url;

      // Send file URL to your API
      const apiResponse = await axios.post("https://saudit-jheg.onrender.com/document/validate-docx", {
        question: selectedCertification,
        filePath: fileUrl,
      });

      setResponse(apiResponse.data); // Assuming response is in the format { score, report, scorePercentage }

    } catch (err) {
      setError("An error occurred while uploading the file or sending data.");
    } finally {
      setUploading(false);
    }
  };


  const handleUploadToProfile = async () => {
    console.log('handleUploadToProfile called');
    if (!file) {
      console.error('File is missing');
      setError("Please select a file.");
      return;
    }
  
    if (!userData) {
      console.error('User data is missing');
      setError("User data is not loaded.");
      return;
    }
    setAddingToDoc(true);
    try {
      const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dizlp3hvp/upload`;
      const uploadPreset = "rztljgso";
  
      // Create FormData for Cloudinary upload
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", uploadPreset);
  
      // Upload file to Cloudinary
      const uploadResponse = await axios.post(cloudinaryUrl, formData);
      const fileUrl = uploadResponse.data.secure_url;
  
      const newFile: Cert = {
        certName: file.name,
        link: fileUrl,
        size: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
        name: `${userData.firstname} ${userData.lastname}`,
        date: new Date().toLocaleDateString(),
        access: '1 Member'
      };
  
      const updatedCerts = [...(userData.cert || []), newFile];
      setUploadedFiles(updatedCerts);
  
      const updatedUserData = { ...userData, cert: updatedCerts };
  
      await axios.put(`https://saudit-jheg.onrender.com/auth/update/${userData._id}`, updatedUserData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TokenService.getToken()}`,
        },
      });
  
      setUserData(updatedUserData);
      toast.success('Document uploaded to profile successfully.');
      handleDialogClose();
     } 
    catch (error) {
      console.error('Error updating profile with document:', error);
      setError("An error occurred while uploading the document to your profile.");
    }
  };
  



  return (
    <DefaultLayout>
      <div className="p-4 w-full px-10 md:px-4 bg-white-A700 gap-4">
        <div className="flex self-stretch justify-between items-center gap-5 md:ml-0">
          <h1 className="my-5 text-2xl">Select and upload certification documents</h1>
        </div>
        <form>
        {loading && 
          <div className='flex flex-col gap-4'>
                 <div  className="flex  w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
                 <div  className="flex  w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
    
       </div>
       }
          {questions.map((cert) => (
            <div key={cert._id} className="mb-4 flex items-center justify-between border-b border-gray-300 pb-2 w-full">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="certification"
                    value={cert._id}
                    id={`certification-${cert._id}`}
                    checked={selectedCertification === cert._id}
                    onChange={() => setSelectedCertification(cert._id)}
                    className="mr-2"
                  />
                  <label htmlFor={`certification-${cert._id}`} className="mr-4 text-sm">{cert.tips}</label>
                </div>
                <button
                  type="button"
                  onClick={handleDialogOpen}
                  className="bg-green-A700_01 text-white-A700 px-4 py-2 rounded-md hover:bg-green-600"
                >
                  Upload
                </button>
              </div>
            </div>
          ))}
        </form>
        <CustomDialog
  open={dialogOpen}
  onClose={handleDialogClose}
  onUpload={handleUploadAndSend}
  onFileChange={handleFileChange}
  file={file}
  error={error}
  response={response}
  uploading={uploading}
  addingToDoc={addingToDoc}
  onReset={handleReset}
  onUploadToProfile={handleUploadToProfile} // Correct prop passing
/>
<ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </div>
    </DefaultLayout>
  );
};


export default AddDocsListing;
