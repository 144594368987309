import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel  } from "../../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import AuthService from "services/authService";



const FooterSection: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);





 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refabout, inViewAbout] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThing, inViewEveryThing] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refEveryThingRight, inViewEveryThingRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadBottom, inViewDownloadBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refDownloadLeft, inViewDownloadLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadRight, inViewDownloadRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThing = useSpring({
    opacity: inViewEveryThing ? 1 : 0,
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationEveryThingLeft = useSpring({
    opacity: inViewEveryThingLeft ? 1 : 0,
    transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationEveryThingRight = useSpring({
    opacity: inViewEveryThingRight ? 1 : 0,
    transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThingBottom = useSpring({
    opacity: inViewEveryThingBottom ? 1 : 0,
    transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });


  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const bottomAboutAnimation = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <>

<footer className="flex flex-col w-full my-16">
          <div className="grid grid-cols-10 gap-4 w-full px-10 ">
         <div className="col-span-2 md:col-span-10 my-auto "><div className="flex items-center gap-1  md:justify-center md:w-full">
                  {/* <Img src="images/img_arrow_left_green_a200.svg" alt="arrowleft_one" className="h-10 w-[29px]" /> */}
                  <Img
                  src="images/img_settings_cyan_900.svg"
                  alt="settings_one"
                  className="h-8"
                />
                  {/* <Heading size="2xl" as="h6" className="!text-blue_gray-600_01 !font-inter">
                    SAUDIT
                  </Heading> */}
                </div>
         </div>
               
         
      <div className="my-auto col-span-6 md:col-span-10  " >  
                  <div  className="flex flex-row items-center justify-center gap-6">
                  <Text  className="!font-medium  sm:text-xs md:text-base text-[16px]">
                    About Us
                  </Text>
                  <Text  className="!font-medium sm:text-xs md:text-base text-[16px]">
                  Services
                  </Text>
                  <Text  className="!font-medium sm:text-xs md:text-base text-[16px]">
                  FAQs
                  </Text>
                  <Text  className="!font-medium sm:text-xs md:text-base text-[16px]">
                  Data Security
                  </Text>
                </div>
                
                </div>
                
               <div className="my-auto col-span-2 md:col-span-10 md:hidden"> <Button
                color="black_900_01"
                size="11xl"
                variant="outline"
                className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 m-auto sm:px-5 font-hankengrotesk font-semibold rounded-full"
              >
                Get in touch
              </Button></div>
    </div>
    <div className="h-[1px] w-full my-8 bg-blue_gray-100_03 " />
    <div className="grid grid-cols-10 gap-4 w-full px-10">
         <div className="col-span-2 md:col-span-10 my-auto"><div className="flex items-center gap-1 md:justify-center md:w-full">
         <Text size="4xl" as="p" className="!font-normal ">
                SALI, all rights reserved, 2024
              </Text>
                </div>
         </div>
               
         
      <div className="my-auto col-span-6 md:col-span-10" >  
      <div className="flex flex-row items-center justify-center gap-2 md:hidden">
                <a href="#">
                    <Text size="4xl" as="p" className="!font-normal">
                      Terms and services
                    </Text>
                  </a>
                <a href="#" className="">
                    <Text size="4xl" as="p" className="!font-normal">
                      Privacy Policy
                    </Text>
                  </a>
                  <a href="#" className="">
                    <Text size="4xl" as="p" className="!font-normal">
                      Cookie Policy
                    </Text>

                  </a> 
                  <a href="#" className="">
                    <Text size="4xl" as="p" className="!font-normal">
                     Legal Notice
                    </Text>

                  </a>
                  <a href="#">
                    <Text size="4xl" as="p" className="!font-normal">
                      Information Notice
                    </Text>
                  </a>
                </div>
                
                </div>
                
               <div className="my-auto flex flex-row col-span-2 md:col-span-10 md:justify-center md:w-full m-auto gap-2">  
                <Img src="images/img_user_black_900_01_26x27.svg" className='cursor-pointer transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 h-6'/>
                  <Img src="images/img_twitter_x_logo.png"  className='cursor-pointer transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 h-6'/>
                  <Img src="images/img_info.svg" className='cursor-pointer transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 h-6' />
                  <Img src="images/img_facebook.svg"  className='cursor-pointer transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 h-6'/>
                </div>
    </div>
          
          </footer>

    </>
  );
};

export default FooterSection;
