import React from 'react';
import { Button, Text, Input, SelectBox, Img } from "../../../../components";
import { CircularProgress } from '@mui/material';
import ResendLink from './ResendLink';

interface EmailVerificationTabProps {
  handleTabChange: (tab: number) => void;

  loading: boolean;
}

const EmailVerificationTab: React.FC<EmailVerificationTabProps> = ({  handleTabChange, loading }) => {
  return (
    <div className="w-full h-full mt-10">
    <Text size="13xl" as="p">
    Request received🎉
   </Text>


<div className="w-full">

<section className="self-stretch mt-2 mb-8 w-full text-sm leading-9 text-[#4E4E4E] ">

            Thank you for requesting a demo of SALI. 
            <br />
            We're excited to learn more about your organization's sustainability goals and how SALI can help you achieve them. 
            <br />
            <br />
            A member of our team will be in touch shortly to schedule a personalized demo tailored to your specific needs. 
            <br />
            <br />

</section>
{/* 
{loading ? (
<button  onClick={() => handleTabChange(3)} className= "mb-14 transition w-[259px] md:h-full ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
<CircularProgress size={24} />
</button>
) : (
<button onClick={() => handleTabChange(3)}  className= "mb-14 transition w-[259px] md:h-full   ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300  sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
Open Email
</button>
)}

<ResendLink /> */}


</div>
</div>
  );
};

export default EmailVerificationTab;
