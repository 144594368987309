import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Input, Img, Text, Button, Heading, TextArea } from "../../../components";
import { Alert, Box, LinearProgress, Radio, RadioGroup, Switch, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DefaultLayout from "layout/DefaultLayout";
import { ReactTyped } from "react-typed";
import AuthService from "services/authService";
import { Divider } from '@mui/material'
import { FaPlus } from 'react-icons/fa6'
import { CircularProgressBar, LinearProgressBar } from "components/ProgressBar";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloseSVG } from "assets/images";
import './process.css'; // Import CSS file

import quizData from '../../../assets/images/climate.json';
import FileTable from "./fileTable";
import ActivityList from "./activities";
import StorageChart from "./StorageChart";


interface Question {
  _id: string;
  sectionName: string;
  question: string;
  sectionPoints: number;
  SDGName: string;
  options: Option[];
}

interface Option {
  name: string;
  points: number;
  category: string;
  _id: string;
}
export default function DocManager() {
  const [searchBarValue8, setSearchBarValue8] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [searchBarValue44, setSearchBarValue44] = React.useState("");

  const userData = AuthService.getUserData();
  // Access the firstname and lastname properties directly from the userData object
  const { firstname, lastname } = userData;

  const [progressValue, setProgressValue] = useState(0);
  const maxProgress = 100;
  const targetProgress = 10;

  useEffect(() => {
    const interval = setInterval(() => {
      if (progressValue < targetProgress) {
        setProgressValue(progressValue + 1);
      } else {
        clearInterval(interval);
      }
    }, 100); // Change the interval time as needed
    return () => clearInterval(interval);
  }, [progressValue, targetProgress]);
  const navigate = useNavigate()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<{ [key: string]: Option }>({});
    const [error, setError] = useState<boolean>(false);
  const [nextButtonColor, setNextButtonColor] = useState<string>('grey');

  const handleAnswerSelection = (questionId: string, option: Option) => {
    setAnswers({ ...answers, [questionId]: option });
    setError(false); // Reset error message when an option is selected
    setNextButtonColor('#5FE1F3'); // Change next button background color to blue when an option is selected
  };

  const moveToNextQuestion = () => {
    if (nextButtonColor === 'grey') {
      setError(true); // Set error if no option is selected
      return;
    }
    setError(false); // Reset error message when moving to the next question
    setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, quizData.length - 1));
    setNextButtonColor('grey'); // Reset next button background color to grey when moving to the next question
  };

  const moveToPreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setNextButtonColor('grey'); // Reset next button background color to grey when moving to the previous question
  };

  const calculateTotalScore = () => {
    let totalScore = 0;
    for (const questionId in answers) {
      if (answers.hasOwnProperty(questionId)) {
        totalScore += answers[questionId].points;
      }
    }
    return totalScore;
  };

  const currentQuestion = quizData[currentQuestionIndex].question;
  const totalQuestions = quizData.length;
  const progressPercentage = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  const isLastQuestion = currentQuestionIndex === totalQuestions - 1;
  const shouldShowSubmitButton = progressPercentage === 100 && isLastQuestion;
  const isOptionSelected = !!answers[quizData[currentQuestionIndex]._id];

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div>
      <div className="flex gap-5 md:flex-col md:gap-0 px-10 md:px-4 ">
        <div className="flex flex-col w-[70%] md:ml-0 md:w-full pt-10 md:pt-4">
          <div className="flex flex-col self-stretch  md:w-full">
            <div className="flex gap-5 justify-between w-full md:flex-wrap md:mr-2.5 md:w-full">
              <div className="flex gap-3 px-4 py-3 my-auto text-sm font-medium text-black rounded-3xl bg-zinc-100 md:flex-wrap">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e538086113bb163aaf45cef49309c072422f2e2eb20eabf9aef4f95acc0d01f1?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                  className="shrink-0 w-5 aspect-square"
                />
                <div className="flex-auto md:w-full">
                  Upload your certifications to assess and improve your overall
                  score
                </div>
              </div>
              <Link 
                  to="/dashboard/doc-manager/add-docs"  className="flex flex-col w-[30%] md:ml-0 md:w-full">
                   <div className="flex gap-2 px-3.5 py-4 items-center justify-center text-base text-white-A700 bg-black-900_01 rounded-xl">
                {/* <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c7fe5323b10ebace2ddd1f158db4c5786c647b8f578ef2b116e269bdf4f7d64a?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                  className="self-start w-3 aspect-square"
                /> */}
                <div className="">View Documents</div>
              </div>
             </Link>
            
            </div>
         
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/61e577f9fb29fb6afafcd607a0e2b732ee74462c32e5ff4fbfd63326e4bbc748?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
              className="mt-11 aspect-[5.26] w-[95px] md:mt-10"
            />
            <div className="mt-3.5 md:pr-5 md:w-full">
              <div className="flex gap-2 md:flex-col md:gap-0">
               
                <Link 
                  to="/dashboard/doc-manager/add-certifications" className="flex flex-col w-[30%] md:ml-0 md:w-full">
                   <div className="flex flex-col md:ml-0 w-full">
                    <div className="flex flex-col items-center py-11 text-xl font-medium text-black whitespace-nowrap rounded-xl border-2 border-solid border-neutral-200 md:px-5 md:mt-3">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ace2c49b592348dfdbd93ce28bfcc84253c819a6f1f9df495af05655969ec995?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                      className="self-center aspect-[1.19] w-16 h-16"
                    />
                    <div className="mt-4 text-base">Certifications</div>
                  </div>
                </div>
                  </Link>
                <Link 
                  to="/dashboard/doc-manager/add-docs-listing"  className="flex flex-col w-[30%] md:ml-0 md:w-full">
                   <div className="flex flex-col ml-5  md:ml-0 w-full">
                  <div className="flex flex-col items-center py-11 text-xl font-medium text-black whitespace-nowrap rounded-xl border-2 border-solid border-neutral-200 md:px-5 md:mt-3">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ace2c49b592348dfdbd93ce28bfcc84253c819a6f1f9df495af05655969ec995?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                      className="self-center aspect-[1.19] w-16 h-16"
                    />
                    <div className="mt-4 text-base">Plans & Proceedures</div>
                  </div>
                </div>
                  </Link>
                <div className="flex flex-col ml-5 w-[30%] md:ml-0 md:w-full">
                <div className="flex flex-col items-center py-11 text-xl font-medium text-black whitespace-nowrap rounded-xl border-2 border-solid border-neutral-200 md:px-5 md:mt-3">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/a13f8c7e3df751f602ee4917ed51f7bb9f64466f038d982bda672d03db6ed17a?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                      className="self-center aspect-[1.19] w-16 h-16"
                    />
                    <div className="mt-4 text-base">Reports</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-5 px-5 mt-14 text-black md:flex-wrap md:mt-10">
              <div className="flex-auto my-auto text-xl font-medium">
                Recent files
              </div>
              <div className="flex flex-auto gap-3 text-xs md:flex-wrap">
                <div className="justify-center px-3 py-2 rounded-xl border border-emerald-200 border-solid bg-slate-200">
                  All files
                </div>
                <div className="justify-center px-4 py-2 whitespace-nowrap bg-blue-200 rounded-xl">
                  Word
                </div>
                <div className="justify-center px-4 py-2 whitespace-nowrap bg-green-200 rounded-xl">
                  Excel
                </div>
                <div className="justify-center px-4 py-2 whitespace-nowrap bg-orange-300 rounded-xl">
                  Powerpoint
                </div>
                <div className="justify-center px-4 py-2 whitespace-nowrap bg-rose-400 rounded-xl">
                  PDF
                </div>
                <div className="justify-center px-4 py-2 whitespace-nowrap bg-violet-200 rounded-xl">
                  Certifications
                </div>
              </div>
            </div>
            <FileTable />
      
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[30%] md:ml-0 md:w-full pt-10 md:pt-4 border-[#C0C0C0] border-l px-8 md:px-4">
          <div className="flex  gap-5 ">
           
            <div className="flex flex-col grow shrink-0 self-start  basis-0 w-fit">
             <ActivityList/>
             <StorageChart/>
            </div>
          </div>
        </div>
      </div>
    </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}