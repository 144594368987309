import React from 'react';

const StorageChart: React.FC = () => {
  const totalStorage = 100 * 1024; // 100 GB in MB
  const usedStorage = 25; // 20 MB used
  const imagesStorage = 5; // 5 MB
  const documentsStorage = 5; // 5 MB
  const videosStorage = 15; // 15 MB

  const usedPercentage = (usedStorage / totalStorage) * 100;

  return (
    <div className="w-full mt-10">
      <h2 className="text-lg font-semibold mb-4">Your Storage</h2>
      <div className=" w-full h-2 bg-[#E3E3E3] rounded flex flex-row">
        <div
          className="h-full bg-green-500 rounded-l"
          style={{ width: `50px` }}
        />
        <div
          className="h-full bg-blue-500"
          style={{  width: `20px`}}
        />
        <div
          className=" h-full bg-yellow-500 rounded-r"
          style={{ width: `30px` }}
        />
      </div>
      <div className="flex justify-between mt-2 text-sm">
        <span>20mb</span>
        <span>100gb</span>
      </div>
      <div className="mt-10 text-sm">
        <div className="flex items-center mb-2">
          <span className="block w-2 h-2 bg-green-500 rounded-full mr-2"></span>
          <span>Images</span>
          <span className="ml-auto">5mb</span>
        </div>
        <div className="flex items-center mb-2">
          <span className="block w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
          <span>Documents</span>
          <span className="ml-auto">5mb</span>
        </div>
        <div className="flex items-center">
          <span className="block w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
          <span>Videos</span>
          <span className="ml-auto">15mb</span>
        </div>
      </div>
    </div>
  );
};

export default StorageChart;
