import { Helmet } from "react-helmet";
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Text, Input, SelectBox, Img } from "../../../components";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import AuthService from '../../../services/authService';
import { Alert, Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import ResendLink from "./component/ResendLink";
import PersonalInfoTab from "./component/PersonalInfoTab";
import SectorInfoTab from "./component/SectorInfoTab";
import VerifiedTab from "./component/VerifiedTab";
import EmailVerificationTab from "./component/EmailVerificationTab";

export default function SignupPagePage() {
  const [firstname, setFirstNmae] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [companyRegNumber, setCompanyRegNumber] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [companysize, setCompanySize] = useState('Number of employees');
  const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate()
  const [sector, setSector] = useState('');
  const [subSector, setSubSector] = useState('');
  const [category, setCategory] = useState('');
  const [role, setRole] = useState('');



  // Function to handle changes in dropdowns
  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };

  const handleSubSectorChange = (event) => {
    setSubSector(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handlefirsNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNmae(e.target.value);
  };
  const handlelastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const handledepartmentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDepartment(e.target.value);
  };
  const handleCompanyRegChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyRegNumber(e.target.value);
  };
  const handleJobtitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setJobtitle(e.target.value);
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  

  const [activeTab, setActiveTab] = useState<number>(1)
  const userData = {
  firstname:firstname,
  lastname:lastname,
  email:email,
  companyName: department,
  industryFunction: jobtitle,
  companySector: 'Transport',
  companyRegistrationNumber: companyRegNumber,
  companySubSector: 'Raiway',
  companySize:'medium',
  };
   
    

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Check if any required fields are empty
    if (!firstname || !lastname || !email || !department || !jobtitle || !companyRegNumber) {
      setError('Please fill in all required fields.');
      return;
    }
  

 
  
    // Set loading to true when submitting the form
    setLoading(true);
    setError('');
  
    const { success, error } = await AuthService.register(userData);
    setLoading(false);
  
    if (success) {
      setError('');
      setActiveTab(2)
      AuthService.sendEmail(email,firstname);
    } else {
      if (error !== undefined) {
        setError(error);
      }
    }
  };
  const handleTabChange = (tabIndex: number) => {
    setActiveTab(tabIndex)
    
}
  return (
    <>
      <Helmet>
        <title>SALI | Signup</title>
        <meta name="description" content="Begin your sustainability
journey" />
      </Helmet>
      <div className="h-screen w-full ">
        <nav className="flex self-stretch justify-between items-center gap-5 p-5  px-10 md:p-4 fixed w-full z-10  bg-gradient5 border border-b-gray-300" >
      <div className="container mx-auto flex justify-between items-center">
      <NavLink to="/">
       <Img
        src="images/img_settings_cyan_900.svg"
        alt="settings_one"
        className="h-8"
       />
        </NavLink>
     
        <div className="  flex flex-row space-x-4 items-center">
        <div className="flex justify-between items-center  gap-5">
       
  
        <NavLink to="/login">
        <div className=" md:hidden h-[50px] w-[140px]  bg-[#3EA241] rounded-md flex text-white-A700 items-center justify-center text-center" >
          Login
        </div>
        </NavLink>
        
      </div>
        </div>
      </div>
        </nav>
        <div className=" flex h-screen flex-row items-start bg-gradient5">
       
        <div className="md:hidden h-full flex flex-col w-5/12  justify-end items-start">
            <Text size="13xl" as="p" className="  ml-10 mb-10 mt-28">
              <>
                Begin your <br />
               <span className="text-[#3EA241]">Sustainability </span>Journey
              </>
            </Text>
            <Img
                src="images/img_auth_pic.png"
                alt="istock_one"
                className="h-[70%]    object-contain"
              />
           
          </div>
       

          <div className="flex flex-col overflow-x-scroll h-full  justify-between items-center w-7/12 md:w-full  gap-5  ">
            <div className="flex flex-col items-start md:w-[85%] w-[70%] mt-28">
                <div className="flex flex-row mb-8">
              
                
                </div>
                {activeTab === 1 && (
                 <PersonalInfoTab
                  firstname={firstname}
                  lastname={lastname}
                  email={email}
                  department={department}
                  jobtitle={jobtitle}
                  companyRegNumber={companyRegNumber}
                  handleFirstNameChange={(e) => setFirstNmae(e.target.value)}
                  handleLastNameChange={(e) => setLastName(e.target.value)}
                  handleEmailChange={(e) => setEmail(e.target.value)}
                  handleDepartmentChange={(e) => setDepartment(e.target.value)}
                  handleJobTitleChange={(e) => setJobtitle(e.target.value)}
                  handleCompanyRegChange={(e) => setCompanyRegNumber(e.target.value)}
                  handleTabChange={handleTabChange}
                  loading={loading}
                  handleSubmit={handleSubmit}
                  error={error} sector={""} subSector={""} category={""} role={""} password={""} confirmPassword={""} showPassword={false} handleSectorChange={function (e: React.ChangeEvent<HTMLSelectElement>): void {
                    throw new Error("Function not implemented.");
                  } } handleSubSectorChange={function (e: React.ChangeEvent<HTMLSelectElement>): void {
                    throw new Error("Function not implemented.");
                  } } handleCategoryChange={function (e: React.ChangeEvent<HTMLSelectElement>): void {
                    throw new Error("Function not implemented.");
                  } } handleRoleChange={function (e: React.ChangeEvent<HTMLSelectElement>): void {
                    throw new Error("Function not implemented.");
                  } } handlePasswordChange={function (e: React.ChangeEvent<HTMLInputElement>): void {
                    throw new Error("Function not implemented.");
                  } } handleConfirmPasswordChange={function (e: React.ChangeEvent<HTMLInputElement>): void {
                    throw new Error("Function not implemented.");
                  } } toggleShowPassword={function (): void {
                    throw new Error("Function not implemented.");
                  } } companySize={""} setCompanySize={function (size: string): void {
                    throw new Error("Function not implemented.");
                  } }        />
      )}

      {/* {activeTab === 2 && (
        <SectorInfoTab
          sector={sector}
          subSector={subSector}
          category={category}
          role={role}
          password={password}
          confirmPassword={confirmpassword}
          showPassword={showPassword}
          handleSectorChange={(e) => setSector(e.target.value)}
          handleSubSectorChange={(e) => setSubSector(e.target.value)}
          handleCategoryChange={(e) => setCategory(e.target.value)}
          handleRoleChange={(e) => setRole(e.target.value)}
          handlePasswordChange={(e) => setPassword(e.target.value)}
          handleConfirmPasswordChange={(e) => setConfirmPassword(e.target.value)}
          toggleShowPassword={toggleShowPassword}
          companySize={companysize}
          setCompanySize={setCompanySize}
          handleTabChange={handleTabChange}
          loading={loading}
          error={error}
        />
      )} */}

      {activeTab === 2 && <EmailVerificationTab   handleTabChange={handleTabChange} loading={loading} />}
      {/* {activeTab === 3 && <VerifiedTab   handleTabChange={handleTabChange} loading={loading} />} */}
              </div>
          </div>
         

        </div>
      </div>
    </>
  );
}
