import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Input, Img, Text, Button, Heading } from "../../components";
import SidebarMenu from '../Sidebar/smallSide';
import AuthService from 'services/authService';

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // Destructure specific parts of the location object
  const { pathname, search, hash } = location;
  const navbarRef = useRef(null);
  const userData = AuthService.getUserData();
  // Access the firstname and lastname properties directly from the userData object
  const { firstname, lastname,email } = userData;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isAddUserOpen, setAddUserOpen] = useState(false);

  const handleBackPress = () => {
    navigate('/dashboard/home', { replace: true });
  };
  const handledocBackPress = () => {
    navigate('/dashboard/doc-manager', { replace: true });
  };
  const handleAddUserToggle = () => {
    setAddUserOpen(!isAddUserOpen);
    disableBodyScroll();
  };
  // Access the firstname and lastname properties directly from the userData object
  const handleCloseAddUser = () => {
    setAddUserOpen(false);
    enableBodyScroll();
  };
  const [isAddCertificateOpen, setAddCertificateOpen] = useState(false);
  const handleAddCertificateToggle = () => {
    setAddCertificateOpen(!isAddCertificateOpen);
    disableBodyScroll();
  };
  // Access the firstname and lastname properties directly from the userData object
  const handleCloseAddCertificate = () => {
    setAddCertificateOpen(false);
    enableBodyScroll();
  };
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
    disableBodyScroll();
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    enableBodyScroll(); // Re-enable body scrolling
  };

  // Function to disable body scrolling
  const disableBodyScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  // Function to enable body scrolling
  const enableBodyScroll = () => {
    document.body.style.overflow = '';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
        setAddUserOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);

  return (
    <>  {pathname=='/dashboard/question/climate-change'|| pathname.includes('/dashboard/question/climate-change')  ? (
      <>
   
    </>):pathname=='/dashboard/module/intro1'|| pathname.includes('/dashboard/module/intro1')  ? 
    (<>
      <header className="sticky top-0 z-40 flex w-full p-2 bg-white-A700 bg-gradient3 drop-shadow-1">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
     

        <div className="container mx-auto flex justify-between items-center">
 
    
        <div className='flex flex-row items-center   w-full gap-4'>

          <p  className='px-4 py-2 bg-[#01526A] text-white-A700 rounded-md'>
           Climate change
          </p>
      
        </div>


      </div>

     
       
      </div>
    </header>
  
    </>):pathname=='/dashboard/doc-manager/add-certifications'|| pathname.includes('/dashboard/doc-manager/add-certifications')  ? 
    (<>
      <header className="sticky top-0 z-40 flex w-full p-2 bg-white-A700 bg-gradient3 drop-shadow-1">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
     

        <div className="container mx-auto flex justify-between items-center">
 
    
        <div className='flex flex-row items-center   w-full gap-4'>
        <svg  onClick={handledocBackPress}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left cursor-pointer md:hidden block"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>

          <p  className='w-[40%]'>
           Add Certification
          </p>
      
        </div>


      </div>

     
       
      </div>
    </header>
  
    </>):pathname=='/dashboard/doc-manager/add-docs'|| pathname.includes('/dashboard/doc-manager/add-docs')  ? 
    (<>
      <header className="sticky top-0 z-40 flex w-full p-2 bg-white-A700 bg-gradient3 drop-shadow-1">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
     

        <div className="container mx-auto flex justify-between items-center">
 
     
        <div className='flex flex-row items-center   w-full gap-4'>
        <svg  onClick={handledocBackPress}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left cursor-pointer md:hidden block"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>

          <p  className='w-[40%]'>
           Add Docs
          </p>
      
        </div>


      </div>

     
       
      </div>
    </header>
  
    </>):(<>
      <header className="sticky top-0 z-40 flex w-full p-2 bg-white-A700 bg-gradient3 drop-shadow-1">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
     

        <div className="container mx-auto flex justify-between items-center">
 
        <div  className='flex flex-row items-center gap-3'>
          <button
            // aria-controls="sidebar"
            onClick={handleMenuToggle} 
            className="z-50  rounded-sm border border-stroke bg-white-A700 p-1.5 shadow-sm hidden md:block"
            
          >
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu "><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
          </button>
          {pathname=='/dashboard/module' ? (
        <>
      
        <svg  onClick={handleBackPress}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left cursor-pointer md:hidden block"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>

         
        </>
       
      ) :(
        <>
       <div>
       
         </div>   
         
        </>
      )}
        </div>
        <div className=" md:hidden w-full">
        {pathname=='/dashboard/home'|| pathname.includes('/dashboard/home')  ? (
        <>
        
       <div className="flex items-center  border  rounded-xl bg-gray-200 border-gray-200 p-3 mt-1">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.275 9.55C7.63602 9.55 9.55 7.63602 9.55 5.275C9.55 2.91398 7.63602 1 5.275 1C2.91398 1 1 2.91398 1 5.275C1 7.63602 2.91398 9.55 5.275 9.55Z" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.0001 10L9.1001 9.10001" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
            <input
                placeholder={'Search File'}
                className=" ml-2 flex-grow outline-none bg-transparent text-xs"
            />
            
        </div> 
         
        </>
       
      ) :pathname=='/dashboard/doc-manager'|| pathname.includes('/dashboard/doc-manager')  ? (
        <div className='flex flex-row items-center  justify-start gap-14 w-full'>
          
          <Heading size="5xl" as="h3" >
          Document Manager
            </Heading>
       <div className="flex  items-center w-[40%]  border  rounded-xl bg-gray-200 border-gray-200 p-3 mt-1 mr-10">
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.275 9.55C7.63602 9.55 9.55 7.63602 9.55 5.275C9.55 2.91398 7.63602 1 5.275 1C2.91398 1 1 2.91398 1 5.275C1 7.63602 2.91398 9.55 5.275 9.55Z" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.0001 10L9.1001 9.10001" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
            <input
                placeholder={'Search File'}
                className=" ml-2 flex-grow outline-none bg-transparent text-xs"
            />
            
        </div> 
         
        </div>
       
      ): (
        <>
       <div>
       
         </div>   
         
        </>
      )}
       
        </div>

         <div className="flex justify-center items-center ">
             <Img
                    src="/images/img_user_blue_gray_900_05.svg"
                    alt="user_one"
                    className="h-6 w-6 "
                  />
                <Img src="/images/img_inbox.svg" alt="inbox_one" className="sm:hidden h-6 w-6  ml-3" />
                <Text
                  size="7xl"
                  as="p"
                  className="ml-3 flex justify-center items-center h-6 w-6 !text-white-A700 !font-hankengrotesk bg-blue-600 text-center rounded-full"
                >
                 {userData?.firstname ? userData.firstname.charAt(0) : ''}

                </Text>
              </div>
      </div>

     
       
      </div>
    </header>
    {isMenuOpen && (
       <SidebarMenu isMenuOpen={isMenuOpen} handleCloseMenu={handleCloseMenu} userData={userData} />
        )}
    </>)}
    </>
  
  );
};

export default Header;
