import React from "react";
import { Helmet } from "react-helmet";
import { Heading, Button, Input, Text, Img } from "../../../components";

export default function ForgotpasswordPage() {

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
     <Helmet>
        <title>SALI | Login</title>
        <meta name="description" content="Begin your sustainability
journey" />
      </Helmet>
      <div className="h-screen w-full bg-white-A700">
      <nav className="flex self-stretch justify-between items-center gap-5 p-5 md:p-4 fixed w-full z-10  bg-gradient5" >
      <div className="container mx-auto flex justify-between items-center">
      <Img
                  src="images/img_settings_cyan_900.svg"
                  alt="settings_one"
                  className="h-8"
      />
        <div className="  flex flex-row space-x-4 items-center">
        <div className="flex justify-between items-center  gap-5">
        <div className="flex self-end items-start gap-2">
          <Img src="images/img_globe_gray_500_06.svg" alt="globe_one" className="h-[22px] w-[22px]" />
          <Text size="10xl" as="p" className="!text-gray-500_04 !font-hankengrotesk">
            English
          </Text>
        </div>
        <div className=" md:hidden h-[30px] w-px bg-gray-900_89" />
        <a href="#">
          <Text size="10xl" as="p" className="!text-gray-900_03 !font-hankengrotesk md:hidden">
          Forgot Password?
          </Text>
        </a>
      </div>
        </div>
      </div>
    </nav>
        <div className=" flex h-full flex-row items-start bg-gradient5">
         
          <div className="md:hidden h-full flex flex-col w-[40%] justify-end items-start">
            <Text size="13xl" as="p" className="  ml-10 mb-10">
              <>
                Begin your <br />
                Sustainability Journey
              </>
            </Text>
            <Img
                src="images/img_auth_pic.png"
                alt="istock_one"
                className="h-[70%]    object-contain"
              />
           
          </div>
          <div className="flex flex-col overflow-x-scroll h-full  justify-between items-center w-[60%] md:w-full mt-3.5 gap-5 ">
            
            <div className="flex flex-col items-start md:w-[85%] w-[60%]">
            <Img src="images/img_user_teal_50_03.svg" alt="user_one" className="h-12 my-5 mt-20 items-start" />
              <a href="#">
                  <Text size="15xl" as="p">
                    Forgot Password?
                  </Text>
                </a>
                <Text size="8xl" as="p" className="mt-[9px] !text-black-900_82 md:text-left text-center">
                  No worries, we’ll send you reset instructions.
                </Text>
              <div className="flex flex-col self-stretch items-start mt-[18px] gap-1.5">
                <Text size="6xl" as="p" className="ml-1 md:ml-0 !text-gray-800_03 !font-hankengrotesk">
                  Email
                </Text>
                <Input shape="square" name="email" className="self-stretch sm:pr-5" />
              </div>
            
              <button   className= "mb-5 transition ease-in-out delay-150 w-[80%]  md:w-full  hover:-translate-y-1 hover:scale-110 duration-300  mt-11 sm:px-5 font-hankengrotesk bg-green-A700_01  text-white-A700 h-12 font-semibold  rounded-[23px]" >
              Reset Password
                </button>
              <button onClick={goBack}>
              <Heading size="xl" as="h1" className="ml-[187px] md:ml-0 !font-hankengrotesk">
                <span className="text-black-900_01">Back to&nbsp;</span>
                <span className="text-green-A700_01">Sign in</span>
              </Heading>
             </button>
              
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}
