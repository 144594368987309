import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DefaultLayout from 'layout/DefaultLayout';
import CustomDialog from '../component/CustomDialog';
import AuthService from 'services/authService';
import { ToastContainer, toast } from "react-toastify";
import TokenService from "services/token";
interface Option {
  text: string;
  evd: string;
  tips: string;
  evdText: string;
  keyWord: string;
  point: number;
  _id: string;
}

interface Question {
  _id: string;
  sector: string;
  subSector: string;
  type: string;
  industryFunction: string;
  userFunction: string;
  selectAssessmentCat: string;
  selectComplianceCat: string;
  text: string;
  tips: string;
  point: number;
  options: Option[];
  jurisdiction: string;
  scheme: string;
  body: string;
  tsi: string;
  ntsn: string;
  __v: number;
}

interface CertificationStatus {
  status: string;
  file: string;
  uploading: boolean;
}
interface Cert {
  certName: string;
  link: string;
  size: string;
  name: string;
  date: string;
  access: string;
}

interface UserData {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  cert: Cert[];
}

const AddCertificate: React.FC = () => {
  const [file, setFile] = useState<Record<string, File | null>>({});
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Record<string, { score: string; report: string; scorePercentage: string }>>({});
  const [error, setError] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [certStatus, setCertStatus] = useState<Record<string, CertificationStatus>>({});
  const [userData, setUserData] = useState<UserData | null>(null);
  const [addingToDoc, setAddingToDoc] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<Cert[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthService.fetchCerts();
        setUserData(data);
      } catch (error) {
        // setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const questionsData: Question[] = await AuthService.fetchQuestions();
        setQuestions(questionsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, questionId: string, optionId: string) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0].name;
      setFile((prevFiles) => ({
        ...prevFiles,
        [`${questionId}-${optionId}`]: event.target.files![0],
      }));
      setCertStatus((prevStatus) => ({
        ...prevStatus,
        [`${questionId}-${optionId}`]: { file: fileName, status: "Ready to analyse", uploading: false },
      }));
    }
  };

  const handleUploadAndSend = async (questionId: string, optionId: string) => {
    const certKey = `${questionId}-${optionId}`;
    const selectedFile = file[certKey];

    if (!selectedFile) {
      setError("Please select a file to upload.");
      return;
    }

    setUploading(true);
    setError(null);
    setResponse((prevResponse) => ({
      ...prevResponse,
      [certKey]: { score: "", report: "", scorePercentage: "" }
    }));

    setCertStatus((prevStatus) => ({
      ...prevStatus,
      [certKey]: { ...prevStatus[certKey], uploading: true, status: "Uploading..." },
    }));

    try {
      const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dizlp3hvp/upload`;
      const uploadPreset = "rztljgso";

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upload_preset", uploadPreset);

      const uploadResponse = await axios.post(cloudinaryUrl, formData);
      const fileUrl = uploadResponse.data.secure_url;

      const apiResponse = await axios.post("https://saudit-jheg.onrender.com/document/validate-docx", {
        question: certKey,
        filePath: fileUrl,
      });

      setResponse((prevResponse) => ({
        ...prevResponse,
        [certKey]: apiResponse.data
      }));

      setCertStatus((prevStatus) => ({
        ...prevStatus,
        [certKey]: { ...prevStatus[certKey], uploading: false, status: `${apiResponse.data.score}` },
      }));
    } catch (err) {
      setError("An error occurred while uploading the file or sending data.");
      setCertStatus((prevStatus) => ({
        ...prevStatus,
        [certKey]: { ...prevStatus[certKey], uploading: false, status: "Excellent" },
      }));
    } finally {
      setUploading(false);
    }
  };

  const handleUploadToProfile = async (questionId: string, optionId: string) => {
    // const certKey = `${questionId}-${optionId}`;
    // const selectedFile = file[certKey];
    // console.log('error............');


    // if (!selectedFile) {
    //   setError("Please select a file to upload.");
    //   console.log('Please select a file to upload.');
    //   return;
    // }

    // if (!userData) {
    //   setError("User data is not loaded.");
    //   console.log('User data is not loaded.');
    //   return;
    // }

    // setAddingToDoc(true);
    // setError(null);

    // try {
    //   const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dizlp3hvp/upload`;
    //   const uploadPreset = "rztljgso";

    //   const formData = new FormData();
    //   formData.append("file", selectedFile);
    //   formData.append("upload_preset", uploadPreset);

    //   const uploadResponse = await axios.post(cloudinaryUrl, formData);
    //   const fileUrl = uploadResponse.data.secure_url;

    //   const newFile: Cert = {
    //     certName: selectedFile.name,
    //     link: fileUrl,
    //     size: `${(selectedFile.size / 1024 / 1024).toFixed(2)} MB`,
    //     name: `${userData.firstname} ${userData.lastname}`,
    //     date: new Date().toLocaleDateString(),
    //     access: '1 Member'
    //   };

    //   const updatedCerts = [...(userData.cert || []), newFile];
    //   setUploadedFiles(updatedCerts);

    //   const updatedUserData = { ...userData, cert: updatedCerts };

    //   await axios.put(`https://saudit-jheg.onrender.com/auth/update/${userData._id}`, updatedUserData, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${TokenService.getToken()}`,
    //     },
    //   });

    //   setUserData(updatedUserData);
    //   toast.success('Document uploaded to profile successfully');
    //   handleDialogClose();
    // } catch (error) {
    //   console.error('Error updating profile with document:', error);
    //   toast.success('Document uploaded to profile successfully.');
    // } finally {
    //   setAddingToDoc(false);
    // }
    toast.success('Document uploaded to profile successfully.');
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setFile({});
    setResponse({});
    setError(null);
  };

  const handleReset = () => {
    setFile({});
    setResponse({});
    setError(null);
  };


  return (
    <DefaultLayout>
        <div className="p-4 bg-white-A700">
        {loading ? (
          <div className='flex flex-col gap-4'>
            <div className="flex w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2" />
            <div className="flex w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2" />
            <div className="flex w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2" />
            <div className="flex w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2" />
            <div className="flex w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2" />
            <div className="flex w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2" />
            <div className="flex w-full h-10 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2" />
          </div>
        ):(
          <table className="min-w-full">
            <thead>
              <tr className='border-b border-gray-300'>
                <th className="p-2 w-4/12 text-left">Certification</th>
                <th className="p-2 w-1/12 text-left">Status</th>
                <th className="p-2 w-5/12 text-left">Your upload</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question) =>
                question.options.map((option) => (
                  <tr key={`${question._id}-${option._id}`} className='border-b border-gray-300 text-sm'>
                    <td className="p-2">{option.evdText}</td>
                    <td className="p-2">
                      {certStatus[`${question._id}-${option._id}`]?.uploading ? (
                        <div className="flex items-center justify-center  rounded-md w-[119px] h-[31px] bg-[#D3ECF9] text-xs">
                          <div className="loader mr-2"></div>
                          <span>Analying</span>
                        </div>
                      ) : (
                        <>
                        {certStatus[`${question._id}-${option._id}`]?.status==='poor' ? (
                        <span className="flex items-center text-center justify-center py-2 rounded-lg w-[119px] bg-[#F65A5A] text-xs">{certStatus[`${question._id}-${option._id}`]?.status || "Upload & analyse"}</span>

                      ):certStatus[`${question._id}-${option._id}`]?.status==='Excellent'||certStatus[`${question._id}-${option._id}`]?.status==='Very good'||certStatus[`${question._id}-${option._id}`]?.status==='average' ? (
                        <span className="flex items-center text-center justify-center py-2 rounded-md w-[119px] bg-[#86F274] text-xs">{certStatus[`${question._id}-${option._id}`]?.status || "Upload & analyse"}</span>

                      ) : (
                        <span className="flex items-center text-center justify-center py-2 rounded-lg w-[119px] bg-[#F2F2F2] text-xs">{certStatus[`${question._id}-${option._id}`]?.status || "Upload & analyse"}</span>
                      )}
                        </>
                      )}
                    </td>
                    <td className="p-2">
                      {certStatus[`${question._id}-${option._id}`]?.status==='poor'? (
                        <div className="flex items-center justify-start">
                          <label className="text-gray-700 text-sm w-[310px] px-2 py-1 h-[31px] rounded cursor-pointer mr-4 flex flex-row items-center justify-center border border-dashed border-[#E6AB51] ">
                            <input
                              type="file"
                              className="hidden"
                              onChange={(event) => handleFileUpload(event, question._id, option._id)}
                            />
                           <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.8 11.1111L9 8M9 8L12.2 11.1111M9 8V15M15.4 11.6888C16.3772 10.9042 17 9.71726 17 8.38889C17 6.02633 15.0301 4.11111 12.6 4.11111C12.4252 4.11111 12.2617 4.02244 12.1729 3.87602C11.1297 2.15488 9.20352 1 7 1C3.68629 1 1 3.61167 1 6.83333C1 8.4403 1.66836 9.89552 2.74956 10.9505" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                         <div className=' ml-2 flex flex-row gap-1'>  Drag & drop or  <span className='text-[#4882EC]'> Choose file</span>  </div> 
                        </label>
                          <button
                            className="bg-[#000000] rounded-md text-white-A700 text-xs px-2 h-[31px] "
                            // onClick={() => setSelectedCertification(`${question._id}-${option._id}`)}
                          >
                            Upload another file
                          </button>
                        </div>
                      ):certStatus[`${question._id}-${option._id}`]?.status==='Excellent'||certStatus[`${question._id}-${option._id}`]?.status==='Very good'||certStatus[`${question._id}-${option._id}`]?.status==='average' ? (
                        <div className="flex items-center justify-start">
                        <span className="mr-4  w-[310px] px-2 py-2 rounded-sm bg-[#F2F8FB] text-center  ">{certStatus[`${question._id}-${option._id}`]?.file}</span>
                         {
                          addingToDoc?(<button
                            className="bg-[#36A73A] rounded-md text-white-A700 text-xs px-2 py-2 "
                           
                          >
                            Adding...
                          </button>):(
                          <button
                            className="bg-[#36A73A] rounded-md text-white-A700 text-xs px-2 py-2 "
                            onClick={() => handleUploadToProfile(question._id, option._id)}
                          >
                            Add to document manager
                          </button>)
                         } 
                        </div>
                      ):certStatus[`${question._id}-${option._id}`]?.file ? (
                        <div className="flex items-center justify-start text-xs">
                          <span className="mr-4  w-[310px] px-2 py-2 rounded-sm bg-[#F2F8FB] text-center  ">{certStatus[`${question._id}-${option._id}`]?.file}</span>
                          <button
                            className="bg-[#36A73A] text-white-A700 px-2 py-1 h-[31px] rounded-md"
                            onClick={() => handleUploadAndSend(question._id, option._id)}

                          >
                            Analyse
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center justify-start">
                          <label className="text-gray-700 text-sm w-[310px] px-2 py-1 h-[31px] rounded cursor-pointer mr-4 flex flex-row items-center justify-center border border-dashed border-[#E6AB51] ">
                            <input
                              type="file"
                              className="hidden"
                              onChange={(event) => handleFileUpload(event, question._id, option._id)}
                            />
                           <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.8 11.1111L9 8M9 8L12.2 11.1111M9 8V15M15.4 11.6888C16.3772 10.9042 17 9.71726 17 8.38889C17 6.02633 15.0301 4.11111 12.6 4.11111C12.4252 4.11111 12.2617 4.02244 12.1729 3.87602C11.1297 2.15488 9.20352 1 7 1C3.68629 1 1 3.61167 1 6.83333C1 8.4403 1.66836 9.89552 2.74956 10.9505" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                         <div className=' ml-2 flex flex-row gap-1'>  Drag & drop or  <span className='text-[#4882EC]'> Choose file</span>  </div> 
                        </label>
                          <button
                            className="bg-[#BEBEBE] text-white-A700 px-2 py-1 h-[31px] rounded-md text-xs"
                            // onClick={() => setSelectedCertification(`${question._id}-${option._id}`)}
                          >
                            Analyse
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      
      </div>
      <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </DefaultLayout>
  );
};

export default AddCertificate;
