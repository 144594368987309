
import React from "react";

interface UserDetailProps {
  author: string;
  organisation: string;
  userFunction: string;
}

const UserDetail: React.FC<UserDetailProps> = ({
  author,
  organisation,
  userFunction,
}) => {
  return (
    <div className="text-sm text-slate-800">
      Author: {author} <br />
      Organisation: {organisation} <br />
      User function: {userFunction}
    </div>
  );
};

export default UserDetail;
