import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Input, Img, Text, Button, Heading } from "../../../../components";
import { Box, LinearProgress, Switch, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DefaultLayout from "layout/DefaultLayout";
import { ReactTyped } from "react-typed";
import AuthService from "services/authService";
import { CircularProgressBar, LinearProgressBar } from "components/ProgressBar";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

const theme = createTheme({
  palette: {
      primary: {
          main: '#4caf50', // Green color
      },
  },
});
const Intro1= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 500 },
  });
  // const [selectedOption, setSelectedOption] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isStandardVisible, setIsStandardVisible] = useState(false);
  const [isRegulationVisible, setRegulationIsVisible] = useState(false);
  const [isbuttonVisible, setbuttonIsVisible] = useState(false);

  const [isstandardInternationlVisible, setIsStandardInternationlVisible] = useState(false);
  const [isstandardRegulationlVisible, setIsStandardRegulationVisible] = useState(false);

  const [isregulationInternationlVisible, setIsRegulationInternationlVisible] = useState(false);
  const [isregulationRegionalVisible, setIsRegulationRegionallVisible] = useState(false);


  const dataOne = ["standard", "framework", "Regulations", ];
  const dataTwo = ["school", "class", "teacher","achievement", "home", "foods"];
  const [selectedOption, setSelectedOption] = useState('');


  const handleCategoryOptionChange = (event) => {
     setSelectedOption(event.target.value);
     if (event.target.value === "Standards") {
      setIsStandardVisible(true);
      setIsVisible(false);
      setRegulationIsVisible(false);
  }
    else{
      setIsStandardVisible(false);
      setIsVisible(false);
      setRegulationIsVisible(true);
    }
  };


  const [selectedSeconOption, setSelectedSeconOption] = useState<string[]>([]);
  const [selectedStandardOption, setSelectedStandardOption] = useState('');
  const [selectedRegulationOption, setSelectedRegulationOption] = useState('');

  const [isstandardInternational, setIsStandardInternationl] = useState('');
  const [isstandardRegulationl, setIsStandardRegulation] = useState('');

  const [isregulationInternational, setIsRegulationInternationl] = useState('');
  const [isregulationRegional, setIsRegulationRegional] = useState('');




  const handleStandardOptionChange = (event) => {
    setSelectedStandardOption(event.target.value);
    if (event.target.value === "International") {
    setIsStandardInternationlVisible(true);
    setIsStandardRegulationVisible(false);
 }
   else if (event.target.value === "regional") {
    setIsStandardInternationlVisible(false);
    setIsStandardRegulationVisible(true);
}
  
  };


  const handleRegulationOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };

  const handleRegulationInternationaOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };
  
  const handleRegulationRegionalOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };

  const handleStandardInternationaOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };

  const handleStandardRegionalOptionChange = (event) => {
    setSelectedRegulationOption(event.target.value);
    if (event.target.value === "Standards") {
     setIsStandardVisible(true);
     setIsVisible(false);
     setRegulationIsVisible(false);
 }
   else{
     setIsStandardVisible(false);
     setIsVisible(false);
     setRegulationIsVisible(true);
   }

  };
  
  const [selectedMultiOptions, setSelectedMultiOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: string }>({});
  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleCheckboxChange = (value: string) => {
    setSelectedMultiOptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value]
    );

    // Optional: You can also update selectedOptions if necessary
    if (value === 'standard_sasb' || value === 'standard_gri' || value === 'standard_issb') {
      handleSelection(4, value);
    }

    // If currentStep is 5 or more, set it to 6
    if (currentStep >= 5) {
      setCurrentStep(6);
    }
  };

  const handleSelection = (step: number, option: string) => {
    setSelectedOptions({ ...selectedOptions, [step]: option });
    setCurrentStep(step + 1); // Move to next step
  };
  
  
  
  return (
    <>
     <Helmet>
        <title>SALI | Intro</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="h-screen py-4 px-6 bg-[#F5F4F4] overflow-scroll flex flex-col gap-8">
      <div className="flex flex-col gap-2">
      <Text  className="text-xl md:text-xl sm:text-base my-5">
      Select sustainability compliance category
              </Text>
      <div className="flex flex-row sm:flex-col gap-2">

      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          className="border-black-900_01 border-opacity-20"
          value="Regulations"
          onChange={() => handleSelection(1, 'Regulations')}
         
          checked={selectedOptions[1] === 'Regulations'}
        />
        Regulations & Frameworks
      </label>
    </div>
    <div 
    className={`transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-[#5BBA47]  rounded-md ${
      currentStep >= 2 && selectedOptions[1] === 'standard'?
      'bg-[#5BBA47] text-white-A700':'bg-[#EEFFEF] text-[#5BBA47]'
    }`}
  >

    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input
          type="radio"
          className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-full bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 "
          value="standard"
          onChange={() => handleSelection(1, 'standard')}
          checked={selectedOptions[1] === 'standard'}
        />
        Standards
      </label>
      </div>
      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          value="guidance&codesofpractices"
          className="border-black-900_01 border-opacity-20"
          checked={selectedOption === 'codesofpractices'}
        />
        Guidance & Codes of Practices
      </label>
      </div>
      </div>
      </div>
     
       {currentStep >= 2 && selectedOptions[1] === 'Regulations' && (
        <div>
          <div className="flex flex-col gap-2">
            <Text  className="text-xl md:text-xl sm:text-base my-4">
            Jurisdiction
              </Text>
         <div className="flex flex-row sm:flex-col gap-2">

  <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start  justify-start border border-gray-400 rounded-md">
    <label   className=" flex flex-row gap-2 items-center justify-center"  >
        <input

            type="radio"
            // name="regulation_type"
            value="international_regulations"
            onChange={() => handleSelection(2, 'international_regulations')}
            checked={selectedOptions[2] === 'international_regulations'}
        />
       International
      </label>
    </div>
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
    <label   className="flex flex-row gap-2 items-center justify-center  text-black-900_01 text-opacity-20"  >
        <input
            type="radio"
            // name="regulation_type"
            value="Regional"
            className="border-black-900_01 border-opacity-20"
            // onChange={() => handleSelection(2, 'Regional')}
            checked={selectedOptions[2] === 'Regional'}
        />
        Regional
      </label>
      </div>
      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center  text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          value="countyspecific"
          className="border-black-900_01 border-opacity-20"
          checked={selectedOptions[2] === 'countyspecific'}
        />
        Country Specific
      </label>
      </div>
        </div>
       
        </div>
        </div>
      )}
      {currentStep >= 2 && selectedOptions[1] === 'standard' && (
         <div className="flex flex-col gap-2">
            <Text  className="text-xl md:text-xl sm:text-base my-4">
            Jurisdiction
              </Text>
         <div className="flex flex-row sm:flex-col gap-2">

         <div 
    className={`transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-[#5BBA47]  rounded-md ${
      currentStep >= 3 && selectedOptions[2] === 'international_standard'?
      'bg-[#5BBA47] text-white-A700':'bg-[#EEFFEF] text-[#5BBA47]'
    }`}
  >

    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input
          type="radio"
            // name="standard_type"
             className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-full bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 "
            value="international_standard"
            onChange={() => handleSelection(2, 'international_standard')}
            checked={selectedOptions[2] === 'international_standard'}
        />
       International
      </label>
    </div>
   
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          className="border-black-900_01 border-opacity-20"
            value="standard_regional"
            onChange={() => handleSelection(2, 'standard_regional')}
            checked={selectedOptions[2] === 'standard_regional'}
        />
        Regional
      </label>
      </div>
      
      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          className="border-black-900_01 border-opacity-20"
          value="standard_country"
          onChange={() => handleSelection(2, 'standard_country')}
          checked={selectedOptions[2] === 'standard_country'}
        />
        Country Specific
      </label>
      </div>
    </div>
       
        </div>
      )}
       {currentStep >= 3 && selectedOptions[2] === 'international_regulations' && (
        <div>
          <div className="flex flex-col gap-2">
            <Text  className="text-xl md:text-xl sm:text-base my-4">
            Scheme
              </Text>
         <div className="flex flex-row sm:flex-col gap-2">

  <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start  justify-start border border-gray-400 rounded-md">
    <label   className=" flex flex-row gap-2 items-center justify-center "  >
        <input

            type="radio"
            // name="regulation_type"
            value="international_regulations_sdgs"
            onChange={() => handleSelection(3, 'international_regulations_sdgs')}
            className="border-black-900_01 border-opacity-20"
            checked={selectedOptions[3] === 'international_regulations_sdgs'}
        />
       SDGs
      </label>
    </div>
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
    <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-2"  >
        <input
            type="radio"
            // name="regulation_type"
            value="international_regulations_ghgprotocols"
            className="border-black-900_01 border-opacity-20"
            // onChange={() => handleSelection(3, 'international_regulations_ghgprotocols')}
            checked={selectedOptions[3] === 'international_regulations_ghgprotocols'}
        />
        GHG Protocols
      </label>
      </div>
      
    </div>
       
        </div>
        </div>
      )}
      {currentStep >= 3 && selectedOptions[2] === 'international_standard' && (
         <div className="flex flex-col gap-2">
            <Text  className="text-xl md:text-xl sm:text-base my-4">
            Scheme
              </Text>
         <div className="flex flex-row sm:flex-col gap-2">

         <div 
    className={`transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-[#5BBA47]  rounded-md ${
      currentStep >= 4 && selectedOptions[3] === 'standard_general'?
      'bg-[#5BBA47] text-white-A700':'bg-[#EEFFEF] text-[#5BBA47]'
    }`}
  >

    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input

         type="radio"
            // name="standard_type"
            value="standard_general"
            onChange={() => handleSelection(3, 'standard_general')}
                      className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-full bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 "

            checked={selectedOptions[3] === 'standard_general'}
        />
       General
      </label>
    </div>
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          className="border-black-900_01 border-opacity-20"
            value="ISO"
            // onChange={() => handleSelection(3, 'ISO')}
            checked={selectedOptions[3] === 'ISO'}
        />
        ISO
      </label>
      </div>
      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex p-5  sm:w-full items-start border justify-start border-black-900_01 border-opacity-20 rounded-md">
      <label   className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20"  >
        <input
          type="radio"
          className="border-black-900_01 border-opacity-20"
          value="industryspecific"
        
          checked={selectedOptions[3] === 'industryspecific'}
        />
        Industry specific
      </label>
      </div>
    </div>
       
        </div>
      )}
       {currentStep >= 3 && selectedOptions[2] === 'standard_regional' && (
        <div>
          <div className="flex flex-col gap-2">
            <Text  className="text-xl md:text-xl sm:text-base my-4">
            Scheme
              </Text>
         <div className="flex flex-row sm:flex-col gap-2">

  <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start  justify-start border border-gray-400 rounded-md">
    <label   className=" flex flex-row gap-2 items-center justify-center"  >
        <input

            type="radio"
            // name="regulation_type"
            value="europe"
            onChange={() => handleSelection(3, 'europe')}
            checked={selectedOptions[3] === 'europe'}
        />
       Europe
      </label>
    </div>
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-gray-400 rounded-md">
    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input
            type="radio"
            // name="regulation_type"
            value="northamerica"
            // onChange={() => handleSelection(3, 'northamerica')}
            checked={selectedOptions[3] === 'northamerica'}
        />
        North America
      </label>
      </div>
      <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-gray-400 rounded-md">
    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input
            type="radio"
            // name="regulation_type"
            value="africa"
            // onChange={() => handleSelection(3, 'africa')}
            checked={selectedOptions[3] === 'africa'}
        />
        Africa
      </label>
      </div>
    </div>
       
        </div>
        </div>
      )}
      {currentStep >= 3 && selectedOptions[2] === 'standard_country' && (
         <div className="flex flex-col gap-2">
            <Text  className="text-xl md:text-xl sm:text-base my-4">
            Scheme
              </Text>
         <div className="flex flex-row sm:flex-col gap-2">

  <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start  justify-start border border-gray-400 rounded-md">
    <label   className=" flex flex-row gap-2 items-center justify-center"  >
        <input

         type="radio"
            // name="standard_type"
            value="unitedkingdom"
            onChange={() => handleSelection(3, 'unitedkingdom')}
            checked={selectedOptions[3] === 'unitedkingdom'}
        />
       United Kingdom
      </label>
    </div>
    <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex  sm:w-full p-5 items-start border justify-start border-gray-400 rounded-md">
    <label   className="flex flex-row gap-2 items-center justify-center"  >
        <input
            type="radio"
            // name="standard_type"
            value="unitedstates"
            onChange={() => handleSelection(3, 'unitedstates')}
            checked={selectedOptions[3] === 'unitedstates'}
        />
        United States
      </label>
      </div>
     
    </div>
       
        </div>
      )}
    
    {currentStep >= 4 && (
        <div className="flex flex-col gap-2">
          <Text className="text-xl md:text-xl sm:text-base my-4">
            Body (Select one or multiple options)
          </Text>
          <div className="flex flex-row sm:flex-col gap-2">
            {['standard_gri', 'standard_sasb', 'standard_issb'].map((value) => (
              <div
                key={value}
                className={`transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 flex p-5 sm:w-full items-center justify-start border rounded-md cursor-pointer ${
                  selectedMultiOptions.includes(value) ? 'bg-[#5BBA47] text-white-A700' : 'bg-[#EEFFEF] text-[#5BBA47] border-[#5BBA47]'
                }`}
              >
                <label className="flex flex-row gap-2 items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-md bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 focus:outline-none"
                    value={value}
                    checked={selectedMultiOptions.includes(value)}
                    onChange={() => handleCheckboxChange(value)}
                  />
                  <span>
                    {value === 'standard_gri' && 'GRI 305 Emissions'}
                    {value === 'standard_sasb' && 'SASB'}
                    {value === 'standard_issb' && 'ISSB'}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      )}

      {currentStep >= 5 && (
        <div className="flex flex-col gap-2">
          <Text className="text-xl md:text-xl sm:text-base my-4">
            Title (Select one or multiple options)
          </Text>

          {selectedMultiOptions.includes('standard_sasb') && (
            <div className="flex flex-row sm:flex-col gap-2">
              {['standard_gri_rail', 'standard_gri_engrg', 'standard_gri_waste'].map((value) => (
                <div
                  key={value}
                  className={`transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 flex p-5 sm:w-full items-start border justify-start ${
                    selectedMultiOptions.includes(value) ? 'bg-[#5BBA47] text-white-A700'
                      : 'bg-[#EEFFEF] text-[#5BBA47] border-[#5BBA47]'
                  } rounded-md checked:bg-[#5BBA47]`}            
                      >
                  <label className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20">
                    <input
                      type="checkbox"
                      className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-md bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 focus:outline-none"
                      value={value}
                      checked={selectedMultiOptions.includes(value)}
                      onChange={() => handleCheckboxChange(value)}
                    />
                    <span>
                      {value === 'standard_gri_rail' && 'SASB: Rail Transportation'}
                      {value === 'standard_gri_engrg' && 'SASB: Engrg, Construction, Infrastructure'}
                      {value === 'standard_gri_waste' && 'SASB: Waste Management'}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          )}

          {selectedMultiOptions.includes('standard_gri') && (
            <div className="flex flex-row sm:flex-col gap-2">
              {['standard_gri_energry', 'standard_gri_water', 'standard_gri_emission'].map((value) => (
                <div
                  key={value}
                  className={`transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 flex p-5 sm:w-full items-start border justify-start ${
                    selectedMultiOptions.includes(value) ? 'bg-[#5BBA47] text-white-A700'
                      : 'bg-[#EEFFEF] text-[#5BBA47] border-[#5BBA47]'
                  } rounded-md checked:bg-[#5BBA47]`
                  }                >
                  <label className="flex flex-row gap-2 items-center justify-center text-black-900_01 text-opacity-20">
                    <input
                      type="checkbox"
                      className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-md bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 focus:outline-none"
                      value={value}
                      checked={selectedMultiOptions.includes(value)}
                      onChange={() => handleCheckboxChange(value)}
                    />
                    <span>
                      {value === 'standard_gri_energry' && 'GRI 302 Energy'}
                      {value === 'standard_gri_water' && 'GRI 303 Water & Effluents'}
                      {value === 'standard_gri_emission' && 'GRI 305 Emissions'}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          )}

          {selectedMultiOptions.includes('standard_issb') && (
            <div className="flex flex-row sm:flex-col gap-2">
              {['standard_issb_climate', 'standard_issb_general'].map((value) => (
                <div
                  key={value}
                  className={`transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 flex p-5 sm:w-full items-start border justify-start ${
                    selectedMultiOptions.includes(value) ? 'bg-[#5BBA47] text-white-A700'
                      : 'bg-[#EEFFEF] text-[#5BBA47] border-[#5BBA47]'
                  } rounded-md checked:bg-[#5BBA47]`}
                >
                  <label className="flex flex-row gap-2 items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="appearance-none w-6 h-6 border-2 border-[#5BBA47] rounded-md bg-white-A700 checked:bg-[#5BBA47] checked:border-white-A700 focus:outline-none"
                      value={value}
                      checked={selectedMultiOptions.includes(value)}
                      onChange={() => handleCheckboxChange(value)}
                    />
                    
                    <span>
                      {value === 'standard_issb_climate' && 'ISSB S1'}
                      {value === 'standard_issb_general' && 'ISSB S2 Climate'}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
                 {(currentStep >= 6) && (
         <div className="flex flex-col gap-2">
            
              <div className="w-full flex flex-row items-center justify-center mt-10 animate__animated animate__fadeInUp">
       <Link
        to="/dashboard/module/climate-intro1"
        className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 hover:bg-[#5BBA47] hover:text-white-A700 bg-[#E5EEF0] w-24 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
        >
        <button
         className="w-full" >
         Continue
         </button>
       </Link>
       </div>
       
        </div>
      )}
    </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {Intro1};
const Intro2= () =>  {

const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });
  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/intro1"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
    
       
    
      <Text  className="text-3xl md:text-2xl sm:text-xl"
      >
      In which transportation subsector are you involved?
              </Text>
     <animated.div ref={ref} style={animation}>       
      <div className="flex flex-row md:flex-col  gap-5 mt-5">
      <Link
       to="/dashboard/module/intro3"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Railway
        </button>
                    </Link>
  
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Road
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Aviation
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Marine
        </button>
      </div>
      </animated.div> 

        

      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {Intro2};
const Intro3= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   
  <animated.div ref={ref} style={animation}>
      <div className="w-full md:flex-col p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/intro2"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
       
      
      <Text  className="text-3xl md:text-2xl sm:text-xl">
      Select your rail transport category?
              </Text>
      <div className="flex md:flex-col flex-row  gap-5 mt-5">
      <Link
       to="/dashboard/module/intro4"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Light Rail
        </button>
                    </Link>
  
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Heavy Rail
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[20%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Metro
        </button>
      </div>
        

        

      </div>
      </animated.div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {Intro3};
const Intro4= () =>  {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const animation = useSpring({
    opacity: 1,
    transform: inView ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 500 },
  });

  return (
    <>
     <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
      <DefaultLayout>   

      <div className="w-full p-4 md:p-6 2xl:p-10  h-screen ">
      <Link
       to="/dashboard/module/intro3"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-50 w-20 mt-2.5 sm:px-5 font-normal text-sm px-4 py-2 rounded-xl mb-10"
       >
                     <button
          className="w-full"
        >
            back
        </button>
      </Link>
       
      
      <Text  className="text-3xl md:text-2xl sm:text-xl">
      Select your rail transport category?
              </Text>
      <animated.div ref={ref} style={animation}>       
      <div className="flex flex-row md:flex-col  gap-5 mt-5">
      <Link
       to="/dashboard/module"
       className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
       >
                     <button
          className="w-full"
        >
         Railway Owner
        </button>
                    </Link>
  
        <button
          className=" transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Railway Operator
        </button>
        <button
          className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-green-100 mt-2.5 w-[30%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Railway Maintaner
        </button>
      </div>
      <div className="flex md:flex-col flex-row  gap-5 mt-5">
      
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
        Engineering and Construction
        </button>
        <button
          className="bg-green-100 mt-2.5 w-[40%] md:w-full sm:px-5 font-normal md:text-base text-xl px-4 py-5 rounded-3xl"
        >
         Manufacturing ( Trains, Signaling,
Track, Communication Systems)
        </button>
      </div>
      </animated.div>
        

      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </DefaultLayout>
    </>
  );
}
export  {Intro4};