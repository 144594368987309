import React, { useEffect, useState } from "react";
import { Text, Button } from 'components';
import DefaultLayout from "layout/DefaultLayout";
import AuthService from "services/authService";
import TokenService from "services/token";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";

interface Cert {
  certName: string;
  link: string;
  size: string;
  name: string;
  date: string;
  access: string;
}

interface UserData {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  cert: Cert[];
}

const AddDocs: React.FC = () => {
  
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<Cert[]>([]);
  const [userData, setUserData] = useState<UserData | null>(null);
  const userDatas = AuthService.getUserData();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthService.fetchCerts();
        setUserData(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && userData) {
      const newFiles: Cert[] = await Promise.all(Array.from(files).map(async file => {
        const link = await uploadFileToCloudinary(file);
        return {
          certName: file.name,
          link,
          size: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
          name: `${userData.firstname} ${userData.lastname}`,
          date: new Date().toLocaleDateString(),
          access: '1 Member'
        };
      }));

      const updatedCerts = [...(userData.cert || []), ...newFiles];
      toast.success('Document uplaoded successfully.', {
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      setUploadedFiles(updatedCerts);

      const updatedUserData = {
        ...userData,
        cert: updatedCerts
      } as UserData;

      try {
        const id = userDatas._id;
        await axios.put(`https://saudit-jheg.onrender.com/auth/update/${id}`, updatedUserData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getToken()}`,
          },
        });
        setUserData(updatedUserData);
      } catch (error) {
        console.error('Error updating certs:', error);
        setError(error as Error);
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="p-4 w-full px-10 bg-white gap-4">
        <div className="flex justify-between items-center gap-5">
          <Text size="14xl" as="p" className="my-5">
            Documents
          </Text>
          <Button color="black" className="bg-black text-white rounded-10px">
            Save & Continue
          </Button>
        </div>
        <div className="p-4 w-full mx-auto">
          <div className="border-2 border-dashed border-gray-300 rounded h-40 flex items-center justify-center p-6 text-center bg-green-50 mb-4">
            <label className="cursor-pointer">
              <span className="block mb-2">Drag and drop file or <span className="text-blue-600 underline">choose file</span></span>
              <input type="file" className="hidden" accept="application/pdf" multiple onChange={handleFileUpload} />
            </label>
          </div>
          <p className="text-sm text-gray-500">Maximum size 25MB</p>

          {loading ? (
             <div className='flex flex-col gap-4'>
             <div  className="flex  w-full h-16 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse my-2 "/>
      
              <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
              <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
              <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
              <div  className="flex  w-full h-8 flex-col items-center rounded-sm bg-green-A700_01 bg-opacity-10 animate-pulse mt-2 "/>
      
              </div>
          ) : error ? (
            <p>Error: {error.message}</p>
          ) : (
            <div className="rounded-sm bg-white px-5 pt-6 pb-2.5 shadow-default text-base">
              {userData?.cert && userData.cert.length > 0 ? (
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-200 text-left text-gray-600 border-b border-gray-300">
                      <th className="py-4 px-4">File name</th>
                      <th className="py-4 px-4">Date</th>
                      <th className="py-4 px-4">Access</th>
                      <th className="py-4 px-4">File Size</th>
                      <th className="py-4 px-4">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.cert.reverse().map((cert, index) => (
                      <tr key={index}>
                        <td className="border-b border-gray-300 py-5 px-4 flex items-center gap-4">
                        <img
                         loading="lazy"
                         srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                          className="shrink-0 h-6 w-6"
                         />
                          {cert.certName}
                        </td>
                        <td className="border-b border-gray-300 py-5 px-4">{cert.date}</td>
                        <td className="border-b border-gray-300 py-5 px-4">{cert.access}</td>
                        <td className="border-b border-gray-300 py-5 px-4">{cert.size}</td>
                        <td className="border-b border-gray-300 py-5 px-4 text-center">
                          <button>
                            <svg width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.97 16c-1.57 0-2.88-1.2-3.01-2.8L1.18 5.33H0.92C0.4 5.33 0 4.93 0 4.4c0-0.53 0.4-0.93 0.92-0.93h2.36V2.67C3.27 1.2 4.45 0 5.89 0h2.23C9.55 0 10.73 1.2 10.73 2.67v0.67h2.35c0.53 0 0.92 0.4 0.92 0.93 0 0.67-0.52 0.93-1.04 0.93h-0.26L11.91 13.07c-0.13 1.6-1.44 2.8-3.01 2.8H4.97zM3.93 12.93c0 0.53 0.52 1.07 1.04 1.07h3.93c0.53 0 1.05-0.4 1.05-1.07L10.73 5.33H3.14l0.79 7.6zm4.71-9.6V2.67c0-0.4-0.27-0.67-0.66-0.67H5.76c-0.39 0-0.66 0.27-0.66 0.67v0.67h3.53z" fill="#212121" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No certifications available.</p>
              )}
            </div>
          )}
        </div>
      </div>
      <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </DefaultLayout>
  );
};

export default AddDocs;

const uploadFileToCloudinary = async (file: File): Promise<string> => {
  const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dizlp3hvp/upload';
  const uploadPreset = 'efgps0l1';
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', 'rztljgso');
  formData.append('resource_type', 'auto'); 


  try {
    const response = await axios.post(cloudinaryUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.secure_url;
  } catch (error) {
    console.error('Error uploading to Cloudinary:', error);
    throw error;
  }
};
