
import React, { useState } from "react";
import './SliderComponent.css'; 
import ReactSlider from 'react-slider';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const PricingSection: React.FC = () => {
  const [value, setValue] = useState<number>(2000);

  const handleSliderChange = (newValue: number) => {
    setValue(newValue);
  };

  // const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValue(parseInt(event.target.value));
  // };
  return (
    <section className="mt-9 md:w-full">
      <div className="flex gap-5 md:flex-col">
        <div className="flex flex-col w-full md:ml-0 md:w-full">
          <div className="flex flex-col items-start mt-1.5 w-full md:mt-10">
            <div className="w-full flex flex-row justify-between">
            <h3 className="text-sm font-bold text-black">
              Number of Employees
            </h3>
            <div className="px-2 py-2  text-sm font-bold text-black bg-emerald-50 rounded-sm md:mt-10">
            25 employees
            </div>
            </div>
           
     <div className="w-full  mt-10">
     <Slider
        min={1000}
        max={10000}
        value={value}
        onChange={handleSliderChange}
        handleStyle={{
          borderColor: 'black',
          height: 20,
          width: 20,
          backgroundColor: 'black',
        }}
        railStyle={{ backgroundColor: '#D1D5DB' }} 
        trackStyle={{ backgroundColor: 'black' }}
      />
      <div className="text-5xl font-bold mt-5 ">
        £{value} <span className="text-xl">/year</span>
      </div>
    </div>
       

          </div>
        </div>
       
      </div>
    </section>
  );
};

export default PricingSection;
