import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel  } from "../../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import AuthService from "services/authService";



const SdgsSection: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);





 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refabout, inViewAbout] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThing, inViewEveryThing] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refEveryThingRight, inViewEveryThingRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadBottom, inViewDownloadBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refDownloadLeft, inViewDownloadLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadRight, inViewDownloadRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThing = useSpring({
    opacity: inViewEveryThing ? 1 : 0,
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationEveryThingLeft = useSpring({
    opacity: inViewEveryThingLeft ? 1 : 0,
    transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationEveryThingRight = useSpring({
    opacity: inViewEveryThingRight ? 1 : 0,
    transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThingBottom = useSpring({
    opacity: inViewEveryThingBottom ? 1 : 0,
    transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });


  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const bottomAboutAnimation = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const items = [
    { id: 1, name: 'images/img_18.png' },
    { id: 2, name: 'images/img_19.png' },
    { id: 3, name: 'images/img_download.png' },
    { id: 4, name: 'images/img_screen_shot_2024_01_18.png' },
    { id: 5, name: 'images/img_5.png' },
    { id: 6, name: 'images/img_sustainable_dev.png' },
    { id: 7, name: 'images/img_e_sdg_goals_ico.png' },
    { id: 8, name: 'images/img_8.png' },
    { id: 9, name: 'images/img_9.png' },
    { id: 10, name: 'images/img_64.png' },
    { id: 11, name: 'images/img_screen_shot_2024_01_18_107x107.png' },
    { id: 12, name: 'images/img_screen_shot_2024_01_18_1.png' },
    { id: 13, name: 'images/img_sustainable_dev_107x107.png' },
    { id: 14, name: 'images/img_sustainable_dev_1.png' },
    { id: 15, name: 'images/img_download_4.png' },
    { id: 16, name: 'images/img_56.png' },
    { id: 17, name: 'images/img_57.png' },
    { id: 18, name: 'images/img_screen_shot_2024_01_19.png' },
  ];
  return (
    <>


   <div className="w-full  flex flex-col items-center justify-center px-10 md:px-4 my-20">
               <  Heading  className="!text-black-900_01 !font-hankengrotesk  text-3xl md:text-2xl  text-center ">
               SALI simplifies CSRD and ESG compliance and helps align with UN SDGs.​
                </Heading>
                <p  className=" md:w-full !text-gray-400_10   text-base  md:text-sm text-black-900_01 text-center font-thin ">
                  <>
                  The 17 SDGs Connected to Our Modules
                  </>
                </p>
    
        
          <div className="grid grid-cols-9 md:grid-cols-3 gap-2 w-full my-10">
      {items.map(item => (
        <div key={item.id}>
      
        <Img src={item.name} alt="eighteen_one" className=" transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 object-cover rounded-[5px]" />
        </div>
    
      ))}
          </div>
         
     
   </div>


    </>
  );
};

export default SdgsSection;
