
import React from "react";

const SectorIndustry: React.FC = () => {
  return (
    <div className="flex flex-col mt-4 w-full md:mt-10 md:w-full">
      <div className="flex flex-wrap gap-6 md:mr-1">
        <div className="flex flex-col flex-1">
        <h2 className="self-start text-xl font-medium leading-none text-zinc-600">
       Sector
      </h2>
          <div className="flex gap-5 justify-between  p-2 mt-4 h-10 text-sm font-medium rounded-md border border-solid border-stone-300 text-zinc-500">
            <select
              id="sector"
              className="w-full bg-transparent appearance-none"
            >
              <option>Select Sector</option>
            </select>
            
          </div>
        </div>
        <div className="flex flex-col flex-1">
        
          <h2 className="self-start text-xl font-medium leading-none text-zinc-600">
          Industry
         </h2>
          <div className="flex gap-5 justify-between  p-2 mt-4 h-10 text-sm font-medium rounded-md border border-solid border-stone-300 text-zinc-500">
            <select
              id="industry"
              className="w-full bg-transparent appearance-none"
            >
              <option>Select Industry</option>
            </select>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorIndustry;
