import React from "react";

const sizes = {
  "20xl": "text-[44px] font-medium md:text-[40px] sm:text-[34px]",
  xs: "text-[6px] font-medium",
  lg: "text-[10px] font-medium",
  "19xl": "text-[40px] font-medium md:text-[38px] sm:text-4xl",
  "18xl": "text-[39px] font-normal md:text-[37px] sm:text-[35px]",
  xl: "text-[11px] font-medium",
  "14xl": "text-3xl font-medium md:text-[28px] sm:text-[26px]",
  "15xl": "text-[34px] font-normal md:text-[32px] sm:text-3xl",
  "17xl": "text-[37px] font-medium md:text-[35px] sm:text-[33px]",
  "16xl": "text-[35px] font-normal md:text-[33px] sm:text-[31px]",
  "9xl": "text-[19px] font-normal",
  "10xl": "text-xl font-normal",
  "11xl": "text-[21px] font-medium",
  "12xl": "text-2xl font-medium md:text-[22px]",
  "13xl": "text-[26px] font-normal md:text-2xl sm:text-[22px]",
  "5xl": "text-[15px] font-normal",
  "6xl": "text-base font-medium",
  "7xl": "text-[17px] font-normal",
  "8xl": "text-lg font-normal",
  "2xl": "text-xs font-normal",
  "3xl": "text-[13px] font-normal",
  "4xl": "text-sm font-medium",
  s: "text-[8px] font-normal",
  "22xl": "text-[54px] font-normal md:text-[46px] sm:text-[40px]",
  md: "text-[9px] font-medium",
  "21xl": "text-[50px] font-medium md:text-[46px] sm:text-[40px]",
  "23xl": "text-[61px] font-normal md:text-5xl",
};

export type TextProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as,
  size = "lg",
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component className={`text-black-900 font-hankengrotesk ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
