
import React from "react";

const ContactInformation: React.FC = () => {
  return (
    <section className="flex flex-col w-full mt-4 md:mt-10 md:w-full">
      <h2 className="self-start text-xl font-medium leading-none text-zinc-600">
        Contact information
      </h2>
      {/* <input
        type="email"
        placeholder="Email"
        className="px-4 py-3.5 mt-7 text-base whitespace-nowrap rounded-md border border-solid border-stone-300 text-neutral-600 md:pr-5 md:mr-2.5"
        aria-label="Email"
      /> */}
      <div    className="border border-gray-400 h-10 p-2 mt-4 w-full rounded-md" >
    <input
       type="email"
       placeholder="Email"
       
     
      />
    </div>
    </section>
  );
};

export default ContactInformation;
