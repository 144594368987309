// MyDoughnutChart.tsx
import React from 'react';
import Chart from 'react-apexcharts';

interface MyDoughnutChartProps {
  certification: number;
  standard: number;
  regulations: number;
}

const MyDoughnutChart: React.FC<MyDoughnutChartProps> = ({ certification, standard, regulations }) => {
  const series = [certification, standard, regulations];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['', '', ''],
    colors: ['#00BFA5', '#0097A7', '#00B8D4'],
    dataLabels: {
      enabled: true,
      formatter: function (val: string | number) {
        const value = typeof val === 'string' ? parseFloat(val) : val;
        return value.toFixed(0) + '%';
      },
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['#FFF'],
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: false,
            name: {
              show: true,
              offsetY: -10,
            },
            value: {
              show: true,
              formatter: function (val: string | number) {
                const value = typeof val === 'string' ? parseFloat(val) : val;
                return value.toFixed(0) + '%';
              },
            },
            // total: {
            //   show: true,
            //   showAlways: true,
            //   label: 'Total',
            //   formatter: function () {
            //     return (certification + standard + regulations) + '%';
            //   },
            // },
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="relative w-full h-80 mx-auto px-4">
      <Chart options={options} series={series} type="donut" height="80%" />
     
    </div>
  );
};

export default MyDoughnutChart;
