import React from 'react';
import { TextField, CircularProgress, Alert } from '@mui/material';
import { Button, Text, Input, SelectBox, Img } from "../../../../components";

interface PersonalInfoTabProps {
  firstname: string;
  lastname: string;
  email: string;
  department: string;
  jobtitle: string;
  companyRegNumber: string;
  sector: string;
  subSector: string;
  category: string;
  role: string;
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  handleFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDepartmentChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleJobTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCompanyRegChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSectorChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleSubSectorChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleCategoryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleRoleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleShowPassword: () => void;
  companySize: string;
  setCompanySize: (size: string) => void;
  handleTabChange: (tab: number) => void;
  loading: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  error: string;
}

const PersonalInfoTab: React.FC<PersonalInfoTabProps> = ({
  firstname,
  lastname,
  email,
  department,
  jobtitle,
  companyRegNumber,
  sector,
  subSector,
  category,
  role,
  password,
  confirmPassword,
  showPassword,
  handleFirstNameChange,
  handleLastNameChange,
  handleEmailChange,
  handleDepartmentChange,
  handleJobTitleChange,
  handleCompanyRegChange,
  handleTabChange,

  handleSectorChange,
  handleSubSectorChange,
  handleCategoryChange,
  handleRoleChange,
  handlePasswordChange,
  handleConfirmPasswordChange,
  toggleShowPassword,
  companySize,
  setCompanySize,
  loading,
  handleSubmit,
  error,
}) => {
  return (
    <form onSubmit={handleSubmit} className="w-full h-full">
                        <Text size="13xl" as="p">
                Request a demo
                </Text>
       
              
                <div className="flex sm:flex-col self-stretch gap-[9px] mt-5">
                  <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                 
                    
                    <TextField
                    label="First Name"
                    type="text"
                    value={firstname}
                    onChange={handleFirstNameChange}
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                  />
                  </div>
                 
                  <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                  
                    <TextField
                    label="Last Name"
                    value={lastname}
                    onChange={handleLastNameChange}
                    type="text"
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                  />
                  </div>
                </div>
                <div className="flex flex-col self-stretch items-start mt-1 gap-1.5">
                  
                  <TextField
                    label="Professional Email"
                    type="text"
                    fullWidth  // Make TextField full width
                    value={email}
                    onChange={handleEmailChange}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
                <div className="flex flex-col self-stretch items-start mt-1 gap-1">
                  
                  <TextField
                    label="Job Title"
                    type="text"
                    value={jobtitle}
                    onChange={handleJobTitleChange}
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                  />
                </div>
                <div className="flex flex-col self-stretch items-start mt-1 gap-1">
                  
                  <TextField
                    label="Company Name"
                    type="text"
                    value={department}
                    onChange={handleDepartmentChange}
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              
                <div className="flex flex-col self-stretch items-start mt-1 gap-1">
                  
                  <TextField
                    label="Company Reg Number"
                    type="text"
                    value={companyRegNumber}
                    onChange={handleCompanyRegChange}
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                  />
                </div>
          
                <div className="flex flex-col self-stretch items-start mt-[17px] gap-[5px]">
                <div className="flex flex-wrap mt-2 p-3 border border-gray-400 mb-5 gap-2  rounded-md w-full">
                  <select  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
                  <option value="">Select Sector</option>
                  <option value="transport">Transport</option>
                  <option value="energy">Energy</option>
                  </select>
                  </div>
                  
                <div className="flex flex-wrap mt-2 p-3 border border-gray-400 mb-5 gap-2  rounded-md w-full">
                <select   className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
                <option value="">Select Sub Sector</option>
                <option value="railway">Railway</option>
                <option value="road">Road</option>
                <option value="aviation">Aviation</option>
                <option value="marine">Marine</option>
                </select>
                </div>

                <div className="flex flex-wrap mt-2 p-3 border border-gray-400 mb-5 gap-2  rounded-md w-full">
                <select   className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
                <option value="">Select company size</option>
                <option value="0-10">0 - 10</option>
                <option value="11-50">11 - 50</option>
                <option value="51-200">51 - 200</option>
                <option value="201-500">201 - 500</option>
                <option value="Morethan00employees">More than 500 employees</option>
                </select>
                </div>
                </div>
        
                <section className="flex-wrap text-xs  text-zinc-800 md:w-full mt-10 mb-5">By accepting SALI's Privacy Policy, you consent to receiving communications from SALI through the email you provided, which may include scheduling updates and marketing information.  </section>
          {error &&  <Alert severity="error">{error}</Alert>}
                <div className="my-3 w-full">
           
      
               
        {loading ? (
                <button   className= "mb-5 transition w-full ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
                <CircularProgress size={24} />
         </button>
        ) : (
          <button type="submit"  className= "mb-5 transition w-full ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300  sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
          Next
                </button>
        )}
                </div>
      </form>
  );
};

export default PersonalInfoTab;
