import React from "react";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-[21px]",
} as const;
const variants = {
  outline: {
    white_A700: "border-white-A700 border-2 border-solid text-white-A700",
    black_900: "border-black-900 border border-solid text-black-900",
  },
  fill: {
    white_A700: "bg-white-A700 text-black-900",
    black_900: "bg-black-900 text-white-A700",
    blue_gray_50: "bg-blue_gray-50 text-black-900",
    gray_500_02: "bg-gray-500_02 text-white-A700",
    green_A700: "bg-green-A700 text-white-A700",
  },
} as const;

const sizes = {
  "17xl": "h-[65px] px-[21px] text-[21px]",
  "9xl": "h-[51px] px-5 text-base",
  "2xl": "h-[34px] px-5 text-base",
  "14xl": "h-[62px] px-[21px]",
  "15xl": "h-[62px] px-[35px] text-[10px]",
  "12xl": "h-[62px] pl-7 pr-[21px] text-[22px]",
  "20xl": "h-[83px] px-8",
  "7xl": "h-[46px] px-[13px]",
  "10xl": "h-[51px] px-[17px]",
  xl: "h-[31px] px-0.5",
  "13xl": "h-[62px] px-[34px] text-[17px]",
  "11xl": "h-[55px] px-8 text-lg",
  xs: "h-[20px] px-[7px]",
  "5xl": "h-[43px] px-[22px] text-xl",
  "8xl": "h-[46px] px-[22px] text-[13px]",
  "16xl": "h-[65px] pl-[15px] pr-2.5 text-[15px]",
  md: "h-[26px]",
  "4xl": "h-[43px] px-[18px] text-sm",
  "18xl": "h-[65px] px-[21px]",
  "19xl": "h-[67px] px-[23px] text-3xl",
  "6xl": "h-[46px] px-[35px] text-base",
  sm: "h-[25px] px-3 text-[11px]",
  "3xl": "h-[39px] px-2.5 text-[15px]",
  lg: "h-[30px] px-2 text-[11px]",
} as const;
type ButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onClick"
> &
  Partial<{
    className: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
  }>;
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "lg",
  color = "green_A700",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color as keyof (typeof variants)[typeof variant]]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
