import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel  } from "../../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import AuthService from "services/authService";
import CustomSlider from "components/CustomSlider";



const AssessmentSection: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);





 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refabout, inViewAbout] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThing, inViewEveryThing] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refEveryThingRight, inViewEveryThingRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadBottom, inViewDownloadBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refDownloadLeft, inViewDownloadLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadRight, inViewDownloadRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThing = useSpring({
    opacity: inViewEveryThing ? 1 : 0,
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationEveryThingLeft = useSpring({
    opacity: inViewEveryThingLeft ? 1 : 0,
    transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationEveryThingRight = useSpring({
    opacity: inViewEveryThingRight ? 1 : 0,
    transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThingBottom = useSpring({
    opacity: inViewEveryThingBottom ? 1 : 0,
    transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });


  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const bottomAboutAnimation = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <>

<div className="h-auto my-20 md:my-10 w-full  flex flex-col justify-center ">
          <animated.div ref={refBottom} style={bottomAnimation}>
            <div className="flex flex-col items-center  ">
              <Heading className="!text-blue_gray-900_06 text-4xl md:text-3xl sm:text-2xl md:text-center md:px-5 ">
                Our process to your <span className="text-[#00BF63]">assessment</span> 
              </Heading>
          
            </div>
            </animated.div>

      <div className="w-full mt-10 md:mt-5 ">
       {/* <MyCarousel/> */}
      <CustomSlider/>
      </div>



          </div>


    </>
  );
};

export default AssessmentSection;
