
import React from "react";

interface UserIdProps {
  userId: string;
}

const UserId: React.FC<UserIdProps> = ({ userId }) => {
  return (
    <div className="z-10 px-7 mt-2.5 text-xs text-sky-900 bg-gray-100 rounded-lg">
      User ID: {userId}
    </div>
  );
};

export default UserId;
