import React from "react";

const CustomProgressBar = ({ label, percentage, details, completed, total }) => {
    return (
      <div className="mb-8">
        <h2 className="text-lg font-semibold">{label}</h2>
        <div className="flex justify-between mb-2">
          <span className="text-3xl font-bold">{percentage}%</span>
          <span className="text-gray-500">{completed}/{total}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-[#E29418] h-2.5 rounded-full"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <div className="flex gap-8 text-xs mt-4">
          {details.map((detail, index) => (
            <div key={index} className="flex items-center space-x-1">
              <span
                className={`h-3 w-3  ${
                  detail.color
                }`}
              ></span>
              <span>{detail.text}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default CustomProgressBar;
  