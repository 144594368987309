import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel  } from "../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AuthService from "services/authService";
import { TextField } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { CgSpinner } from "react-icons/cg";
import Navbar from "./components/navbar";
import HeroSection from "./components/hero";
import AssessmentSection from "./components/assessment";
import ScopeSection from "./components/scope";
import ChangeSection from "./components/change";
import SolutionSection from "./components/solution";
import SdgsSection from "./components/sdgs";
import ModuesSection from "./components/modules";
import JouneySection from "./components/jouney";
import FooterSection from "./components/footer";



export default function LandingPagePage() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const carouselItems = [
    {
      title: "AI-Powered assessment",
      description: "Our AI-driven sustainability assessment tool meticulously examines every facet of your company's operations, scrutinizing environmental, social, and economic practices to provide a comprehensive evaluation of your sustainability performance."
    },
    {
      title: "Learning Insights",
      description: "Gain profound insights into the intricate web of your company's sustainability landscape. Our tool translates complex data into meaningful patterns, helping you learn and comprehend the nuances of your environmental impact and social responsibility."
    },
    {
      title: "Reports & Analytics",
      description: "Effortlessly generate detailed sustainability reports that go beyond mere numbers. Our tool transforms data into compelling narratives, making it easy for your company to communicate its commitment to sustainability to stakeholders, customers, and partners."
    },
    {
      title: "Benchmarked Ranking",
      description: "Compare your sustainability standing with industry benchmarks and competitors. Our tool assigns a ranking that reflects your company's commitment to sustainable practices, empowering you to set ambitious goals and stay ahead in the race towards corporate responsibility."
    },
    {
      title: "Continuous Improvements",
      description: "Armed with actionable insights, embark on a journey of continuous improvement by Identifying areas of opportunity. Implement targeted strategies to enhance your sustainability lifestyle, fostering a positive impact on the environment and society"
    }
  ];



  const moduelitems = [
    { 
      id: 1, 
      img: 'images/img_television_white_a700.svg',
      name: 'Air Emmissions', 
      content: [
        'Evaluate air quality and emissions, including greenhouse gases',
        'Identify sources of emissions and recommend reduction strategies​',
        'Assess compliance with air quality regulations'
      ]
    },
    { 
      id: 2, 
      img: 'images/img_thumbs_up_white_a700_44x38.svg',
      name: 'Waste', 
      content: [
        'Analyse waste generation and management practices​',
        'Ensure adherence to waste disposal regulations​',
        'Recommend waste reduction, recycling, and disposal solution'
      ]
    },
    { 
      id: 3, 
      img: 'images/img_qrcode.svg',
      name: 'Climate Change', 
      content: [
        'Analyse carbon footprint and emissions reduction initiatives',
        'Assess climate change adaptation  and mitigation strategies​',
        'Advise on climate-resilient practices'
      ]
    },
    { 
      id: 4, 
      img: 'images/img_settings_white_a700.svg',
      name: 'Social Sustainability', 
      content: [
        'Assess social impact, diversity, and inclusivity​',
        'Review labour practices and employee well-being​​',
        'Suggest improvements for social responsibility and ethical conduct'
      ]
    },
    { 
      id: 5, 
      img: 'images/img_close.svg',
      name: 'Environmental Sustainability', 
      content: [
        'Evaluate resource consumption and conservation efforts​​',
        'Analyse environmental policies and compliance with regulations',
        'Recommend eco-friendly practices for energy, water, and land use'
      ]
    },
    { 
      id: 6, 
      img: 'images/img_settings_white_a700_43x29.svg',
      name: 'Culture', 
      content: [
        'Examine organisational culture and values​',
        'Evaluate alignment with sustainability objectives​​',
        'Suggest measures to cultivate a sustainable culture'
      ]
    },
    { 
      id: 7, 
      img: 'images/img_television_white_a700_42x59.svg',
      name: 'Sustainable Communities', 
      content: [
        'Investigate community engagement and partnerships​​',
        'Evaluate social and economic contributions to local communities​​',
        'Advise on community-centric sustainability initiatives'
      ]
    },
    { 
      id: 8, 
      img: 'images/img_settings_white_a700_38x44.svg',
      name: 'Health & Well-Being', 
      content: [
        'Review health and safety policies and practices​',
        'Assess employee well-being programs and mental health support​​',
        'Recommend measures to enhance health and well-being'
      ]
    },
    { 
      id: 9, 
      img: 'images/img_television_white_a700_54x27.svg',
      name: 'Investment for Sustainability', 
      content: [
        'Examine organisational culture and values​',
        'Assess return on investment for sustainability initiatives​​​​',
        'Offer guidance on sustainable investment strategies​'
      ]
    },
    { 
      id: 10, 
      img: 'images/img_car_green_600.svg',
      name: 'Education & Stakeholders', 
      content: [
        'Evaluate sustainability education and awareness programs​​',
        'Analyse stakeholder engagement & communication',
        'Suggest methods to enhance education and stakeholder relations'
      ]
    },
    { 
      id: 11, 
      img: 'images/img_thumbs_up_white_a700_44x52.svg',
      name: 'Biodiversity', 
      content: [
        'Assess impacts on local ecosystems and biodiversity​​',
        'Recommend conservation and biodiversity protection measures​​​​',
        'Ensure compliance with wildlife &  habitat protection regulations'
      ]
    },
    { 
      id: 12, 
      img: 'images/img_thumbs_up_white_a700_48x47.svg',
      name: 'Governance', 
      content: [
        'Examine governance structures and ethical decision-making​​',
        'Analyse transparency, accountability, and compliance with regulations',
        'Provide guidance for strengthening governance for sustainability'
      ]
    },
    // Add more items as needed
  ];


  
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refabout, inViewAbout] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThing, inViewEveryThing] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refEveryThingRight, inViewEveryThingRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadBottom, inViewDownloadBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refDownloadLeft, inViewDownloadLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadRight, inViewDownloadRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThing = useSpring({
    opacity: inViewEveryThing ? 1 : 0,
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationEveryThingLeft = useSpring({
    opacity: inViewEveryThingLeft ? 1 : 0,
    transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationEveryThingRight = useSpring({
    opacity: inViewEveryThingRight ? 1 : 0,
    transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThingBottom = useSpring({
    opacity: inViewEveryThingBottom ? 1 : 0,
    transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });


  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const bottomAboutAnimation = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Display two items at a time
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 2, // Show one item at a time on smaller screens
        },
      },
    ],
    
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Check if any required fields are empty
    if (!email  ) {
      setError('Please fill in all required fields.');
      return;
    }
  

    // Check password complexity
    
  
  
    // Set loading to true when submitting the form
    setLoading(true);
    setError('');
  
    const { success, error } = await AuthService.sendnewletter(email);
  
    // Reset loading state after login attempt
    setLoading(false);
  
    if (success) {
      setError('');
      setEmail('');
     
    } else {
      if (error !== undefined) {
        setError(error);
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>SALI | Home</title>
        <meta name="description" content="Begin your sustainability journey" />
      </Helmet>
       <Navbar/>
       <HeroSection/>
       <AssessmentSection/>
       <ScopeSection/>
       <SolutionSection/>
       <ChangeSection/>
       <SdgsSection/>
       <ModuesSection/>
       <JouneySection/>
       <FooterSection/>
     
<ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </>
  );
}
