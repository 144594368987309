// SidebarMenu.tsx
import React from 'react';
import { Text,Img } from 'components';


interface UserData {
  firstname:string;
  lastname:string;
  email: string;
  companyName: string;
  position: string;
  sector: string;
  industry: string;
  companySize: string;
  password: string;
  // Add other user data fields as needed
}

interface SidebarMenuProps {
  isMenuOpen: boolean;
  handleCloseMenu: () => void;
  userData?: UserData;
}


const SidebarMenu: React.FC<SidebarMenuProps> = ({ isMenuOpen, handleCloseMenu, userData }) => {
  return (
    <>
     <div>
     <button 
              onClick={handleCloseMenu}
              className="text-white absolute top-2 right-0 mr-3 focus:outline-none"
            >
                <div className="hidden md:block m-auto items-center  justify-center  top-0 right-0  bg-gray-400_7f h-full w-full  fixed z-40" style={{ opacity: 0.9 }}>
        
                  
        </div>
            </button>
      
          <div className="hidden md:block m-auto items-center  justify-center  top-0 right-0  bg-white-A700 h-full md:w-[80] w-[70%]  p-4 fixed z-50">
            <button 
              onClick={handleCloseMenu}
              className="text-white absolute top-2 right-0 mr-3 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
        <div className="flex justify-center items-start pt-10  w-full overflow-scroll">
            <div  className="flex flex-col ">
            <Text size="12xl" as="p">
                Your Profile.
              </Text>
              <div className="flex flex-col items-start w-full mt-1.5">
              <div className="flex flex-col items-center  ">
                <div className="w-12 h-12 relative ">
                  <div className="flex justify-center items-center h-12 w-12  left-0 bottom-0 right-0 top-0  m-auto border-gray-300_03 border-2 border-solid absolute rounded-full">
                    <Text size="12xl" as="p" className="!text-gray-400_05 !font-hankengrotesk !font-normal">
                    {userData?.firstname ? userData.firstname.charAt(0) : ''}
                    </Text>
                  </div>
                  <button
                    className="bg-white-A700 rounded-full w-4 h-4 bottom-0 right-0 flex justify-center items-center  m-auto border-gray-400_10 border border-solid absolute "
                  >
                    <Img src="/images/img_plus.svg" />
                  </button>
                </div>
                <Text size="2xl" as="p" className="!font-hankengrotesk mt-2">
                  Add Photo
                </Text>
              </div>
              <div className="flex flex-col items-start w-[81%]  mt-5">
         
                <div className="flex justify-between w-[76%] md:w-full mt-[-3px] gap-5  flex-wrap">
                  <div className="flex flex-col">
                  <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    First name
                  </Text>
                  <Text size="6xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.firstname}
                  </Text>
                  </div>
                 <div className="flex flex-col">
                 <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    Last name
                  </Text>
                 <Text size="6xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.lastname}
                  </Text>
                 </div>
                 
                </div>
                <div className="flex flex-col items-start mt-[18px] gap-0.5">
                  <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    Email Address
                  </Text>
                  <Text size="6xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.email}
                  </Text>
                </div>
                <div className="flex flex-col items-start mt-[17px]">
                  <Text as="p" className="!text-gray-400_09 !font-hankengrotesk">
                    Company
                  </Text>
                  <Text size="6xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                  {userData?.companyName}
                  </Text>
                </div>
                <Text as="p" className="mt-[17px] !text-gray-400_09 !font-hankengrotesk">
                  Company Role
                </Text>
                <Text size="6xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                {userData?.position}
                </Text>
                <Text as="p" className="mt-[17px] !text-gray-400_09 !font-hankengrotesk">
                  Industry
                </Text>
                <Text size="6xl" as="p" className="!text-gray-900_04 !font-hankengrotesk">
                {userData?.industry}
                </Text>
                <Text as="p" className="mt-[17px] !text-gray-400_09 !font-hankengrotesk">
                  Company Size
                </Text>
                <button
                  className="bg-green-50_f9 mt-2.5 sm:px-5 font-semibold px-4 py-1 rounded-xl text-base"
                >
                  {userData?.companySize}
                </button>
              </div>
              <button
                  className="bg-green-A700_01 my-5 w-full h-10 font-hankengrotesk flex flex-row justify-center items-center gap-2  text-base font-semibold rounded-xl text-white-A700"
                >
                 <Img src="/images/img_edit.svg" alt="edit" />  Update Profile
                </button>
           
            </div>
            </div>
            
          
          </div>
                  
          </div>
          </div>
    </>
  );
};

export default SidebarMenu;
