import React from "react";

const ESGEngagement = () => {
    const categories = [
      { label: "Environment", items: ["Disclosure of environmental data", "Greenhouse gas emissions", "Product sustainability", "Environmental management", "Water and waste management"], color: "bg-[#F7B329]" , img: "/images/img-eg-1.png"},
      { label: "Social", items: ["Human rights and labor practices", "Diversity and inclusion", "Employee well-being and benefits", "Health and safety standards",], color: "bg-[#BB4415]",img: "/images/img-eg-2.png" },
      { label: "Ethics", items: ["Code of conduct and business ethics", "Anti-bribery and corruption measures", "Whistleblower policies", "Fair trade and ethical sourcing", "Transparent reporting of ethical issues"], color: "bg-[#00B8D4]",img: "/images/img-eg-3.png" },
      { label: "Governance", items: ["Disclosure of environmental data", "Greenhouse gas emissions", "Product sustainability", "Environmental management", "Water and waste management"], color: "bg-[#00BFA5]",img: "/images/img-eg-4.png" },
    ];
  
    return (
      <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
        {categories.map((category, index) => (
          <div key={index} className={``}>
            <h3 className={`font-bold mb-2 ${category.color} text-white-A700 p-2 `}>{category.label}</h3>
            <div className=" flex flex-row gap-4">
     
               <img
                  loading="lazy"
                  src={category.img}
                  className="object-cover inset-0 w-[70px] h-[85px]"
                />

             <ul className="list-disc pl-5 text-sm">
              {category.items.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
            </div>
          
          </div>
        ))}
      </div>
    );
  };
  
  export default ESGEngagement;
  