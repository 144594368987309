import React, { useState } from 'react'
import { Helmet } from "react-helmet";

import { Button, Text, Input, SelectBox, Img } from "../../../components";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import Snackbar from '@mui/material/Snackbar/Snackbar';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import { Link, NavLink, useNavigate } from "react-router-dom";
import AuthService from '../../../services/authService';
import { Alert, Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { ToastContainer } from 'react-toastify';





export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate()

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

const handleSubmit = async (e) => {
    e.preventDefault();

    // Set loading to true when submitting the form
    setLoading(true);
    setError('');
    const { success, error } = await AuthService.login(email, password);

    // Reset loading state after login attempt
    setLoading(false);

    if (success) {
      setError('');
      navigate("/dashboard/welcome");
      
    
    }else{
      setError(error);
    }
  };

  return (
    <>
       <Helmet>
        <title>SALI | Login</title>
        <meta name="description" content="Begin your sustainability
journey" />
      </Helmet>
      <div className="h-screen w-full bg-white-A700">
      <nav className="flex self-stretch justify-between items-center gap-5 p-5 px-10 md:p-4 fixed w-full z-10  bg-gradient5" >
      <div className="container mx-auto flex justify-between items-center">
      <NavLink to="/">
       <Img
        src="images/img_settings_cyan_900.svg"
        alt="settings_one"
        className="h-8"
       />
     </NavLink>
        <div className="  flex flex-row space-x-4 items-center">
        <div className="flex justify-between items-center  gap-5">
        <div className="flex self-end items-start gap-2">
          <Img src="images/img_globe_gray_500_06.svg" alt="globe_one" className="h-[22px] w-[22px]" />
          <Text size="10xl" as="p" className="!text-gray-500_04 !font-hankengrotesk">
            English
          </Text>
        </div>
        <div className=" md:hidden h-[30px] w-px bg-gray-900_89" />
     
        <Text size="10xl" as="p" className="!text-gray-900_03 !font-hankengrotesk md:hidden">
           Login
          </Text>
      </div>
        </div>
      </div>
    </nav>
        <div className=" flex h-full flex-row items-start bg-gradient5">
         
          <div className="md:hidden h-full flex flex-col w-[40%] justify-end items-start">
            <Text size="13xl" as="p" className="  ml-10 mb-10">
              <>
                Begin your <br />
                Sustainability Journey
              </>
            </Text>
            <Img
                src="images/img_auth_pic.png"
                alt="istock_one"
                className="h-[70%]    object-contain"
              />
           
          </div>
          <div className="flex flex-col overflow-x-scroll h-full  justify-between items-center w-[60%] md:w-full  gap-5 ">
         
            <div className="flex flex-col items-start md:w-[85%] w-[60%]">
            <form onSubmit={handleSubmit} className='w-full'>
      <Img src="images/img_user_teal_50_03.svg" alt="user_one" className="h-12 my-5 mt-20 items-start" />
                <Text size="15xl" as="p">
                  Login
                </Text>
              <div className="flex flex-col self-stretch items-start mt-[18px] w-full gap-1.5">
                <Text size="6xl" as="p" className="ml-1 md:ml-0 !text-gray-800_03 !font-hankengrotesk">
                  Email
                </Text>
                <TextField
                label="Email"
                type="email"
                value={email}
                className='w-full self-stretch flex'
                onChange={handleEmailChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
        
                
              </div>
              <div className="flex flex-col self-stretch items-start mt-[31px] gap-1.5">

                <Text size="6xl" as="p" className="ml-1 md:ml-0 !text-gray-800_03 !font-hankengrotesk">
                  Password
                </Text>
                <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'} // Show/hide password based on state
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              // Show/hide password icon button
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> 
                </div>

              
              <Link to="/forgotpassword" style={{ color: "#87CEFA", textDecoration: "none" }}>
              <Text size="7xl" as="p" className="mt-[31px] text-left mb-5">
                <span className="text-black-900_01">Don’t remember your&nbsp;</span>
                <span className="text-teal-800_01 font-semibold">password?</span>
              </Text>
          </Link>
          {error &&  <Alert severity="error">{error}</Alert>}
       
         {loading ? (
                <button  className= "mb-5 transition w-full ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
                <CircularProgress size={24} />
         </button>
        ) : (
          <button type="submit" className= "mb-5 transition w-full ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300  sm:px-5 font-hankengrotesk bg-[#014E64]  text-white-A700 h-12 font-semibold  rounded-sm" >
          Login
                </button>
        )}
    </form>
  
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </>
  );
}
