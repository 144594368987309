import { Helmet } from "react-helmet";
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, Text, Input, SelectBox, Img } from "../../../components";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import AuthService from '../../../services/authService';
import { Alert, Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 

export default function AssignConfirmEmailPage() {
  const [firstname, setFirstNmae] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [companysize, setCompanySize] = useState('Number of employees');
  const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate()
  const [sector, setSector] = useState('');
  const [subSector, setSubSector] = useState('');
  const [category, setCategory] = useState('');
  const [role, setRole] = useState('');



  // Function to handle changes in dropdowns
  const handleSectorChange = (event) => {
    setSector(event.target.value);
  };

  const handleSubSectorChange = (event) => {
    setSubSector(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handlefirsNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNmae(e.target.value);
  };
  const handlelastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const handledepartmentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDepartment(e.target.value);
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  const isPasswordValid = (password: string) => {
    // Define regular expressions for each required character type
    const hasCapital = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
  
    // Check if all required character types are present
    return hasCapital && hasLowercase && hasSpecial && password.length >= 6;
    };
  
  const userData = {
  firstname:firstname,
  lastname:lastname,
  email:email,
  companyName: department,
  position: role,
  sector: sector,
  industry: subSector,
  companySize:'medium',
  password: password,
    };
   
    

  const handleSubmit = async () => {
    navigate(`/assign-user/login`);
  };

  return (
    <>
      <Helmet>
        <title>SALI | Signup</title>
        <meta name="description" content="Begin your sustainability
journey" />
      </Helmet>
      <div className="h-screen w-full ">
      {/* <nav className="flex self-stretch justify-between items-center gap-5 p-5  px-10 md:p-4 fixed w-full z-10  bg-gradient5" >
      <div className="container mx-auto flex justify-between items-center">
      <NavLink to="/">
       <Img
        src="/images/img_settings_cyan_900.svg"
        alt="settings_one"
        className="h-8"
       />
        </NavLink>
     
        <div className="  flex flex-row space-x-4 items-center">
        <div className="flex justify-between items-center  gap-5">
        <div className="flex self-end items-start gap-2">
          <Img src="/images/img_globe_gray_500_06.svg" alt="globe_one" className="h-[22px] w-[22px]" />
          <Text size="10xl" as="p" className="!text-gray-500_04 !font-hankengrotesk">
            English
          </Text>
        </div>
        <div className=" md:hidden h-[30px] w-px bg-gray-900_89" />
        <NavLink to="/login">
        <Text size="10xl" as="p" className="!text-gray-900_03 !font-hankengrotesk md:hidden">
            Login
          </Text>
        </NavLink>
        
      </div>
        </div>
      </div>
    </nav> */}

        <div className=" flex h-screen flex-row items-start bg-gradient5 p-4">
         
        <div className="md:hidden h-full flex flex-col w-[35%]  justify-end items-start bg-[#00495E] rounded-md py-6 px-10">
            <Text size="13xl" as="p" className="mb-2 text-white-A700">
              <>
             
                SALI is your partner 
                for a successful <br />
                <span className="text-green-A700_01"> sustainability journey. </span>
               
              </>
            </Text>
            <p className="text-xs mb-10 text-[#D4D4D4] font-thin">
              <>
             
              Go beyond static competitor tools. SALI leverages advanced AI and Machine Learning for comprehensive analyses,benchmarking and reporting.
               
              </>
            </p>
            <Img
                src="/images/assign_image.svg"
                alt="istock_one"
                className="h-full    object-contain object-center"
              />
           
          </div>
       

          <div className="flex flex-col overflow-x-scroll h-full  justify-between items-center w-[60%] md:w-full  gap-5 ">
          <form onSubmit={handleSubmit} className='w-full flex flex-col justify-center items-center'>
              <div className="flex flex-col items-start md:w-[85%] w-[60%] 10">
      <div className="flex flex-col  w-full my-5">
      <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/550280be023705ac2b07b3bc01dabc882d7c6b6c14b55fbf562027391aeb6ba6?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
          className="w-20  fill-green-500 h-20 "
        />
      <div className="mt-3 w-full text-4xl font-semibold text-sky-900  md:text-3xl">
        Welcome to SALI
      </div>
      <div className="mt-2 w-full text-base font-semibold text-black  ">
        (Sustainability Assessment and Learning Intelligence)
      </div>
      <div className="mt-1 w-full text-sm  text-neutral-950 ">
        Some questions have been assigned to you.
        <br />
        Please <span className="font-semibold text-neutral-950">
          sign up
        </span>{" "}
        to answer these questions.
      </div>
    </div>
              
        
    <div className="flex gap-5 self-start mt-10 items-center font-medium leading-[79.11px]">
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ab19ff62893c2c7613f4d9b65f62ec2d148510fd780d35b9654f7ac8bcd91944?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
          className="shrink-0 w-10 h-10 bg-green-100 rounded-full aspect-square"
        />
        <div className="flex-auto my-auto text-sm">Check your email</div>
      </div>

      <div className="mt-10 w-full text-sm leading-9 max-md:max-w-full">
        We sent an email containing your login credentials to{" "}
        <span className="text-emerald-500">joe@gmail.com</span>
      </div>
         
        <button onClick={handleSubmit}  className= "mb-5 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300  mt-11 sm:px-5 font-hankengrotesk bg-green-A700_01  text-white-A700 h-12 font-semibold min-w-[259px] rounded-[23px]" >
         Open email
               </button>
              </div>
              </form>
          </div>
         

        </div>
      </div>
    </>
  );
}
