

import React, { useState } from 'react';
import axios from 'axios';
import { Img } from 'components';
import { Link } from 'react-router-dom';

const AskSaliPage: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const [chat, setChat] = useState<Array<{ type: string, text: string }>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const sendMessage = async () => {
    if (message.trim() === '') return;

    const newChat = [...chat, { type: 'user', text: message }];
    setChat(newChat);
    setMessage('');
    setLoading(true);

    try {
      const response = await axios.post('https://saudit-jheg.onrender.com/document/asksali', { ask: message });
      console.log(response); // Log the response to see its structure

      // Extract the response text directly from the data property
      const responseText = response.data || 'No response from the server.';
      setChat(prevChat => [...newChat, { type: 'bot', text: responseText }]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setChat(prevChat => [...newChat, { type: 'bot', text: 'Error: Could not get a response.' }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col item-center h-screen w-full p-4 bg-neutral-700 overflow-hidden">
       <nav className="flex flex-row  justify-between items-center fixed w-full z-10  p-6 md:p-4  bg-neutral-700 " >
    <Img
                  src="images/img_settings_cyan_900.svg"
                  alt="settings_one"
                  className="h-8"
                />

     

      <Link to="/" style={{ color: "#87CEFA", textDecoration: "none" }} >
      <svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="21" fill="#4B4B4B"/>
      <path d="M30.1875 13.125L13.125 30.1875M13.125 13.125L30.1875 30.1875" stroke="#727272" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>  
    </Link>
    </nav>
    
      <div className="w-full h-full flex justify-center items-center p-4 bg-white rounded-lg shadow-md mt-20">
        <div className="flex flex-col h-full  items-center w-[80%] md:w-[95%]">
        <div className="h-[80%] overflow-y-auto w-full">
          
          {chat.map((entry, index) => (
            <div key={index} className={`mb-2 p-2 rounded-lg whitespace-pre-line ${entry.type === 'user' ? ' text-green-400_02' : 'text-white-A700'}`}>
              {entry.text}
            </div>
          ))}
          {loading && (
            <div className="mb-2 p-2 rounded-lg bg-green-400_02 bg-opacity-50 animate-pulse text-white-A700 w-full">
              Sending...
            </div>
          )}
          
          {chat.length === 0 && (
            <div className=" rounded-lg  text-black w-full h-full items-center justify-between">
       <p
        className="text-base text-white-A700  leading-6 text-white w-full">
        Hi! Ask me anything about sustainability. I can help you with starting your sustainability assessment journey
      </p>
            </div>
          )}
        </div>
       
   
        <div className="flex gap-5 py-1.5 pr-1.5 pl-12  w-full mt-4
    text-xl leading-4 border-lime-600 border-solid bg-neutral-700 
    border-[3px] rounded-[37px] text-neutral-400 w max-md:flex-wrap ">
          <input
            type="text"
            className="flex-auto my-auto bg-transparent border-none text-neutral-400"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' ? sendMessage() : null}
            disabled={loading}
          />
          <button
            className="bg-transparent border-none"
            onClick={sendMessage}
            disabled={loading}
          >
              <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/879bb7863bbd41e0d63b916ef134f18c25117d0cb58727585218ee5f2866b7b8?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
        alt='asl aali'
        className="shrink-0 w-11 aspect-square"
      />
          </button>
        </div>
        </div>
      </div>
        

    </div>
  );
          };
export default AskSaliPage;
