import React, { ReactNode, useEffect, useState } from 'react';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  mainonClose: () => void;
  children: ReactNode;
}

const Dialog: React.FC<DialogProps> = ({ isOpen,mainonClose, onClose, children }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen) return;
      const target = event.target as HTMLElement;
      if (!target.closest('.dialog-inner')) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Conditionally render the dialog based on isOpen
  if (!isOpen) return null;
  const handleclose = () => {
    // onClose();
    mainonClose();
  };
  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none  bg-gray-400_7f bg-opacity-90 "  >
        <div className="z-50 bg-white-A700 p-5 flex flex-col justify-between dialog-inner h-[25%] w-[80%] rounded-lg shadow-lg max-w-md ">
          <div className="flex flex-row w-full  justify-between items-center ">
            <h3 className="font-bold text-black ">Are you sure you want to close?</h3>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-black dark:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <h3 className="font-medium text-black ">All the information you’ve entered will be lost.</h3>
          <div className="flex flex-row w-full justify-between items-center">
            <button className="h-10 w-36 text-white-A700 bg-green-A700_01 rounded-lg border border-strokedark shadow-default dark:border-stroke" onClick={handleclose}>Close</button>
            <button className="h-10 w-36 text-black dark:text-white rounded-lg border border-strokedark shadow-default dark:border-stroke" onClick={onClose}>No</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
