import * as React from "react";

interface StepItemProps {
  stepNumber: number;
  title: string;
  isActive?: boolean;
}

const StepItem: React.FC<StepItemProps> = ({ stepNumber, title, isActive = false }) => {
  return (
    <div className="flex flex-col grow md:mt-10 md:w-full w-40 ">
    <div className="justify-center text-white-A700 flex  text-sm text-center  items-center self-center  w-7 h-7  text-white whitespace-nowrap bg-[#00BF63] rounded-full">
      {stepNumber}
    </div>
    <div
      className={`flex justify-center items-center h-20 w-full mt-5 text-sm md:text-xs font-medium rounded-md border border-solid ${
        isActive
          ? "text-white-A700 bg-[#5BBA47] border-[#5B9C80] font-semibold"
          : "text-black bg-[#DEEFDD]  border-[#5B9C80]"
      } max-md:px-5`}
    >
      {title}
    </div>
  </div>
  );
};

const MyComponent: React.FC = () => {
  const steps = [
    { stepNumber: 1, title: "Launch Dashboard" },
    { stepNumber: 2, title: "Select Module" },
    { stepNumber: 3, title: "Upload Evidence Doc" },
    { stepNumber: 4, title: "Start Assessment", isActive: true },
  ];

  return (
    <section className="px-5 my-10 ">
      <div className="gap-2  justify-center grid grid-cols-4 md:grid-cols-2  ">
        {steps.map((step, index) => (
          <StepItem
            key={index}
            stepNumber={step.stepNumber}
            title={step.title}
            isActive={step.isActive}
          />
        ))}
      </div>
    </section>
  );
};

export default MyComponent;