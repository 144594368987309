import React, { useEffect, useState } from 'react';
import AuthService from 'services/authService';

interface Activity {
  date: string;
  time: string;
  user: string;
  action: string;
  type: 'upload' | 'rename';
}

const activities: Activity[] = [
  { date: 'Wed', time: '11:24 AM', user: 'Hanotu W', action: 'uploaded an item', type: 'upload' },
  { date: 'Friday', time: '11:24 AM', user: 'Teria Y', action: 'renamed an item', type: 'rename' },
  { date: 'Wed', time: '11:24 AM', user: 'Eberechi', action: 'uploaded ISO 9001 doc', type: 'upload' },
  { date: 'Friday', time: '11:24 AM', user: 'Richard E', action: 'renamed an item', type: 'rename' },
  { date: 'Wed', time: '11:24 AM', user: 'Richard', action: 'uploaded ISO 14001 doc', type: 'upload' },
];

const ActivityList: React.FC = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthService.fetchCerts();
        setUserData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="flex flex-col container mx-auto items-start">
      <h2 className="text-lg font-semibold mb-4">Activity</h2>
      <div className="space-y-4">
      <div >
    
    {activities && activities.length > 0 ? (
            <div className='gap-4'>
              {activities.slice(0, 5).map((activity, index) => (
         <div key={index} className="flex items-center my-4">
           <div className={`p-2 rounded-full`}>
           <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.96 8.39844C18.56 8.70844 20.03 10.5584 20.03 14.6084V14.7384C20.03 19.2084 18.24 20.9984 13.77 20.9984H7.26C2.79 20.9984 1 19.2084 1 14.7384V14.6084C1 10.5884 2.45 8.73844 5.99 8.40844" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5195 1.5V14.38" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.8699 12.1484L10.5199 15.4984L7.16992 12.1484" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
           </div>
           <div>
             <div className="text-xs text-gray-500 ">{`${activity.date}`}</div>
             <div className="text-sm">
               <span className="font-medium">{activity.user}</span>{activity.action}
             </div>
           </div>
         </div>
       ))}
            </div>
         ) : (
           <p>No certifications available.</p>
         )}


   </div>
      
      </div>
    </div>
  );
};

export default ActivityList;
