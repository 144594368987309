
import { Img,Text } from "components";
import React from "react";
import { NavLink } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <nav className="flex self-stretch justify-between items-center gap-5 p-5 px-10 md:p-4 fixed w-full z-10  bg-gradient5" >
      <div className="container mx-auto flex justify-between items-center">
      <NavLink to="/">
       <Img
        src="images/img_settings_cyan_900.svg"
        alt="settings_one"
        className="h-8"
       />
     </NavLink>
        <div className="  flex flex-row space-x-4 items-center">
        <div className="flex justify-between items-center  gap-5">
        <div className="flex self-end items-start gap-2">
          <Img src="images/img_globe_gray_500_06.svg" alt="globe_one" className="h-[22px] w-[22px]" />
          <Text size="10xl" as="p" className="!text-gray-500_04 !font-hankengrotesk">
            English
          </Text>
        </div>
        <div className=" md:hidden h-[30px] w-px bg-gray-900_89" />
     
        <NavLink to="/signuppage">
        <Text size="10xl" as="p" className="!text-gray-900_03 !font-hankengrotesk md:hidden">
           Signup
          </Text>
        </NavLink>
      </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
