// AddUserSidebarMenu.tsx
import React, { useEffect, useRef, useState } from "react";
import { Text,Img, Button, Input, Radio, RadioGroup } from 'components';
import { Box, TextField } from "@mui/material";
import SendInviteSidebarMenu from "./senduserinvite";
import Dialog from "./closedialog";


interface UserData {
  firstname: string;
  lastname: string;
  email: string;
  // Add other user data fields as needed
}

interface AddUserSidebarMenuProps {
  isMenuOpen: boolean;
  handleCloseMenu: () => void;
  userData?: UserData;
}
const DialogBox = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="dialog-overlay">
        <div className="dialog-content">
          <button className="close-button" onClick={onClose}>
            Close
          </button>
          <h2>Dialog Title</h2>
          <p>This is the content of the dialog box.</p>
        </div>
      </div>
    );
  };
const AddCertificateSidebarMenu: React.FC<AddUserSidebarMenuProps> = ({ isMenuOpen, handleCloseMenu, userData }) => {
    const [isAddUserOpen, setAddUserOpen] = useState(false);
    const handleAddUserToggle = () => {
      setAddUserOpen(!isAddUserOpen);
      disableBodyScroll();
    };
    // Access the firstname and lastname properties directly from the userData object
    const handleCloseAddUser = () => {
      setAddUserOpen(false);
      enableBodyScroll();
    };
  
  
    // Function to disable body scrolling
    const disableBodyScroll = () => {
      document.body.style.overflow = 'hidden';
    };
  
    // Function to enable body scrolling
    const enableBodyScroll = () => {
      document.body.style.overflow = '';
    };
  
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
      setIsOpen(true);
    };
  
    const handleClose = () => {
      setIsOpen(false);
    };
    const handleCloseAll = () => {
        setIsOpen(false);
        handleCloseMenu(); // Close the parent menu
      };
  return (
    <>
     <div>
      <button 
              onClick={handleCloseMenu}
              className="text-white absolute top-2 right-0 mr-3 focus:outline-none"
            >
                <div className="m-auto items-center  justify-center  top-0 right-0  h-full w-full  fixed z-40 bg-gray-400_7f " style={{ opacity: 0.9 }}>
        
                  
        </div>
            </button>
      
          <div className=" m-auto items-center  justify-center  top-0 right-0  bg-white-A700 h-full md:w-[80%] w-[65%]  py-5  md:px-5 px-10 fixed z-50 overflow-scroll">
            <div className="flex self-stretch justify-center p-[11px] bg-white-A700">
              <div className="flex flex-col items-start w-full mt-8 gap-2.5">
                <div className="flex flex-col self-stretch items-start">
                <div className="flex self-stretch justify-between items-center  gap-5 md:ml-0">
                    <Button
                      onClick={handleCloseMenu}
                          >
                   <Img src="/images/img_vector_166.svg" alt="vector166_one" className="self-end md:h-3 h-5 mb-3.5" />

                    </Button>
                    <Button
                      onClick={handleCloseMenu}
                      color="black_900_01"
                  
                      className="bg-black-900_01  md:text-sm text-lg text-white-A700  font-hankengrotesk w-44 rounded-[10px]"
                    >
                      Save & Continue
                    </Button>
                 </div>
                <Text size="14xl" as="p" className="my-5">
                    Select Certifications
                </Text>
                </div>
                <div
                className="md:text-sm text-lg mt-1 mb-5 flex flex-row gap-2 bg-gray-200_03 w-auto p-3 font-medium rounded-[21px]">
                  <Img src="/images/img_videocamera_yellow_900.svg" alt="video_camera" />
                  Select your certifications to assess and improve your overall score
                </div>
              </div>
            </div>
            <div className="h-px w-[90%] mt-4 bg-blue_gray-100_04" />
            <RadioGroup name="sustainability" className="flex flex-col self-start mt-10 ">
              <Radio
                value="iso9001qualitymanagementsystem"
                label="ISO 9001: Quality Management System"
                className="mt-2 gap-[19px] p-px  text-black-900_01 font-hankengrotesk md:text-sm text-lg flex-1"
              />
            <div className="h-px w-full  my-10 md:my-5 md:my-5 bg-blue_gray-100_04" />

              <Radio
                value="iso14001environmentalmanagementsystem"
                label="ISO 14001: Environmental Management System"
                className=" gap-[19px] p-px  text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
              />
             <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />
              <Radio
                value="iso45001occupationalhealthandsafetymanagementsystem"
                label="ISO 45001: Occupational Health and Safety Management System"
                className="gap-[19px] p-px text-black-900_01 font-hankengrotesk md:text-sm text-lg flex-1"
              />
              <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

              <Radio
                value="isots22163qualitymanagementsystemforrailways"
                label="ISO/TS 22163: Quality Management System for Railways"
                className=" gap-[19px] p-px  text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
              />
               <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

               <Radio
                  value="iso50001energymanagementsystem"
                  name="iso50001"
                  label="ISO 50001: Energy Management System"
                  className="gap-[19px] p-px md:ml-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="iso26000socialresponsibility"
                  label="ISO 26000: Social Responsibility"
                  className=" gap-[19px] py-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="iso19650informationmanagementfortheconstructionindustry"
                  label="ISO 19650: Information Management for the Construction Industry"
                  className=" gap-[19px] py-px text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="risqsrailwayindustrysupplierqualificationscheme"
                  label="RISQS (Railway Industry Supplier Qualification Scheme)"
                  className=" gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="risasrailindustrystandardassurancescheme"
                  label="RISAS (Rail Industry Standard Assurance Scheme)"
                  className=" gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                 <Radio
                  value="smassafetymanagementadvisoryservices"
                  label="SMAS (Safety Management Advisory Services)"
                  className=" gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg border-blue_gray-100_04 border-b-[0.5px] flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="achillesuvdbutilitiesvendordatabase"
                  label="Achilles UVDB (Utilities Vendor Database)"
                  className="gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="railsafetyaccreditationschemersas"
                  label="Rail Safety Accreditation Scheme (RSAS)"
                  className=" gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="networkrailprincipalcontractorslicensepcl"
                  label="Network Rail Principal Contractors License (PCL)"
                  className=" mr-[68px] gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="railplantassociationrpamembership"
                  label="Rail Plant Association (RPA) Membership"
                  className=" gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="institutionofrailwaysignalengineersirsemembership"
                  label="Institution of Railway Signal Engineers (IRSE) Membership"
                  className=" gap-[19px] p-px text-black-900_01 font-hankengrotesk md:text-sm text-lg flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                <Radio
                  value="constructionlinegoldmembership"
                  label="Construction line Gold Membership"
                  className=" mr-[170px] gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                  <Radio
                  value="achilleslinkupcertification"
                  label="Achilles Link-Up Certification"
                  className=" mr-[178px] gap-[19px] p-px md:mr-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg  flex-1"
                />
                 <div className="h-px w-full  my-10 md:my-5 bg-blue_gray-100_04" />

                 <Radio
                  value="cirasconfidentialincidentreportinganalysissystem"
                  name="ciras"
                  label="CIRAS (Confidential Incident Reporting & Analysis System)"
                  className=" gap-[19px] p-px md:ml-0 text-black-900_01 font-hankengrotesk md:text-sm text-lg"
                />
              
              
            </RadioGroup>
          
          </div>
          </div>
          
    <Dialog isOpen={isOpen} onClose={handleClose} mainonClose={handleCloseAll}>
        <h2 className="text-lg font-bold mb-2">Dialog Title</h2>
        <p>This is the content of the dialog.</p>
      </Dialog>
    </>
  );
};

export default AddCertificateSidebarMenu;
