import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';


interface ChartThreeState {
  series: number[];
}

const options: ApexOptions = {
  chart: {
    type: 'donut',
  },
  colors: ['#1F8B27', '#2578DA',  ],
  labels: ['Certification', 'Sustainable Standard',  ],
  legend: {
    show: false,
    position: 'left',
  },

  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        background: 'transparent',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 300,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 300,
        },
      },
    },
  ],
};

interface ReportProps {
  score: string;
}
const Ranking:React.FC<ReportProps> = ({ score }) => {
  const userScore = score;

    const [state, setState] = useState<ChartThreeState>({
      series: [65, 50],
    });
  return (
    
      <div className="flex flex-col py-10  w-full ">
        
        
        <div className='w-full flex flex-row md:flex-col mb-10'>
        <div className="flex flex-col px-5 text-black w-[50%] md:w-full">
          <div className="flex gap-5 text-xl font-semibold leading-5 text-black max-md:flex-wrap max-md:pr-5 md:w-full">
            <div className="flex-auto">Congratulations! </div>
           
        </div>
            <div className="text-sm mt-5 leading-5 w-full">
              You've been awarded the Sustainability Excellence Trophy in Silver
              for your
              outstanding commitment to sustainability in the Railway sector.
            </div>
            <div className="mt-10 text-xl font-semibold leading-10 w-full">
              Criteria for Award:
            </div>
            <div className="mt-2 text-sm leading-5 w-full">
              This award is based on your adherence to key sustainability
              standards and 
              certifications, includingSDG’s, ESG’s and the likes.
            </div>
            <div className="mt-10 text-xl font-semibold leading-10 max-md:mt-10 max-md:max-w-full">
              Evaluation Process:
            </div>
            <div className="mt-2 text-sm leading-5 w-full">
              Your sustainability performance was assessed through a
              comprehensive 
              evaluation process, including data collection, analysis, and
              review by our 
              sustainability assessment tool.
            </div>
          
          </div>
          <div className="flex flex-col items-center px-5 mt-4 text-black w-[50%] md:w-full">
          <div className="flex  flex-col">
        
         <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/bda0dab3368d884c35fb16df57b1c520c7cbdd0e18a09110c37e9c5f4c9b9e81?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                  className="object-cover inset-0 "
                />
        </div>
              
              <div className="mt-6 text-xl text-center w-full font-semibold leading-10 text-blue-950">
                Sustainability Excellence Trophy
              </div>
            <div className="mt-2n text-sm leading-7 text-center text-black w-full">
              This Silver trophy recognises your achievement benchmarked <br />
              against the highest level of sustainability excellence.
            </div>
          </div>
        </div>
        <div className='w-full flex flex-row md:flex-col'>
        <div className="flex flex-col px-5 mt-4 text-black w-[50%] md:w-full">
         
        <div className="flex  flex-col justify-between px-8 py-10 mt-10 h-[400px]  bg-[url(/public/images/map.svg)] bg-cover bg-no-repeat md:h-[h-300px] font-bold text-white-A700 rounded-2xl bg-[#113258] max-md:px-5 w-full">
              <div className="text-4xl leading-10 w-full md:text-3xl ">
                YourClimate <br />
                Net Zero
                <br />
                Ranking 2024
              </div>
              <div className="flex gap-5  text-xl  max-md:flex-wrap">
                <div className="flex-auto self-end mt-11 md:mt-10">
                  Verified and awarded by SALI
                </div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/5f7ba3bc94bec98798409f05338f1ee45ae044b8426e705291acbe7781c42a10?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                  className="shrink-0 aspect-[0.95] w-[61px]"
                />
              </div>
            </div>
      
          </div>
          <div className="flex  flex-col relative justify-center h-[400px] md:h-[h-300px] items-center px-5 mt-4 text-black w-[50%] md:w-full">
          <p className='font-Kumbh font-semibold absolute top-2  md:text-center text-left text-[20px]'>Your overall  performance score</p>
          <div className='flex flex-col w-full my-10' style={{    marginTop: ""}}> 
              <div className='relative flex  items-center'>
             
          <div className='absolute top-1/2 left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-center'>
          <ReactApexChart
            options={options}
            series={state.series}
            type="donut"
          />   
                </div>             
                <div className='absolute top-1/2 left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-center'>
                    <p className='font-semibold font-Kumbh text-[#000] text-[50px]'>{`${userScore}%`}</p>
                </div>
              </div>
          <div className='w-full items-start justify-start  absolute bottom-0 left-0 right-0'>
          <button
            className="font-medium flex mx-auto items-center border border-[#000] font-Kumbh text-base  px-10  h-[42px]  rounded-[29px] transform transition-transform outline-none"
          >
            <p className='text-center'>Explore Recommendations</p>
          </button>
          </div>
      
          </div>
         
         
          </div>
        </div>
       
      <div className="w-full flex flex-row items-center justify-center mt-10 animate__animated animate__fadeInUp">
       <Link
        to="/dashboard/module/"
        className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 bg-[#E5EEF0]  mt-2.5 sm:px-5 font-normal text-sm px-4 py-3 rounded-xl mb-10"
        >
        <button
         className="w-full" >
         Return to dashboard
         </button>
       </Link>
       </div>
      </div>
  );
  
      
}

export default Ranking

