import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel  } from "../../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import AuthService from "services/authService";



const Navbar: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);





 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <>

    <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5  px-10 md:px-4  py-6  " ref={navbarRef}>
      <div className="container mx-auto flex  justify-between items-center">
      <Img
                  src="images/img_settings_cyan_900.svg"
                  alt="settings_one"
                  className="h-8"
                />

        <div className="hidden md:block  ">
          {isMenuOpen ? (
            <button 
              onClick={handleCloseMenu}
              className="text-gray-800 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : (
            <button 
              onClick={handleMenuToggle}
              className="text-gray-800 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          )}
        </div>

        {isMenuOpen && (
          <div className="hidden md:block m-auto items-start  justify-start  absolute top-16 right-0 left-0 bg-gradient5 w-full h-36 p-4 z-50">
          
        
        
       <div className="flex flex-col  items-start">
          <Link to="/login" style={{ color: "#87CEFA", textDecoration: "none" }} className="flex items-left justify-left w-40 text-black-900_01">
            <Text size="10xl" as="p" className="!text-black-900_01 !font-hankengrotesk mb-5">
              Log In
             </Text>
          </Link>
                   
                
        <Link to="/signuppage" style={{ color: "#87CEFA", textDecoration: "none" }}>
        <div className="w-40  h-14 relative">
        <div className="w-full h-14 left-0 top-0 absolute bg-gradient-to-l from-[#076e5c] to-[#00984f] rounded-[27.50px]" />
        <div className="w-full h-4 left-[20px] top-[21px] absolute text-white-A700 text-[17px] font-semibold font-kumbhsans leading-3">Book a demo</div>
        </div>
      </Link>
        
      </div>
          </div>
        )}

<div className="md:hidden  flex flex-row space-x-4  items-center justify-center">
    
      <Link to="/login" >
      <div className="w-24  h-12 flex items-center justify-center ">
      <p className=" text-black-900_01 font-hankengrotesk text-base font-semibold ">
                  Log In
                </p>
        </div>
      </Link>      
            
      <Link to="/signuppage" >
      <div className="w-36  h-12 relative">
        <div className="w-full h-14 left-0 top-0 absolute bg-gradient-to-l from-[#076e5c] to-[#00984f] rounded-[27.50px]" />
        <div className="w-full h-4 left-[20px] top-[21px] absolute text-white-A700 text-base font-semibold font-kumbhsans leading-3">Book a demo</div>
        </div>
      </Link>
     
      </div>
      </div>
    </nav>


    </>
  );
};

export default Navbar;
