import React, { useState, ChangeEvent } from "react";
import { Text, Button, Radio } from 'components';
import { Box, TextField, RadioGroup, RadioGroupProps, FormControlLabel } from "@mui/material";
import axios from 'axios';
import SendInviteSidebarMenu from "./senduserinvite";
import Dialog from "./closedialog";
import { Bounce, ToastContainer, toast } from "react-toastify";

interface UserData {
  firstname: string;
  lastname: string;
  email: string;
  
}

interface UserDatas {
  firstname: string;
  lastname: string;
  email: string;
  jobTitle: string;
  userRole: string;
  accessModule: string;
  createPassword: boolean;
}
interface AddUserSidebarMenuProps {
  isMenuOpen: boolean;
  handleCloseMenu: () => void;
  userData?: UserData;
}

interface DialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
}

const DialogBox: React.FC<DialogBoxProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="dialog-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="dialog-content bg-white p-6 rounded-lg shadow-lg">
        <button className="close-button absolute top-2 right-2 text-lg font-bold" onClick={onClose}>
          &times;
        </button>
        <h2 className="text-2xl mb-4">Dialog Title</h2>
        <p>This is the content of the dialog box.</p>
      </div>
    </div>
  );
};

const AddUserSidebarMenu: React.FC<AddUserSidebarMenuProps> = ({ isMenuOpen, handleCloseMenu, userData }) => {
  const [isAddUserOpen, setAddUserOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<UserDatas>({
    firstname: '',
    lastname: '',
    email: '',
    jobTitle: '',
    userRole: '',
    accessModule: '',
    createPassword: true,
  });

  const handleAddUserToggle = () => {
    setAddUserOpen(!isAddUserOpen);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseAddUser = () => {
    setAddUserOpen(false);
    document.body.style.overflow = '';
  };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleCloseAll = () => {
    setIsOpen(false);
    handleCloseMenu();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://saudit-jheg.onrender.com/company/user/add', formData);
      if (response.status === 200||response.status === 201) {
        console.log("User added successfully");
        toast.success('User added successfully', {
          position:"top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
        handleAddUserToggle();
      } else {
        console.error("Error adding user");
      }
    } catch (error) {
      console.error("Error adding user", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isMenuOpen) return null;

  return (
    <>
     <div>
      <button 
              onClick={handleCloseMenu}
              className="text-white absolute top-2 right-0 mr-3 focus:outline-none"
            >
                <div className="m-auto items-center  justify-center  top-0 right-0  h-full w-full  fixed z-40 bg-gray-400_7f " style={{ opacity: 0.9 }}>
        
                  
        </div>
            </button>
      
          <div className=" m-auto items-center  justify-center  top-0 right-0  bg-white-A700 h-full md:w-[80%] w-[65%]  py-5  md:px-5 px-10 fixed z-50 overflow-scroll">
          <Text  className="text-2xl md:text-xl sm:text-base mr-6 mt-8  md:mr-0">
              Add a user
            </Text>
            <div className="flex flex-col items-start  mb-2 ">
            <div className="flex sm:flex-col self-stretch gap-[9px]">
                  <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                   
                    <TextField
                    label="First Name"
                    type="text"
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                  </div>
                 
                  <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                   
                    <TextField
                    label="Last Name"
                    type="text"
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                  </div>
                </div>
                <div className="flex flex-col self-stretch items-start mt-2 gap-1.5">

                  <TextField
                    label="Email"
                    type="text"
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />

                </div>
                <div className="flex flex-col self-stretch items-start mt-2 gap-1">
                
                  <TextField
                    label="Job title"
                    type="text"
                    fullWidth  // Make TextField full width
                    margin="normal"
                    variant="outlined"
                    name="jobTitle"
                    value={formData.jobTitle}
                     onChange={handleChange}
                  />
                </div>
                <RadioGroup
              name="userRole"
              value={formData.userRole}
              onChange={handleChange}
              className="flex flex-col mt-8"
            >
              <Text className="text-2xl md:text-xl sm:text-base mb-4">User Roles</Text>
              <FormControlLabel value="company" control={<Radio />} label="Company admin" />
              <FormControlLabel value="executive" control={<Radio />} label="Executive" />
              <FormControlLabel value="seniormanagement" control={<Radio />} label="Senior management" />
              <FormControlLabel value="staff" control={<Radio />} label="Staff" />
            </RadioGroup>
            <Text className="text-2xl md:text-xl sm:text-base mt-8 mb-4">Access to modules</Text>
            <RadioGroup
              name="accessModule"
              value={formData.accessModule}
              onChange={handleChange}
              className="flex flex-col"
            >
              <FormControlLabel value="culture" control={<Radio />} label="Culture" />
              <FormControlLabel value="biodiversity" control={<Radio />} label="Biodiversity" />
              <FormControlLabel value="airemissions" control={<Radio />} label="Air Emissions" />
              <FormControlLabel value="governance" control={<Radio />} label="Governance" />
            </RadioGroup>
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                name="createPassword"
                checked={formData.createPassword}
                onChange={handleChange}
                className="mr-2"
              />
              <Text as="p" className="text-sm">Automatically create password for this user</Text>
            </div>
            <div className="flex self-stretch justify-between mt-[37px] gap-5">
            
              <Button
                onClick={handleSubmit}
                color="green_A700_01"
                size="6xl"
                className="bg-green-A700_01 text-white py-2 px-4 rounded-lg"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Next"}
              </Button>
              <Button
                onClick={handleOpen}
                color="gray_400_08"
                size="6xl"
                className="bg-gray-400_08 sm:px-5 font-hankengrotesk min-w-[128px] rounded-[23px]"
              >
                Cancel
              </Button>
              
            </div>
          </div>
                  
          </div>
          </div>
          
     <Dialog isOpen={isOpen} onClose={handleClose} mainonClose={handleCloseAll}>
        <p>This is the content of the dialog.</p>
      </Dialog>


      {isAddUserOpen && (
      <SendInviteSidebarMenu isAddUserOpen={isAddUserOpen} handleCloseMenu={handleCloseAddUser} UserDatas={formData} />
         
     )}

    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    />
    </>
  );
};

export default AddUserSidebarMenu;
