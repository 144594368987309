import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel, Slider  } from "../../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import AuthService from "services/authService";



const ModuesSection: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Display two items at a time
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 2, // Show one item at a time on smaller screens
        },
      },
    ],
    
  };


 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refabout, inViewAbout] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThing, inViewEveryThing] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refEveryThingRight, inViewEveryThingRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadBottom, inViewDownloadBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refDownloadLeft, inViewDownloadLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadRight, inViewDownloadRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThing = useSpring({
    opacity: inViewEveryThing ? 1 : 0,
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationEveryThingLeft = useSpring({
    opacity: inViewEveryThingLeft ? 1 : 0,
    transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationEveryThingRight = useSpring({
    opacity: inViewEveryThingRight ? 1 : 0,
    transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThingBottom = useSpring({
    opacity: inViewEveryThingBottom ? 1 : 0,
    transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });


  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const bottomAboutAnimation = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const moduelitems = [
    { 
      id: 1, 
      img: 'images/img_television_white_a700.svg',
      name: 'Air Emmissions', 
      content: [
        'Evaluate air quality and emissions, including greenhouse gases',
        'Identify sources of emissions and recommend reduction strategies​',
        'Assess compliance with air quality regulations'
      ]
    },
    { 
      id: 2, 
      img: 'images/img_thumbs_up_white_a700_44x38.svg',
      name: 'Waste', 
      content: [
        'Analyse waste generation and management practices​',
        'Ensure adherence to waste disposal regulations​',
        'Recommend waste reduction, recycling, and disposal solution'
      ]
    },
    { 
      id: 3, 
      img: 'images/img_qrcode.svg',
      name: 'Climate Change', 
      content: [
        'Analyse carbon footprint and emissions reduction initiatives',
        'Assess climate change adaptation  and mitigation strategies​',
        'Advise on climate-resilient practices'
      ]
    },
    { 
      id: 4, 
      img: 'images/img_settings_white_a700.svg',
      name: 'Social Sustainability', 
      content: [
        'Assess social impact, diversity, and inclusivity​',
        'Review labour practices and employee well-being​​',
        'Suggest improvements for social responsibility and ethical conduct'
      ]
    },
    { 
      id: 5, 
      img: 'images/img_close.svg',
      name: 'Environmental Sustainability', 
      content: [
        'Evaluate resource consumption and conservation efforts​​',
        'Analyse environmental policies and compliance with regulations',
        'Recommend eco-friendly practices for energy, water, and land use'
      ]
    },
    { 
      id: 6, 
      img: 'images/img_settings_white_a700_43x29.svg',
      name: 'Culture', 
      content: [
        'Examine organisational culture and values​',
        'Evaluate alignment with sustainability objectives​​',
        'Suggest measures to cultivate a sustainable culture'
      ]
    },
    { 
      id: 7, 
      img: 'images/img_television_white_a700_42x59.svg',
      name: 'Sustainable Communities', 
      content: [
        'Investigate community engagement and partnerships​​',
        'Evaluate social and economic contributions to local communities​​',
        'Advise on community-centric sustainability initiatives'
      ]
    },
    { 
      id: 8, 
      img: 'images/img_settings_white_a700_38x44.svg',
      name: 'Health & Well-Being', 
      content: [
        'Review health and safety policies and practices​',
        'Assess employee well-being programs and mental health support​​',
        'Recommend measures to enhance health and well-being'
      ]
    },
    { 
      id: 9, 
      img: 'images/img_television_white_a700_54x27.svg',
      name: 'Investment for Sustainability', 
      content: [
        'Examine organisational culture and values​',
        'Assess return on investment for sustainability initiatives​​​​',
        'Offer guidance on sustainable investment strategies​'
      ]
    },
    { 
      id: 10, 
      img: 'images/img_car_green_600.svg',
      name: 'Education & Stakeholders', 
      content: [
        'Evaluate sustainability education and awareness programs​​',
        'Analyse stakeholder engagement & communication',
        'Suggest methods to enhance education and stakeholder relations'
      ]
    },
    { 
      id: 11, 
      img: 'images/img_thumbs_up_white_a700_44x52.svg',
      name: 'Biodiversity', 
      content: [
        'Assess impacts on local ecosystems and biodiversity​​',
        'Recommend conservation and biodiversity protection measures​​​​',
        'Ensure compliance with wildlife &  habitat protection regulations'
      ]
    },
    { 
      id: 12, 
      img: 'images/img_thumbs_up_white_a700_48x47.svg',
      name: 'Governance', 
      content: [
        'Examine governance structures and ethical decision-making​​',
        'Analyse transparency, accountability, and compliance with regulations',
        'Provide guidance for strengthening governance for sustainability'
      ]
    },
    // Add more items as needed
  ];
  return (
    <>

<div className="flex flex-col items-center justify-center w-full mt-10 gap-[38px] mx-auto md:p-5 ">
            <Heading className="text-center w-[80%] md:w-[95%] text-4xl md:text-3xl sm:text-2xl ">
              Discover Our Modules
            </Heading>
            <Text  className="!text-gray-900_02 text-center w-[80%] md:w-[95%] mb-10  text-[16px] md:text-base sm:text-sm">
              <>
                With these services, you not only gain valuable insights into your business&#39;s impact on the
                environment but also actively
                contribute to the greater mission of conserving and ensuring a harmonious coexistence between industry
                and nature.
              </>
            </Text>
            <div className="md:hidden grid grid-cols-4 md:grid-cols-2  gap-4 w-[90%] md:[95%] ">
      {moduelitems.map(item => (
        <div key={item.id} className=" w-full p-4 mb-10 " >
         <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex flex-col md:items-center items-start">
              <Img src={item.img} alt="air_emmissions" className="h-[49px] ml-2 md:ml-0" />
              <Heading size="5xl" as="h2" className="h-10 md:ml-0 !font-hankengrotesk my-5 md:text-center">
               {item.name}
              </Heading>
              <div className="h-[1px] w-full my-2 bg-blue_gray-100_03 " />

            </div>
          <ul>
            {item.content.map((point, index) => (
              <li key={index}><Text size="3xl" as="p" className="mt-2 !text-gray-600 !font-medium leading-2">
                {point}
              </Text></li>
            ))}
          </ul>
        </div>
      ))}
     </div>
     <div className="hidden md:block h-auto w-full my-10 ">
     <Slider {...settings}>
      {moduelitems.map((item) => (
         <div key={item.id} className=" w-full p-4 mb-10" >
         <div className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 flex flex-col md:items-center items-start">
              <Img src={item.img} alt="air_emmissions" className="h-[49px] ml-2 md:ml-0" />
              <Heading size="5xl" as="h2" className="h-10 md:ml-0 !font-hankengrotesk my-5 md:text-center">
               {item.name}
              </Heading>
              <div className="h-[1px] w-full my-2 bg-blue_gray-100_03 " />

            </div>
          <ul>
            {item.content.map((point, index) => (
              <li key={index}><Text size="3xl" as="p" className="mt-2 !text-gray-600 !font-medium leading-2">
                {point}
              </Text></li>
            ))}
          </ul>
        </div>
      ))}
    </Slider>
     </div>
          </div>
    


    </>
  );
};

export default ModuesSection;
