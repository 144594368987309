import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input, Radio, RadioGroup,MyCarousel  } from "../../../components";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import AuthService from "services/authService";
import { TextField } from "@mui/material";
import { CgSpinner } from "react-icons/cg";



const JouneySection: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);



  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navbarRef]);





 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refabout, inViewAbout] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThing, inViewEveryThing] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refEveryThingRight, inViewEveryThingRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadBottom, inViewDownloadBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refDownloadLeft, inViewDownloadLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refDownloadRight, inViewDownloadRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThing = useSpring({
    opacity: inViewEveryThing ? 1 : 0,
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationEveryThingLeft = useSpring({
    opacity: inViewEveryThingLeft ? 1 : 0,
    transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationEveryThingRight = useSpring({
    opacity: inViewEveryThingRight ? 1 : 0,
    transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationEveryThingBottom = useSpring({
    opacity: inViewEveryThingBottom ? 1 : 0,
    transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });


  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });

  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const bottomAboutAnimation = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Check if any required fields are empty
    if (!email  ) {
      setError('Please fill in all required fields.');
      return;
    }
  

    // Check password complexity
    
  
  
    // Set loading to true when submitting the form
    setLoading(true);
    setError('');
  
    const { success, error } = await AuthService.sendnewletter(email);
  
    // Reset loading state after login attempt
    setLoading(false);
  
    if (success) {
      setError('');
      setEmail('');
     
    } else {
      if (error !== undefined) {
        setError(error);
      }
    }
  };
  return (
    <div className="w-full flex items-center justify-center">

     
    <div className="flex sm:flex-col justify-center items-center w-full mx-10 md:mx-4 my-20 md:my-10 ">
    <animated.div ref={refEveryThingLeft} style={animationEveryThingLeft}>
              <div className="flex justify-end sm:self-stretch  p-[54px] md:p-0 sm:ml-0 relative bg-[#00BF63] flex-1 rounded-[18px] ">
               <Img src="images/img_triangle_image.png" alt="image" className="w-[30%] h-52 sm:w-full md:hidden  object-contain mr-3" />
                <div className="flex flex-col w-[70%] md:w-full mt-2">
                  <Heading size="10xl" as="h1">
                    <span className="text-black-900_01">
                      <>
                        Taking you on a journey of a more 
                        sustainable&nbsp;
                      </>
                    </span>
                    <span className="text-white-A700">lifestyle</span>
                  </Heading>
                  <Text size="6xl" as="p" className="w-[91%] md:w-full mt-2.5">
                    <>
                      Stay up-to-date on sustainability topics with our monthly updates and
                      never miss a beat.
                    </>
                  </Text>
                  <form onSubmit={handleSubmit} className='w-full'>
                  <div className="flex sm:flex-col items-center justify-center  gap-3.5">
                   
      <TextField
      placeholder="Your email address"
      type="email"
      className='w-full self-stretch flex'
      onChange={handleEmailChange}
      value={email}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#ffffff', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#ffffff', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />

                    <button className="transition h-12 bg-black-900_01 flex items-center justify-center text-white-A700 ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 font-semibold w-36 md:w-full rounded-[11px]">
                     {loading ? (<CgSpinner className=" animate-spin text-lg items-center " /> ):(<p>Signup</p>)}
                    </button>
                  </div>
                  </form>
                </div>
              </div>
              </animated.div>
    </div>

    </div>
  );
};

export default JouneySection;
