import React from 'react';
import Chart from 'react-apexcharts';

const MyDoughnutChart: React.FC = () => {
  // Static values to display
  const rawValues = [15, 20, 5];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['Certification', 'Standard', 'Regulations'],
    colors: ['#00BFA5', '#0097A7', '#00B8D4'],
    dataLabels: {
      enabled: true, // Enable data labels
      formatter: function (val: string | number) {
        const value = typeof val === 'string' ? parseFloat(val) : val;
        return value.toFixed(0); // Show raw values without percentage
      },
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['#FFF'],
      },
    },
    legend: {
      show: true, // Show legend if needed
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: true, // Ensure labels are visible
            name: {
              show: false,
            },
            value: {
              show: true,
              formatter: function (val: string | number) {
                const value = typeof val === 'string' ? parseFloat(val) : val;
                return value.toFixed(0); // Show raw values without percentage
              },
            },
            total: {
              show: false,
              label: 'Total',
              formatter: function () {
                return ''; // Hide total label
              },
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val: string | number) {
          const value = typeof val === 'string' ? parseFloat(val) : val;
          return value.toFixed(0); // Show raw values in tooltips
        },
      },
    },
  };

  return (
    <div className="flex items-start w-full h-80 mx-auto px-4 bg-yellow-50">
      <Chart options={options} series={rawValues} type="donut" height="80%" />
    </div>
  );
};

export default MyDoughnutChart;
