import React, { useEffect, useState } from 'react';
import AuthService from 'services/authService';

const files = [
  { name: "Strategic Plan – Climate Change",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf", date: "Feb 13, 2024", access: "3 Members", size: "17 mb", type: "video" },
  { name: "Climate Strategic Plan",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Climate Risk Committee Meeting Records",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Climate Improvement Report",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Climate Risk Committee Terms of Reference",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Climate Management Performance Reviews",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Detailed Structural Design Report",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
  { name: "Climate Risk Committee Terms of Reference",link: "https://res.cloudinary.com/code-idea/image/upload/v1717407707/D043-PCY-ETH-AA_Climate_Improvement_Report_01_Test_z2aqgn.pdf",  date: "Feb 13, 2024", access: "1 Member", size: "17 mb", type: "pdf" },
];

const FileTable: React.FC = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AuthService.fetchCerts();
        setUserData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className='flex flex-col gap-4'>
  <div>
  <div className="rounded-sm  bg-white px-5 pt-6 pb-2.5 shadow-default text-base sm:px-7.5 xl:pb-1 ">
    
    {files && files.length > 0 ? (
             <table className="w-full table-auto">
               <thead>
                 <tr className="bg-[#EBEBEB] text-left text-[#818181] border-b border-[#C0C0C0]">
                   <th className="min-w-[220px] py-4 px-4 font-medium text-black  xl:pl-11">
                     File name
                   </th>
                   <th className="min-w-[150px] py-4 px-4 font-medium text-black ">
                     Date
                   </th>
                   <th className="min-w-[120px] py-4 px-4 font-medium text-black ">
                     Access
                   </th>
                   <th className="py-4 px-4 font-medium text-black">
                     File Size
                   </th>
                 </tr>
               </thead>
               <tbody>
                 {files.map((cert, index) => (
                   <tr key={index} className='border-b border-[#C0C0C0] p-4 h-14  '>
                     <td className=" ">
                     <div className="flex flex-row gap-4 font-normal items-center py-auto">
                     <img
                         loading="lazy"
                         srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                         className="h-5 w-5"
                       />
                       <h5 className="font-normal text-black dark:text-white">
                         {cert.name}
                       </h5>
                       </div>  
                    
                     </td>
                     <td className="dark:border-strokedark">
                       <p className="text-black dark:text-white font-normal">
                         {cert.date}
                       </p>
                     </td>
                     <td className="dark:border-strokedark">
                       
                       <p className="text-black dark:text-white font-normal">
                       {cert.access}
                     </p>
                     </td>
                     <td className="dark:border-strokedark">
                     <p className="text-black dark:text-white font-normal">
                         {cert.size}
                       </p>
                     </td>
                   </tr>
                 ))}
               </tbody>
             </table>
         ) : (
           <p>No certifications available.</p>
         )}

     {/* <div className="w-full overflow-x-auto">
       <table className="w-full table-auto">
         <thead>
           <tr className="bg-[#EBEBEB] text-left text-[#818181] border-b border-[#C0C0C0]">
             <th className="min-w-[220px] py-4 px-4 font-medium text-black  xl:pl-11">
               File name
             </th>
             <th className="min-w-[150px] py-4 px-4 font-medium text-black ">
               Date
             </th>
             <th className="min-w-[120px] py-4 px-4 font-medium text-black ">
               Access
             </th>
             <th className="py-4 px-4 font-medium text-black">
               File Size
             </th>
           </tr>
         </thead>
         <tbody>
           {userData.cert.map((cert, index) => (
             <tr key={index}>
               <td className="border-b border-[#C0C0C0] py-5 px-4  flex flex-row gap-4 font-normal">
               <img
                   loading="lazy"
                   srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b52662409970d710535c4bdca952f34635015c2d81b99d88bd00f44bb91dfe3?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                   className="shrink-0 aspect-[1.3] w-[26px]"
                 />
                 <h5 className="font-normal text-black dark:text-white">
                   {cert.certName}
                 </h5>
               </td>
               <td className="border-b border-[#C0C0C0] py-5 px-4 dark:border-strokedark">
                 <p className="text-black dark:text-white font-normal">
                   {cert.date}
                 </p>
               </td>
               <td className="border-b border-[#C0C0C0] py-5 px-4 dark:border-strokedark">
                 
                 <p className="text-black dark:text-white font-normal">
                 {cert.access}
               </p>
               </td>
               <td className="border-b border-[#C0C0C0] py-5 px-4 dark:border-strokedark">
               <p className="text-black dark:text-white font-normal">
                   {cert.size}
                 </p>
               </td>
             </tr>
           ))}
         </tbody>
       </table>
     </div> */}
   </div>
    </div>

    </div>

  );
};

export default FileTable;
