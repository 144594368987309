import React, { useState, useEffect } from 'react';

const LinearProgressBar = ({ value, max }) => {
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    const width = (value / max) * 100;
    setProgressWidth(width);
  }, [value, max]);

  return (
    <div style={{ width: '100%', backgroundColor: '#ddd', borderRadius: '4px', marginTop: '10px' }}>
      <div
        style={{
          width: `${progressWidth}%`,
          height: '10px',
          borderRadius: '4px'
        }}
        className='bg-green-A700_01'
      ></div>
    </div>
  );
};


export  {LinearProgressBar};

const CircularProgressBar = ({ progress }) => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
      const progressOffset = ((100 - progress) / 100) * 251.2; // Calculate offset based on progress
      setOffset(progressOffset);
    }, [progress]);
  
    return (
      <svg className="w-24 h-24" viewBox="0 0 120 120">
        <circle className="fill-transparent stroke-gray-300" cx="60" cy="60" r="54" strokeWidth="12" />
        <circle
          className="fill-transparent stroke-green-500 transition-all duration-500"
          cx="60"
          cy="60"
          r="54"
          strokeWidth="12"
          strokeDasharray="251.2"
          strokeDashoffset={offset}
        />
        <text x="50%" y="50%" textAnchor="middle" className="text-black text-2xl">{`${progress}%`}</text>
      </svg>
    );
  };
  
  export  {CircularProgressBar};