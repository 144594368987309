import React, { useEffect, useState } from 'react'

import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

// import Logo from "../../../assets/svg/Logo.svg"
import Report from './reportComponent/Report';
import Ranking from './reportComponent/Ranking';
import { Img } from 'components/Img';

const Scores = () => {
  const [activeButton, setActiveButton] = useState("Summary Report")
  const { score } = useParams();
  const [loading, setLoading] = useState(true)


  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
};


const navigate = useNavigate();


const handleBackPress = () => {
  navigate('/dashboard/module', { replace: true });
};

useEffect(() => {
  window.addEventListener('popstate', handleBackPress);
  return () => {
    window.removeEventListener('popstate', handleBackPress);
  };
}, []);

const changeLoading = () => {
  setTimeout(() => {
    console.log("Delayed for 1 second.");
    setLoading(false)
  }, 5000); 
  }



  useEffect(() => {
    changeLoading()
  },[])

 

  return (
    <div>
        <nav className="flex self-stretch  items-center gap-10 p-5  md:p-4 fixed w-full z-50 bg-white-A700  bg-gradient3 shadow" >
  
      <NavLink to="/dashboard/module">
       <Img
        src="/images/img_settings_cyan_900.svg"
        alt="settings_one"
        className="h-8"
       />
        </NavLink>
      <p className={loading ? "hidden" : 'text-base sm:text-sm  font-hanken font-medium'}>{activeButton === "Summary Report" ? "Summary Report" : "Ranking"}</p>

       

     
    </nav>
    <div className='w-full overflow-x-hidden pt-20 px-10 md:px-5'>
    {/* <h2>Param value: {score}</h2> */}
        
  {
     loading ? 
      <div className='flex  flex-col items-center justify-center h-auto' >
        <img src="https://iosexample.com/content/images/2019/09/A-circular-progress-bar.gif"  alt='loading' className=' h-[50%]' />
        <p className='font-inter font-bold text-[30px] text-[#696969]'>Loading...</p>
      </div>
     :
      <div className='w-full  '>
        <div className='w-full  my-10 '> {/* gap-[116px] */}
        <svg  onClick={handleBackPress}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left cursor-pointer mb-4"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>

        <div className='flex flex-row items-center gap-5  md:overflow-scroll'>
                <button
                    className={`font-medium sm:text-sm  font-hanken text-base text-center  h-[42px] px-5 rounded-[21px] transform transition-transform outline-none `}
                    onClick={() =>  handleButtonClick("Summary Report")}
                    style={activeButton === "Summary Report" ? {background:'#C4F0BD',color:'#2A6121'} : {background:"#E7F0E6",color:'#698864'} }
                >
                  Summary Report
                </button>
                <button
                    className={`font-medium font-hanken sm:text-sm  text-base text-center  h-[42px] px-5 rounded-[21px] transform transition-transform outline-none `}
                    onClick={() =>  handleButtonClick("Ranking")}
                    style={activeButton === "Ranking" ? {background:'#C4F0BD',color:'#2A6121'} : {background:"#E7F0E6",color:'#698864'} }
                >
                  Ranking
                </button>
             
            
            </div>
        
        </div>
          {activeButton === "Summary Report" && <Report score={score}/>}
          {activeButton === "Ranking" && <Ranking score={score}/>}

      </div>
  }
</div>
    </div>
      
  )
}

export default Scores