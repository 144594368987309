
import React from "react";
import Header from "./component/Header";
import ContactInformation from "./component/ContactInformation";
import PaymentMethod from "./component/PaymentMethod";
import SectorIndustry from "./component/SectorIndustry";
import PricingSection from "./component/PricingSection";

const SustainabilityJourney: React.FC = () => {
  return (
    <main className="flex flex-col w-full ">
       <Header />
      <div className="flex overflow-hidden flex-col items-center pt-px pb-60 w-full bg-white md:pb-24 md:w-full">
       
        <h1 className="mt-32 text-4xl font-bold text-center text-black md:mt-24 md:w-full md:text-xl md:px-4 md:text-left">
          Modules to Kickstart Your <br /> Sustainability Journey
        </h1>
        <div className="mt-28  mb-12 px-20 md:px-4 w-10/12  md:mt-4 md:mb-2.5 md:w-full">
          <div className="flex gap-5 md:flex-col">
            <div className="flex flex-col w-6/12 md:ml-0 md:w-full">
              <ContactInformation />
              <PaymentMethod />
            </div>
            <div className="flex flex-col ml-5 w-6/12 md:ml-0 md:w-full">
              <SectorIndustry />
              <PricingSection />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SustainabilityJourney;
