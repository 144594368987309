import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link, useNavigate } from "react-router-dom";

import { Doughnut } from 'react-chartjs-2';


interface ChartThreeState {
  series: number[];
}

const options: ApexOptions = {
  chart: {
    type: 'donut',
  },
  colors: ['#60A965', '#548ABB','#8DD630'  ],
  labels: [ 'Sustainable Standard','Reglation','Certification' ],
  legend: {
    show: false,
    position: 'left',
  },

  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        background: 'transparent',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 300,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 300,
        },
      },
    },
  ],
};
interface SwotAnalysis {
  strengths: string[];
  weaknesses: string[];
  opportunities: string[];
  threats: string[];
}


interface ReportProps {
  score: string;
}
const Report:React.FC<ReportProps> = ({ score }) => {
  const userScore = score;

  const [state, setState] = useState<ChartThreeState>({
    series: [65, 50, 50],
  });
    const [loading, setLoading] = useState(false)
    const [swotAnlysisL, setSwotAnalysisL] = useState([])
    const [swotData, setSwotData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://saudit-jheg.onrender.com/openai/swot-analysis', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              // Include the JSON data in the request body
              "_id": "65c3451ed845128393072ed6",
              "firstname": "John",
              "lastname": "Doe",
              "email": "shalom.mat40+12s2392931023@gmail.com",
              "companyName": "Example Company",
              "position": "Developer",
              "sector": "IT",
              "industry": "Software",
              "companySize": "Medium",
              "surveys": [
                [
                  {
                    "question": {
                      "_id": "65ce1472daec4b41fc6f33aa",
                      "sectionName": "Climate",
                      "question": ":How much of your total energy consumption is sourced from renewable energy?",
                      "sectionPoints": 0,
                      "SDGName": "Affordable and Clean Energy",
                      "options": [
                        {
                          "name": ">500%",
                          "points": 5,
                          "category": "Sustainability Standards",
                          "_id": "65ce1472daec4b41fc6f33ab"
                        },
                        // Other options...
                      ],
                      "__v": 0
                    },
                    "answer": {
                      "name": "10%",
                      "points": 2,
                      "category": "Sustainability Standards",
                      "_id": "65ce1472daec4b41fc6f33ae"
                    },
                    "_id": "65ce5fc9c1626623834e7b5e"
                  },
                  // Other survey questions...
                ]
              ]
            }),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();
          setSwotData(data);
        } catch (error) {
          console.error('Error fetching SWOT analysis:', error);
          // Handle error state
        }
      };
    
      fetchData();
    }, []);
  




  

      

    
     
  return (
    <div className='flex w-full flex-col  '>

    <div className='w-full flex flex-row md:flex-col'>
    <div className="flex flex-col  mt-4 text-black w-[60%] md:w-full">
     
    <div className='w-full  rounded-lg bg-[#D7F5FF] py-5 px-4'>
            <p className='text-[#000]  font-Kumbh text-lg sm:text-base  font-medium '>
                This dashboard presents your climate net zero assessment report for 
                strategy and commitment. The overall score for the company is <span>{`${score}%.`}</span> 
                This report provides a summary analysis of the assessment results, 
                highlighting strengths and areas for improvement.
            </p>

    </div>
    <div className='flex flex-col w-full gap-5'>
          

            <div className='flex flex-col gap-10 mt-[20px]'>
            <p className='font-Kumbh font-semibold text-[20px'>Your Organisation's Compliance</p>
            <div className='h-[1.5px] w-full bg-[#D4D4D4]'></div>
            <div className='flex md:flex-col gap-3 justify-between  w-full'>
            
               
                <div className='bg-[#60A965] w-[35%] md:w-full h-24 gap-2 rounded-lg p-3 flex flex-col'>
                    <p className='font-Kumbh text-base font-semibold text-white-A700'>Sustainability Standards</p>
                    <p className='font-Kumbh text-base font-semibold  text-white-A700'>{score}%</p>
                </div>  
                <div className='bg-[#548ABB] w-[30%] md:w-full h-24 gap-2 rounded-lg p-3 flex flex-col'>
                    <p className='font-Kumbh text-base font-semibold  text-white-A700'>Regulations</p>
                    <p className='font-Kumbh text-base font-semibold  text-white-A700'>0%</p>
                </div>  
                <div className='bg-[#8DD630] w-[30%] md:w-full h-24 gap-2 rounded-lg p-3 flex flex-col'>
                    <p className='font-Kumbh text-base font-semibold  text-white-A700'>Certifications</p>
                    <p className='font-Kumbh text-base font-semibold  text-white-A700'>30%</p>
                </div>  
            </div>
            </div>
        </div>
      </div>
      <div className="flex flex-col relative justify-center h-[400px] md:h-[h-300px] items-center px-5 mt-4 text-black w-[40%] md:w-full">
      <p className='font-Kumbh font-semibold absolute top-2 md:right-0 md:left-0 left-10 md:text-center text-left text-[20px]'>Compliance Score</p>
      <div className='flex flex-col w-full my-10' style={{    marginTop: ""}}> 
          <div className='relative flex  items-center'>
         
      <div className='absolute top-1/2 left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-center'>
      <ReactApexChart
        options={options}
        series={state.series}
        type="donut"
      />   
            </div>             
            <div className='absolute top-1/2 left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-center'>
                <p className='font-semibold font-Kumbh text-[#000] text-[50px]'>{`${userScore}%`}</p>
            </div>
          </div>
  
  
      </div>
     
     
      </div>
    </div>
    <hr className='my-5' />
    <div className='mb-5'>
    <div className="flex flex-col mx-auto py-8">
      <h2 className="text-xl font-bold mb-4">SWOT Analysis</h2>
      <div className="mb-8">
        <h3 className="text-base font-semibold mb-2">Strengths:</h3>
        <ul className="list-disc pl-4">
          <li>Strong commitment to sustainability from leadership and employees</li>
          <li>Implemented energy-efficient practices and renewable energy sources, reducing carbon footprint</li>
          <li>Diverse and inclusive workplace culture</li>
          <li>Innovative and sustainable products and services</li>
          <li>Established relationships with environmentally responsible suppliers</li>
        </ul>
      </div>
      <div className="mb-8">
        <h3 className="text-base font-semibold mb-2">Weaknesses:</h3>
        <ul className="list-disc pl-4">
          <li>High water usage in manufacturing processes</li>
          <li>Limited recycling programs in place</li>
          <li>Some products contain non-biodegradable materials</li>
          <li>Need for improved waste management and reduction strategies</li>
          <li>Limited transparency in sustainability reporting</li>
        </ul>
      </div>
      <div className="mb-8">
        <h3 className="text-base font-semibold mb-2">Opportunities:</h3>
        <ul className="list-disc pl-4">
          <li>Growing demand for sustainable products and services</li>
          <li>Potential for cost savings through water and energy efficiency measures</li>
          <li>Expanding into new markets with environmentally conscious consumers</li>
          <li>Collaboration with other companies and organizations to share best practices</li>
          <li>Development of new sustainable products and services</li>
        </ul>
      </div>
      <div>
        <h3 className="text-base font-semibold mb-2">Threats:</h3>
        <ul className="list-disc pl-4">
          <li>Increasing regulations and laws regarding environmental impact</li>
          <li>Negative publicity and reputation damage if sustainability goals not met</li>
          <li>Competition from companies with stronger sustainability track records</li>
          <li>Supply chain disruptions due to environmental or social issues</li>
          <li>Economic downturns impacting investment in sustainability initiatives</li>
        </ul>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Report